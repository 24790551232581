/* eslint react/no-unused-state: 0 */

import React, { PureComponent } from 'react';
import { processing } from 'decorators';
import { getConfig } from 'services/api/main';
import { withErrorNotification } from 'storage/NotificationsContext';
import mainWithContext from 'with/withContext';

type ConfigContextInterface = {
  config: {
    adminAccountsUrl: string;
    loginUrl: string;
    profileUrl: string;
  };
  isFetching: boolean;
};

const defaultValue: ConfigContextInterface = {
  config: {
    adminAccountsUrl: '',
    loginUrl: '',
    profileUrl: '',
  },
  isFetching: true,
};

const Context = React.createContext<ConfigContextInterface>(defaultValue);

function withProvider(WrappedComponent) {
  class ContextWrapper extends PureComponent<any, ConfigContextInterface> {
    state = { ...defaultValue };

    @withErrorNotification
    @processing('isFetching')
    async componentDidMount() {
      this.setState({ config: await getConfig() });
    }

    render() {
      return (
        <Context.Provider value={this.state}>
          <WrappedComponent {...this.props} />
        </Context.Provider>
      );
    }
  }

  return ContextWrapper;
}

export { ConfigContextInterface };
export default {
  useContext: () => React.useContext(Context),
  withProvider,
  withContext: mainWithContext(Context),
};
