import cn from 'classnames';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import React from 'react';
import ModalConfirmation from 'components/Modal/ModalConfirmation';
import UserStatusEnum from 'model/UserStatusEnum';

export default class RemoveAccountOwnerModal extends React.PureComponent {
  static propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number,
      participantId: PropTypes.number,
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      status: PropTypes.string,
    }).isRequired,
    woaaccount: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    onCancel: () => {},
  };

  state = {
    submitting: false,
  };

  @autobind
  async onConfirm() {
    this.setState({ submitting: true });

    try {
      await this.props.onConfirm();
    } finally {
      this.setState({ submitting: false });
    }
  }

  getUserLabel(user) {
    if (user.status === UserStatusEnum.ACTIVE) {
      return `${user.firstName} ${user.lastName}`;
    }

    return user.email;
  }

  render() {
    const { onCancel, user, woaaccount } = this.props;
    const { submitting } = this.state;

    return (
      <ModalConfirmation
        buttonCloseClick={onCancel}
        buttonOkClassName={cn({ 'is-loading': submitting })}
        buttonOkClick={this.onConfirm}
        buttonOkDisabled={submitting}
        buttonOkTitle="Remove User"
        className="removeUserModal"
        seleniumid="removeUserModal"
        title="Remove User"
      >
        <div>
          {`Do you really want to remove `}
          <strong>{this.getUserLabel(user)}</strong>
          {` from `}
          <strong>{woaaccount.name}</strong>
          {` account?`}
        </div>
        <div>
          If you remove this user, they will be sent an email notification that they can no longer access this account.
        </div>
        <div>
          {`Please make sure to have at least one `}
          <strong>Account Owner</strong>
          {` per account.`}
        </div>
      </ModalConfirmation>
    );
  }
}
