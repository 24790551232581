import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import React from 'react';
import Elements from 'components/Elements';
import LoaderLayout from 'components/Layouts/LoaderLayout';
import AddUserModal from 'components/Profile/AddUserModal';
import RemoveUserModal from 'components/Profile/RemoveUserModal';
import { processing } from 'decorators';
import PermissionEnum from 'model/PermissionEnum';
import UserStatusEnum from 'model/UserStatusEnum';
import { addPermission, addUser, removePermission, removeUser } from 'services/api/account';
import { notify, withErrorNotification } from 'storage/NotificationsContext';
import { canManageUsers } from 'utils/permissions';

function getUserLabel(user) {
  if (user.status === UserStatusEnum.ACTIVE) {
    return `${user.firstName} ${user.lastName}`;
  }

  return user.email;
}

export default class Users extends React.PureComponent {
  static propTypes = {
    profile: PropTypes.shape({
      email: PropTypes.string,
    }).isRequired,
    refreshWoaaccount: PropTypes.func.isRequired,
    woaaccount: PropTypes.shape({
      id: PropTypes.number,
      woausers: PropTypes.arrayOf({}),
    }).isRequired,
  };

  state = {
    removeUserModal: {
      isVisible: false,
      user: null,
      open: user => {
        this.setState(state => ({
          removeUserModal: {
            ...state.removeUserModal,
            isVisible: true,
            user,
          },
        }));
      },
      close: () => {
        this.setState(state => ({
          removeUserModal: {
            ...state.removeUserModal,
            isVisible: false,
            user: null,
          },
        }));
      },
      onCancel: () => {
        this.state.removeUserModal.close();
      },
      onConfirm: withErrorNotification(async () => {
        const { woaaccount } = this.props;
        const { user } = this.state.removeUserModal;

        await removeUser(woaaccount.id, user.participantId);
        await this.props.refreshWoaaccount();

        this.state.removeUserModal.close();

        const message = `
            <strong>${getUserLabel(user)}</strong> has been successfully dissociated from 
            <strong>${woaaccount.name}</strong>.`;
        notify.info(message);
      }),
    },

    addUserModal: {
      isVisible: false,
      open: () => {
        this.setState(state => ({
          addUserModal: {
            ...state.addUserModal,
            isVisible: true,
          },
        }));
      },
      close: () => {
        this.setState(state => ({
          addUserModal: {
            ...state.addUserModal,
            isVisible: false,
          },
        }));
      },
      onAdd: withErrorNotification(async email => {
        if (this.props.woaaccount.woausers.find(x => x.email.toLowerCase() === email.toLowerCase())) {
          notify.error('User is already tagged with this account.');
          return;
        }

        const woauser = await addUser(this.props.woaaccount.id, email);
        await this.props.refreshWoaaccount();

        this.state.addUserModal.close();

        let message = '';
        if (woauser.status === UserStatusEnum.ACTIVE) {
          message = `User <strong>${email}</strong> has been successfully tagged with this account.`;
        } else {
          message = `A mail has been sent to the user <strong>${email}</strong> with registration instructions.`;
        }
        notify.info(message);
      }),
      onCancel: () => {
        this.state.addUserModal.close();
      },
    },
  };

  @autobind
  onAddUser() {
    this.state.addUserModal.open();
  }

  onRemoveUser(user) {
    this.state.removeUserModal.open(user);
  }

  @withErrorNotification
  @processing('isPermissionChanging')
  async changePermission(woauser, permission, isGranted) {
    const options = [this.props.woaaccount.id, woauser.participantId, permission];
    if (isGranted) {
      await addPermission(...options);
    } else {
      await removePermission(...options);
    }

    await this.props.refreshWoaaccount();

    const message = `<strong>${getUserLabel(woauser)}</strong>'s permission has been changed successfully.`;
    notify.info(message);
  }

  renderUserRow(woaaccount, woauser, hasUserManagementPermission, isAdmin, isDisabled) {
    const cols = [];

    if (woauser.status === UserStatusEnum.ACTIVE) {
      cols.push(<td key="0">{`${woauser.firstName} ${woauser.lastName}`}</td>);
      cols.push(
        <td key="1" className="taggedUsers-email">
          {woauser.email}
        </td>,
      );
    } else {
      cols.push(<td key="0" />);
      cols.push(
        <td key="1" className="taggedUsers-email">
          <span className="font-italic">
            {woauser.email}
            <span style={{ color: 'red' }}> (Pending)</span>
          </span>
        </td>,
      );
    }

    if (hasUserManagementPermission) {
      const isOwner = woauser.permissions.includes(PermissionEnum.OWNER);
      const isConsortia = woauser.permissions.includes(PermissionEnum.CONSORTIA);

      cols.push(
        <td key="2" style={{ textAlign: 'center' }}>
          <Elements.Checkbox
            checked={isOwner}
            disabled={isDisabled}
            onChange={() => this.changePermission(woauser, PermissionEnum.OWNER, !isOwner)}
            seleniumid={`taggedUsers-owner--${woauser.email}`}
          />
        </td>,
      );

      cols.push(
        <td key="3" style={{ textAlign: 'center' }}>
          <Elements.ConfirmCheckbox
            checked={isConsortia}
            disabled={!isAdmin || isDisabled || !woaaccount.consortiumId}
            onChange={() => this.changePermission(woauser, PermissionEnum.CONSORTIA, !isConsortia)}
            seleniumid={`taggedUsers-consortia--${woauser.email}`}
            title={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <>
                This user will get &quot;Owner&quot; permission to all accounts associated with
                <br />
                this Deal/Consortia by default. Do you want to continue?
              </>
            }
            tooltip={!isAdmin ? 'You should be an admin to manage consortia permission.' : undefined}
          />
        </td>,
      );

      cols.push(
        <td key="4" style={{ textAlign: 'center' }}>
          <button
            className="btn btn-light btn-sm"
            data-seleniumid={`taggedUsers-remove--${woauser.email}`}
            data-title={isConsortia ? 'First consortia permission should be removed.' : undefined}
            disabled={isDisabled || isConsortia}
            onClick={() => this.onRemoveUser(woauser)}
            type="button"
          >
            Remove
          </button>
        </td>,
      );
    } else {
      for (let i = 2; i <= 4; i += 1) {
        cols.push(<td key={`${i}`} />);
      }
    }

    return <tr key={woauser.email}>{cols}</tr>;
  }

  render() {
    const { profile, woaaccount } = this.props;
    const { addUserModal, removeUserModal, isPermissionChanging } = this.state;

    const hasUserManagementPermission = canManageUsers(woaaccount);
    const isAdmin = woaaccount.permissions.includes(PermissionEnum.ADMIN);

    return (
      <>
        {hasUserManagementPermission && (
          <button
            className="btn btn-secondary"
            data-seleniumid="taggedUsers-addUser"
            onClick={this.onAddUser}
            style={{ marginBottom: '.5em' }}
            type="button"
          >
            Add User
          </button>
        )}

        {woaaccount.woausers.length > 0 && (
          <LoaderLayout className="notificationConfig" isLoading={isPermissionChanging}>
            <table className="table mb_2em ta_l taggedUsers" style={{ wordBreak: 'break-all' }}>
              <colgroup>
                <col style={{ width: '25%' }} />
                <col />
                <col />
                <col />
                <col />
              </colgroup>

              <thead>
                <tr className="table-sectionTitle">
                  <td className="fs_110p bold" colSpan="5">
                    Other Account User(s)
                  </td>
                </tr>
                <tr>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th style={{ textAlign: 'center' }}>{hasUserManagementPermission && 'Owner'}</th>
                  <th style={{ textAlign: 'center' }}>{hasUserManagementPermission && 'Consortia'}</th>
                  <th style={{ textAlign: 'center' }}>{hasUserManagementPermission && 'Remove'}</th>
                </tr>
              </thead>

              <tbody>
                {woaaccount.woausers
                  .filter(woauser => woauser.email !== profile.email)
                  .map(woauser =>
                    this.renderUserRow(woaaccount, woauser, hasUserManagementPermission, isAdmin, isPermissionChanging),
                  )}
              </tbody>
            </table>
          </LoaderLayout>
        )}

        {addUserModal.isVisible && (
          <AddUserModal onAdd={addUserModal.onAdd} onCancel={addUserModal.onCancel} woaaccount={woaaccount} />
        )}

        {removeUserModal.isVisible && (
          <RemoveUserModal
            onCancel={removeUserModal.onCancel}
            onConfirm={removeUserModal.onConfirm}
            user={removeUserModal.user}
            woaaccount={woaaccount}
          />
        )}
      </>
    );
  }
}
