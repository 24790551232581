import { autobind, decorate } from 'core-decorators';
import get from 'lodash.get';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';
import { processing } from 'decorators';
import RequestStatusEnum from 'model/consortia/RequestStatusEnum';
import { getRequests } from 'services/api/consortia';
import ConsortiaContext from 'storage/ConsortiaContext';
import { withErrorNotification } from 'storage/NotificationsContext';
import { Emitter as OrdersEmitter } from 'storage/OrderActionsContext';
import withConsortiumRequired from 'with/withConsortiumRequired';
import { compose } from 'with/withContext';
import withSelectedAccountRequired from 'with/withSelectedAccountRequired';

const columnFilterOptions = {
  [RequestStatusEnum.APPROVED]: [
    { value: 'request.created', label: 'Request Date' },
    { value: 'article.doi', label: 'Article DOI' },
    { value: 'article.authors', label: 'Authors' },
    { value: 'journal.name', label: 'Journal Name' },
    { value: 'article.publicationStatus.name', label: 'Article Publication Status' },
    { value: 'request.amount', label: 'Amount' },
  ],
  [RequestStatusEnum.CANCELLED]: [
    { value: 'request.created', label: 'Request Date' },
    { value: 'article.doi', label: 'Article DOI' },
    { value: 'article.authors', label: 'Authors' },
    { value: 'journal.name', label: 'Journal Name' },
    { value: 'request.amount', label: 'Amount' },
  ],
  [RequestStatusEnum.DENIED]: [
    { value: 'request.created', label: 'Request Date' },
    { value: 'article.doi', label: 'Article DOI' },
    { value: 'article.authors', label: 'Authors' },
    { value: 'journal.name', label: 'Journal Name' },
    { value: 'request.amount', label: 'Amount' },
  ],
  [RequestStatusEnum.PENDING]: [
    { value: 'request.created', label: 'Request Date' },
    { value: 'article.doi', label: 'Article DOI' },
    { value: 'article.authors', label: 'Authors' },
    { value: 'journal.name', label: 'Journal Name' },
    { value: 'request.amount', label: 'Amount' },
  ],
};

function withRequests({ status }) {
  return function (Component) {
    @autobind
    class WithRequests extends React.PureComponent {
      static propTypes = {
        selectedAccount: PropTypes.shape({ blendedDealId: PropTypes.number }).isRequired,
        setConsortiumId: PropTypes.func.isRequired,
        dealShowPrice: PropTypes.bool,
      };

      static defaultProps = {
        dealShowPrice: undefined,
      };

      state = {
        columnFilter: [],
        isFetching: false,
        journalRevenueModel: 'Both',
        page: 0,
        pageSize: 20,
        requests: {
          pagination: null,
          requests: null,
          stats: null,
        },
        search: undefined,
        sorted: undefined,
      };

      subscriptions = [];

      componentDidMount() {
        this.subscriptions.push(OrdersEmitter.ORDER_WAS_UPDATED.subscribe(this.getRequests));
        this.props.setConsortiumId(this.props.selectedAccount.consortiumId);
        this.getRequests();
      }

      async componentDidUpdate(prevProps) {
        if (this.props.selectedAccount.consortiumId !== prevProps.selectedAccount.consortiumId) {
          this.props.setConsortiumId(this.props.selectedAccount.consortiumId);
          this.onListChange(undefined, 0, this.state.pageSize);
        }
      }

      componentWillUnmount() {
        this.subscriptions.forEach(x => x());
      }

      getCanClearFilters(columnFilter, journalRevenueModel) {
        return columnFilter.length > 0 || journalRevenueModel !== 'Both';
      }

      @decorate(memoize)
      // eslint-disable-next-line @typescript-eslint/no-shadow
      getColumnFilterOptions(showPrice, status) {
        const options = columnFilterOptions[status];

        if (!showPrice) {
          return options.filter(x => x.value !== 'request.amount');
        }

        return options;
      }

      @withErrorNotification
      @processing('isFetching')
      async getRequests() {
        const { journalRevenueModel, page, pageSize, search, sorted } = this.state;
        this.setState({
          requests: await getRequests(
            this.props.selectedAccount.consortiumId,
            status,
            journalRevenueModel === 'Both' ? undefined : journalRevenueModel,
            sorted,
            page,
            pageSize,
            search,
          ),
        });
      }

      @decorate(memoize)
      // eslint-disable-next-line @typescript-eslint/no-shadow
      getVisibleColumns(columns, showPrice, status) {
        const columnsThatCanNotBeHidden = ['request.id', 'account.name', 'journal.revenueModel'];
        let columnsThatCanBeHidden = columnFilterOptions[status].map(x => x.value);

        if (!showPrice) {
          columnsThatCanBeHidden = columnsThatCanBeHidden.filter(x => x !== 'request.amount');
        }

        if (columns.length === 0) {
          return [...columnsThatCanNotBeHidden, ...columnsThatCanBeHidden];
        }

        return [...columnsThatCanNotBeHidden, ...columnsThatCanBeHidden.filter(column => columns.includes(column))];
      }

      onColumnFilterChange(columnFilter) {
        this.setState({ columnFilter });
      }

      onFiltersClear() {
        this.setState(
          { journalRevenueModel: 'Both', columnFilter: [] },
          this.state.journalRevenueModel !== 'Both' ? this.getRequests : undefined,
        );
      }

      onJournalRevenueModelChange(journalRevenueModel) {
        this.setState({ journalRevenueModel, page: 0 }, this.getRequests);
      }

      onListChange(sorted, page, pageSize) {
        this.setState({ sorted, page, pageSize }, this.getRequests);
      }

      onSearchStringChange(search) {
        this.setState({ search, page: 0 }, this.getRequests);
      }

      onSearchStringClear() {
        this.setState({ search: undefined, page: 0 }, this.getRequests);
      }

      render() {
        const { columnFilter, isFetching, journalRevenueModel, requests, search, sorted } = this.state;

        return (
          <Component
            {...this.props}
            canClearFilters={this.getCanClearFilters(columnFilter, journalRevenueModel)}
            columnFilter={columnFilter}
            columnFilterOptions={this.getColumnFilterOptions(this.props.dealShowPrice, status)}
            isFetching={isFetching}
            journalRevenueModel={journalRevenueModel}
            onColumnFilterChange={this.onColumnFilterChange}
            onFiltersClear={this.onFiltersClear}
            onJournalRevenueModelChange={this.onJournalRevenueModelChange}
            onListChange={this.onListChange}
            onSearchStringChange={this.onSearchStringChange}
            onSearchStringClear={this.onSearchStringClear}
            pagination={requests.pagination}
            requests={requests.requests}
            searchString={search}
            sorted={sorted}
            stats={requests.stats}
            visibleColumns={this.getVisibleColumns(columnFilter, this.props.dealShowPrice, status)}
          />
        );
      }
    }

    return compose(
      withSelectedAccountRequired,
      withConsortiumRequired,
      ConsortiaContext.withContext(state => ({
        setConsortiumId: state.setConsortiumId,
        dealShowPrice: get(state, 'dealOOConfiguration.showPrice'),
      })),
      WithRequests,
    );
  };
}

export default withRequests;
