import PropTypes from 'prop-types';
import React from 'react';
import LoaderLayout from 'components/Layouts/LoaderLayout';
import { getHtml } from 'services/api/assets';

export default class StaticContent extends React.PureComponent<{ src: string }> {
  static propTypes = {
    src: PropTypes.string.isRequired,
  };

  state = {
    html: '',
    isFetching: true,
  };

  async componentDidMount() {
    const { src } = this.props;

    this.setState({
      html: await getHtml(src),
      isFetching: false,
    });
  }

  render() {
    const { html, isFetching } = this.state;

    if (isFetching) {
      return <LoaderLayout isLoading />;
    }

    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }
}
