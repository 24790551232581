import Status from './Status';

const licenseStatus = {
  SIGNED: 'Signed',
};

Object.keys(licenseStatus).forEach(code => {
  licenseStatus[code] = new Status(code, licenseStatus[code]);
});

licenseStatus.l = code => {
  if (!code) return '';
  return licenseStatus[code] ? licenseStatus[code].toString() : `[${code}]`;
};

export default licenseStatus;
