import React from 'react';
import MainLayout from 'components/Layouts/MainLayout';

export default function withMainLayout(Component) {
  return function WithMainLayout(props) {
    return (
      <MainLayout>
        <Component {...props} />
      </MainLayout>
    );
  };
}
