import ArticleSubscriptionTypeEnum from 'model/ArticleSubscriptionTypeEnum';

// eslint-disable-next-line import/prefer-default-export
export function canBeAutoApproved(order: WoaRequest, account: WoaAccount) {
  if (account.onHold || order.onHold) {
    return false;
  }

  return (
    order.articleSubscriptionType === ArticleSubscriptionTypeEnum.ONLINE_OPEN &&
    !order.isInternal &&
    !!account?.ooProcessConfiguration?.autoApproval
  );
}
