import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import './noteIcon.scss';

class NoteIcon extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    isShow: false,
  };

  onMouseEnter = () => this.setState(state => (state.isShow ? null : { isShow: true }));

  onMouseLeave = () => this.setState(state => (!state.isShow ? null : { isShow: false }));

  render() {
    const { children } = this.props;
    const { isShow } = this.state;

    return (
      <div className="noteIcon" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <div className={cn('noteIcon-content', { 'noteIcon-content--visibled': isShow })}>{children}</div>
      </div>
    );
  }
}

export default NoteIcon;
