// @ts-ignore
import aboutcookies from 'assets/html/aboutcookies.html';
// @ts-ignore
import help from 'assets/html/help.html';
// @ts-ignore
import helpconsortia from 'assets/html/helpconsortia.html';
// @ts-ignore
import woaatnc from 'assets/html/woaatnc.html';

// eslint-disable-next-line import/prefer-default-export
export function getHtml(src: string): Promise<string> {
  let html = '';

  switch (src) {
    case '/aboutcookies':
      html = aboutcookies;
      break;
    case '/help':
      html = help;
      break;
    case '/helpconsortia':
      html = helpconsortia;
      break;
    case '/woaatnc':
      html = woaatnc;
      break;
    default:
  }

  return Promise.resolve(html);
}
