import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Elements from 'components/Elements';
import NotificationConfigRadioButtons from './NotificationConfigRadioButtons';

const Item = ({ el, onChange, onUpdatePublicationStatuses, seleniumid, suppressEmail, subPubStatusUpdates }) => {
  const isChanged = Object.prototype.hasOwnProperty.call(el, 'newValue');
  const value = isChanged ? el.newValue : el.value;

  return (
    <li className={cn('notificationConfigCheckboxes-item', { 'has-changed': isChanged })}>
      <Elements.Checkbox
        checked={suppressEmail ? false : value}
        disabled={suppressEmail}
        onChange={({ target }) => onChange(el.type, target.checked)}
        seleniumid={seleniumid}
      >
        {el.title}
      </Elements.Checkbox>
      {el.type === 'ALERT_PUBLICATION_STATUS_CHANGED' && (
        <NotificationConfigRadioButtons
          alertPublicationstatusChange={el}
          onChange={onUpdatePublicationStatuses}
          options={subPubStatusUpdates}
          seleniumid={seleniumid}
        />
      )}
    </li>
  );
};

class NotificationConfigCheckboxes extends PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        newValue: PropTypes.bool,
        title: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.bool,
      }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    seleniumid: PropTypes.string,
    suppressEmail: PropTypes.bool,
  };

  static defaultProps = {
    seleniumid: 'notificationConfigCheckboxes',
    suppressEmail: false,
  };

  onUpdatePublicationStatuses = (type, value) => {
    this.props.onChange(type, value);
  };

  render() {
    const { options, onChange, seleniumid, suppressEmail, pubStatusUpdates } = this.props;

    return (
      <ul className="notificationConfigCheckboxes">
        {options.map(el => (
          <Item
            key={el.type}
            el={el}
            onChange={onChange}
            onUpdatePublicationStatuses={this.onUpdatePublicationStatuses}
            seleniumid={`${seleniumid}-${el.type}`}
            subPubStatusUpdates={pubStatusUpdates}
            suppressEmail={suppressEmail}
          />
        ))}
      </ul>
    );
  }
}

export default NotificationConfigCheckboxes;
