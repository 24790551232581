// https://github.com/wiley/as-api/blob/master/funder_dashboard.yaml

import ConsortiaReportTypeEnum from 'const/ConsortiaReportTypeEnum';
import { get } from 'services/ajax';

const baseUrl = `${process.env.API_URL_V2}`;

export async function getAccounts(
  consortiumId: number,
  fromDate: string,
  toDate: string,
  sort?: string,
  page: number = 0,
  size: number = 20,
  accountName?: string,
): Promise<{
  accounts: Array<{ account: ConsortiaAccount; stats: ConsortiaStats }>;
  pagination: Pagination;
  stats: ConsortiaStats;
}> {
  return get(`${baseUrl}/deals/${consortiumId}/accounts`, {
    fromDate,
    toDate,
    sort,
    page,
    size,
    accountName,
  });
}

export async function getDealOOConfiguration(consortiumId: number): Promise<OrderProcessConfiguration> {
  return get(`${baseUrl}/deals/${consortiumId}/ooConfiguration`);
}

export async function getRequests(
  consortiumId: number,
  status: 'pending' | 'approved' | 'denied',
  revenueModel?: string,
  sort?: string,
  page: number = 0,
  size: number = 20,
  search?: string,
): Promise<{
  pagination: Pagination;
  requests: Array<{
    account: ConsortiaAccount;
    article: ConsortiaArticle;
    journal: { id: string; name: string; revenueModel: 'Online Open' | 'Open Access' };
    request: { /* modelType: string; */ id: string; created: string; amount: number };
  }>;
  stats: ConsortiaStats;
}> {
  return get(`${baseUrl}/deals/${consortiumId}/requests/${status}`, {
    revenueModel,
    sort,
    page,
    size,
    search,
  });
}

export async function createReport(
  consortiumId: number,
  reportName: ConsortiaReportTypeEnum,
  fromDate: string,
  toDate: string,
): Promise<void> {
  return get(`${baseUrl}/reports/${reportName}/create`, { consortiumId, fromDate, toDate });
}

export async function downloadReport(reportId: string): Promise<{ downloadUrl: string }> {
  return get(`${baseUrl}/reports/${reportId}/download`);
}
