import React from 'react';
import { Link } from 'react-router-dom';
import withMainLayout from 'with/withMainLayout';

const Page404 = () => (
  <div className="single-message">
    <p>404</p>
    <h1>PAGE NOT FOUND</h1>
    <Link className="btn btn-secondary" to="/">
      Go Home
    </Link>
  </div>
);

export default withMainLayout(Page404);
