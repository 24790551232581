import cn from 'classnames';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import React from 'react';
import { InlineHelp } from 'components/Elements';
import ModalConfirmation from 'components/Modal/ModalConfirmation';
import { form } from 'decorators';
import { currencyChar, trimStringProperties, validation as v } from 'services/utils';

@autobind
@form
class AddFundsModal extends React.PureComponent {
  static propTypes = {
    currency: PropTypes.string.isRequired,
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  state = {
    fields: {
      amount: '',
      purchaseOrderNum: '',
    },
    submitting: false,
    validation: {},
  };

  async onSubmit(e) {
    e.preventDefault();

    if (!this.validate()) {
      return;
    }

    const fields = trimStringProperties(this.state.fields);

    try {
      this.setState({ submitting: true });
      await this.props.onAdd(fields.amount, fields.purchaseOrderNum);
    } finally {
      this.setState({ submitting: false });
    }
  }

  validate() {
    const fields = trimStringProperties(this.state.fields);

    const errors = {
      amount: v.validate(fields.amount, [
        [v.notEmpty, 'Must not be empty.'],
        [v.isPositiveNumber, 'Must be a positive number.'],
      ]),
    };

    this.setState({ validation: errors });

    return Object.values(errors).every(x => !x);
  }

  render() {
    const { currency, onCancel } = this.props;
    const { fields, submitting, validation } = this.state;

    return (
      <ModalConfirmation
        buttonCloseClick={onCancel}
        buttonOkClassName={cn({ 'is-loading': submitting })}
        buttonOkClick={this.onSubmit}
        buttonOkDisabled={submitting}
        buttonOkTitle="Add Funds"
        className="addFundsModal"
        seleniumid="addFundsModal"
        title="Request Invoice"
      >
        <form>
          <div>You will be invoiced for the amount requested.</div>

          <div style={{ display: 'flex', marginTop: '0.5em' }}>
            <div className="text-right" style={{ width: '20%', paddingTop: '4px' }}>
              {`Amount ${currencyChar(currency)}:`}
            </div>
            <div style={{ width: '79%', paddingLeft: '0.5em' }}>
              <input
                className={cn('input w_100p', { 'is-error': validation.amount })}
                data-seleniumid="addFundsModal-amount"
                name="amount"
                onChange={this.onInputChange}
                onFocus={this.onInputFocus}
                type="text"
                value={fields.amount}
              />
              {validation.amount && <InlineHelp className="d-block is-error">{validation.amount}</InlineHelp>}
            </div>
          </div>

          <div style={{ display: 'flex', marginTop: '0.5em' }}>
            <div className="text-right" style={{ width: '20%', paddingTop: '4px' }}>
              PO Number:
            </div>
            <div style={{ width: '79%', paddingLeft: '0.5em' }}>
              <input
                className="input w_100p"
                data-seleniumid="addFundsModal-purchaseOrderNum"
                name="purchaseOrderNum"
                onChange={this.onInputChange}
                placeholder="Optional"
                type="text"
                value={fields.purchaseOrderNum}
              />
            </div>
          </div>
          <div style={{ display: 'flex', marginTop: '0.5em' }}>
            Please note: The amount entered in the ‘Amount’ field above is exclusive of tax.
          </div>
        </form>
      </ModalConfirmation>
    );
  }
}

export default AddFundsModal;
