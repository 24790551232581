/* eslint react/no-unused-state: 0 */

import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { processing } from 'decorators';
import * as mainApi from 'services/api/main';
import { withErrorNotification } from 'storage/NotificationsContext';
import mainWithContext from 'with/withContext';

type NotificationConfigContextInterface = {
  isFetching: boolean;
  emailConfig?: {
    user: UserWoaConfiguration;
    default: DefaultWoaConfiguration;
    group: GroupWoaConfiguration;
  };
  saveNotificationConfig: (
    userEmail: string,
    accountId: number,
    newUser: WoaConfiguration['setting'],
    newGroup: WoaConfiguration['setting'],
  ) => void;
  getNotificationConfig: (userEmail: string, accountId: number) => void;
  reloadNotificationConfig: () => void;
};

const defaultValue: NotificationConfigContextInterface = {
  isFetching: false,
  saveNotificationConfig: () => {},
  getNotificationConfig: () => {},
  reloadNotificationConfig: () => {},
};

const NotificationConfigContext = React.createContext<NotificationConfigContextInterface>(defaultValue);

function withProvider(WrappedComponent) {
  @autobind
  class NotificationConfigProviderWrapper extends PureComponent<any, NotificationConfigContextInterface> {
    state = {
      ...defaultValue,
      saveNotificationConfig: this.saveNotificationConfig,
      getNotificationConfig: this.getNotificationConfig,
    };

    @withErrorNotification
    @processing('isFetching')
    async getNotificationConfig(email, accountId) {
      this.setState({
        reloadNotificationConfig: () => this.getNotificationConfig(email, accountId),
      });

      const emailConfig = await mainApi.getNotificationConfig(email, accountId);

      this.setState({ emailConfig });
    }

    @withErrorNotification
    @processing('isFetching')
    async saveNotificationConfig(userEmail, accountId, newUser, newGroup) {
      await mainApi.saveNotificationConfig(userEmail, accountId, newUser, newGroup);
      this.state.reloadNotificationConfig();
    }

    render() {
      return (
        <NotificationConfigContext.Provider value={this.state}>
          <WrappedComponent {...this.props} />
        </NotificationConfigContext.Provider>
      );
    }
  }

  return NotificationConfigProviderWrapper;
}

export { NotificationConfigContextInterface };
export default {
  useContext: () => React.useContext(NotificationConfigContext),
  withProvider,
  withContext: mainWithContext(NotificationConfigContext),
};
