import React from 'react';
import StatusHelpLine from './StatusHelpLine';

export default function RequestStatuses() {
  return (
    <div style={{ border: '2px solid transparent', padding: '0 0.5em' }}>
      <div className="font-weight-bold text-center" style={{ marginTop: '1em' }}>
        Request Statuses
      </div>

      <StatusHelpLine status="Approved" tooltip="Funding for this article has been approved." />

      <StatusHelpLine
        status="Denied"
        tooltip="Funding for this article has been denied. The author may be contacted for payment, in which case, the funding request will be switched to cancelled."
      />

      <StatusHelpLine
        status="Cancelled"
        tooltip="The funding request for this article has been cancelled. It will not be charged to the account balance. Open Access articles that are approved but not accepted for publication will be shown as cancelled."
      />
    </div>
  );
}
