import cn from 'classnames';
import React, { CSSProperties, FC, useCallback } from 'react';
import ErrorBaner from 'components/ErrorBaner';

const LoaderLayout: FC<{
  isLoading?: boolean;
  isReplace?: boolean;
  isDisabled?: boolean;
  disableLoading?: boolean;
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  error?: Error;
}> = ({ isLoading, isReplace, isDisabled, disableLoading, className, style, children, error }) => {
  const disabled = isDisabled || (isLoading && disableLoading);

  const onKeyDown = useCallback(
    event => {
      if (disabled && event.keyCode !== 8) {
        event.preventDefault();
      }
    },
    [disabled],
  );

  return (
    <div
      className={cn('layout_loader', { 'layout_loader-disabled': disabled }, className)}
      onKeyDown={onKeyDown}
      role="presentation"
      style={style}
    >
      {isLoading && (
        <div className="layout_loader__spinner_container">
          <div className="bg_smole is-loading layout_loader__spinner">&nbsp;</div>
        </div>
      )}

      {error ? <ErrorBaner error={error} /> : (!isLoading || !isReplace) && children}
    </div>
  );
};

LoaderLayout.defaultProps = {
  isLoading: false,
  isReplace: false,
  isDisabled: false,
  disableLoading: true,
  className: null,
  children: null,
  style: null,
  error: null,
};

export default React.memo(LoaderLayout);
