import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import L from 'services/console';

class ErrorBoundary extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    error: null,
    info: null,
  };

  componentDidCatch(error, info) {
    this.setState({ error, info });
    L.error(error, info);
  }

  render() {
    const { error, info } = this.state;

    if (error) {
      return (
        <div style={{ background: '#FFC6CE', padding: '5px' }}>
          {String(error)}
          <hr />
          {String(info)}
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
