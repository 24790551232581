import React, { PureComponent } from 'react';
import AccountSummaryData from 'components/AccountSummary/AccountSummaryData';
import AddFundsModal from 'components/Modal/AddFundsModal';
import Widget from 'components/Widget';
import { addFunds } from 'services/api/account';
import { currencyFormat, dateFormat } from 'services/utils';
import AccountContext, { AccountContextInterface } from 'storage/AccountContext';
import { notify, withErrorNotification } from 'storage/NotificationsContext';
import PermissionEnum from '../../model/PermissionEnum';
import './accountSummary.scss';

class AccountSummary extends PureComponent<
  Pick<AccountContextInterface, 'refreshSelectedAccount' | 'selectedAccount'>,
  { addFundsModal: boolean }
> {
  state = {
    addFundsModal: false,
  };

  onAdd = withErrorNotification(async (amount, purchaseOrderNum) => {
    await addFunds(this.props.selectedAccount.id, amount, purchaseOrderNum);

    this.closeAddFunds();

    const message = `Invoice of amount ${currencyFormat(
      amount,
      this.props.selectedAccount.currency,
    )} has been raised successfully.`;
    notify.info(message);

    this.props.refreshSelectedAccount();
  });

  openAddFunds = () => this.setState({ addFundsModal: true });

  closeAddFunds = () => this.setState({ addFundsModal: false });

  render() {
    const { selectedAccount } = this.props;
    const { addFundsModal } = this.state;
    const { currency } = selectedAccount;
    const isReadOnlyAdmin = selectedAccount.permissions.includes(PermissionEnum.READ_ONLY_ADMIN);

    if (!selectedAccount.showPrice) {
      return null;
    }

    return (
      <Widget
        className="accountSummary"
        title={`Account Summary as of: ${dateFormat(selectedAccount.balancesActualFor, true)}`}
      >
        {selectedAccount.advanceCash ? (
          <AccountSummaryData
            note="Starting balance, minus all approved funding requests charged to this account. Open access articles for which funding is approved but for which editorial acceptance is pending are deducted from Available balance."
            title="Available Balance:"
            value={currencyFormat(selectedAccount.totalAvailableAmount, currency)}
          />
        ) : (
          <AccountSummaryData
            note="Total amount of approved funding requests charged to this account."
            title="Total Spent:"
            value={currencyFormat(selectedAccount.totalAllocated, currency)}
          />
        )}
        <AccountSummaryData
          note="Total charges for articles for which funding has been requested but not yet approved."
          title="Pending Approval:"
          value={currencyFormat(selectedAccount.totalPendingApprovals, currency)}
        />
        <AccountSummaryData
          note="Total charges for articles for which funding has been approved but article has not yet been accepted."
          title="Under Review:"
          value={currencyFormat(selectedAccount.totalPendingAcceptance, currency)}
        />
        <AccountSummaryData
          note="Credits/debits against this account that have not yet been processed by our system. This amount will be processed in our next system update."
          title="In Process:"
          value={currencyFormat(selectedAccount.totalInProcess, currency)}
        />

        {!isReadOnlyAdmin && selectedAccount.advanceCash && (
          <div className="text-center" style={{ marginTop: '0.5em' }}>
            <button
              className="btn btn-secondary"
              data-seleniumid="accountSummary-addFunds"
              onClick={this.openAddFunds}
              type="button"
            >
              Add Funds
            </button>
          </div>
        )}

        {addFundsModal && (
          <AddFundsModal currency={selectedAccount.currency} onAdd={this.onAdd} onCancel={this.closeAddFunds} />
        )}
      </Widget>
    );
  }
}

export default AccountContext.withContext(['refreshSelectedAccount', 'selectedAccount'])(AccountSummary);
