import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

function InlineHelp({ children, className }) {
  if (!children) return null;

  return <small className={cn('inlineHelp', className)}>{children}</small>;
}

InlineHelp.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

InlineHelp.defaultProps = {
  children: null,
  className: '',
};

export default InlineHelp;
