/* eslint react/no-unused-state: 0 */

import { autobind } from 'core-decorators';
import get from 'lodash.get';
import queryString from 'query-string';
import React, { PureComponent } from 'react';
import { processing } from 'decorators';
import PermissionEnum from 'model/PermissionEnum';
import { mainRoutes } from 'routes';
import { getAccountByCode, getAccountById, getAccounts } from 'services/api/account';
import { getUserByParticipantId } from 'services/api/user';
import { notify, withErrorNotification } from 'storage/NotificationsContext';
import ProfileInfoContext from 'storage/ProfileInfoContext';
import storage from 'storage/storage';
import { canUseConsortia } from 'utils/permissions';
import mainWithContext, { compose } from 'with/withContext';
import withNavigate from 'with/withNavigate';

type AccountContextInterface = {
  selectedAccount?: WoaAccount & { permissions: PermissionEnum[] };
  accountsList: Array<{ id: number; code: string; name: string; notCurrent: boolean }>;
  isFetching: boolean;
  refreshSelectedAccount: () => void;
  setSelectedAccountByCode: (code: string) => void;
  setSelectedAccountById: (id: number) => void;
};

const defaultValue: AccountContextInterface = {
  selectedAccount: null,
  accountsList: [],
  isFetching: false,
  refreshSelectedAccount: () => {},
  setSelectedAccountByCode: () => {},
  setSelectedAccountById: () => {},
};

const Context = React.createContext<AccountContextInterface>(defaultValue);

async function getAccountPermissions(profileInfo: WoaLoggableUser, accountId: number): Promise<PermissionEnum[]> {
  if (profileInfo.admin) {
    return [PermissionEnum.ADMIN];
  }
  if (profileInfo.readOnlyAdmin) {
    return [PermissionEnum.READ_ONLY_ADMIN];
  }

  const woauser = await getUserByParticipantId(profileInfo.participantId);
  const permissions = [...woauser.accounts.find(x => x.accountId === accountId).permissions];

  if (!permissions) {
    throw new Error('Can not define role for the account');
  }

  return permissions;
}

function withProvider(WrappedComponent) {
  @autobind
  class ContextWrapper extends PureComponent<
    { isAuth: boolean; navigate: (url: string) => void; profileInfo: WoaLoggableUser },
    AccountContextInterface
  > {
    state = {
      ...defaultValue,
      refreshSelectedAccount: this.refreshSelectedAccount,
      setSelectedAccountByCode: this.setSelectedAccountByCode,
      setSelectedAccountById: this.setSelectedAccountById,
    };

    componentDidMount() {
      if (this.props.isAuth) {
        this.fetchAccountList();
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.isAuth !== prevProps.isAuth) {
        if (this.props.isAuth) {
          this.fetchAccountList();
        } else {
          storage.remove('CURRENT_ACCOUNT');

          this.setState({
            accountsList: [],
            selectedAccount: null,
          });
        }
      }
    }

    @withErrorNotification
    @processing('isFetching')
    async setSelectedAccount(identifier, getAccount) {
      const accountIsManuallySelectedForTheFirstTime = !storage.get('CURRENT_ACCOUNT');
      let selectedAccount = null;

      if (identifier != null) {
        selectedAccount = await getAccount(identifier);
        selectedAccount.permissions = await getAccountPermissions(this.props.profileInfo, selectedAccount.id);
        storage.set('CURRENT_ACCOUNT', selectedAccount.id);
      } else {
        storage.remove('CURRENT_ACCOUNT');
      }

      this.setState({ selectedAccount });

      if (
        accountIsManuallySelectedForTheFirstTime &&
        get(selectedAccount, 'consortiumId') &&
        canUseConsortia(selectedAccount)
      ) {
        this.props.navigate(mainRoutes.consortiaAccounts.getUrl());
      }
    }

    async setSelectedAccountById(id) {
      await this.setSelectedAccount(id, getAccountById);
    }

    async setSelectedAccountByCode(code) {
      await this.setSelectedAccount(code, getAccountByCode);
    }

    @processing('isFetching')
    async fetchAccountList() {
      const accountsList = await getAccounts();

      this.setState({ accountsList });

      const id = Number(queryString.parse(window.location.search).accountId);

      if (id) {
        if (accountsList.find(x => x.id === id)) {
          await this.setSelectedAccountById(id);
        } else {
          notify.error(`Account with ${id} id is not found.`);
        }
      } else if (accountsList.length === 1) {
        await this.setSelectedAccountById(accountsList[0].id);
      } else if (accountsList.length > 0) {
        const storedId = Number(storage.get('CURRENT_ACCOUNT'));
        const account = accountsList.find(x => x.id === storedId);

        if (account) {
          await this.setSelectedAccountById(storedId);
        }
      } else {
        storage.remove('CURRENT_ACCOUNT');
      }
    }

    async refreshSelectedAccount() {
      const id = Number(storage.get('CURRENT_ACCOUNT'));

      if (id) {
        await this.setSelectedAccountById(id);
      }
    }

    render() {
      return (
        <Context.Provider value={this.state}>
          <WrappedComponent {...this.props} />
        </Context.Provider>
      );
    }
  }

  return compose(ProfileInfoContext.withContext(['isAuth', 'profileInfo']), withNavigate, ContextWrapper);
}

export { AccountContextInterface };
export default {
  useContext: () => React.useContext(Context),
  withProvider,
  withContext: mainWithContext(Context),
};
