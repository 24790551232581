/* eslint-disable react/no-unused-state */

import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { processing } from 'decorators';
import { getAccountById } from 'services/api/account';
import { getArticle, getOrderDetails } from 'services/api/main';
import mainWithContext from 'with/withContext';
import { Emitter } from './ArticleNoteContext';
import { withErrorNotification } from './NotificationsContext';

type OrderDetailsContextInterface = {
  orderId?: number;
  orderDetails?: any;
  isFetching: boolean;
  error?: Error;
  getOrderDetails: (orderId: number) => void;
  reloadOrderDetails: () => void;
};

const defaultValue: OrderDetailsContextInterface = {
  isFetching: false,
  getOrderDetails: () => {},
  reloadOrderDetails: () => {},
};

const OrderDetailsContext = React.createContext<OrderDetailsContextInterface>(defaultValue);

let lastOrderIdRequest = null;

function withProvider(WrappedComponent) {
  @autobind
  class OrderDetailsProviderWrapper extends PureComponent<any, OrderDetailsContextInterface> {
    state = {
      ...defaultValue,
      getOrderDetails: this.getOrderDetails,
    };

    subscriptions = [];

    componentDidMount() {
      this.subscriptions.push(
        Emitter.NOTE_WAS_ADDED.subscribe(this.afterNoteEvent),
        Emitter.NOTE_WAS_UPDATED.subscribe(this.afterNoteEvent),
        Emitter.NOTE_WAS_REMOVED.subscribe(this.afterNoteEvent),
      );
    }

    componentWillUnmount() {
      this.subscriptions.forEach(x => x());
    }

    afterNoteEvent() {
      this.state.reloadOrderDetails();
    }

    @withErrorNotification
    @processing('isFetching')
    async getOrderDetails(orderId: number) {
      lastOrderIdRequest = orderId;

      this.setState(state => ({
        orderId,
        orderDetails: state.orderId !== orderId ? undefined : state.orderDetails,
        error: undefined,
        reloadOrderDetails: () => this.getOrderDetails(orderId),
      }));

      try {
        const orderDetails = await getOrderDetails(orderId);
        const [woaaccount, woaarticle] = await Promise.all([
          getAccountById(orderDetails.accountId),
          getArticle(orderDetails.articleId),
        ]);

        // @ts-ignore
        woaarticle.woanotesUser = woaarticle.woanotes.filter(
          el => el.isPublic && !el.isInternal && !el.woaloggableUser.admin,
        );
        if (woaaccount.woanotes.length > 0) {
          const internalNotes = woaaccount.woanotes.filter(({ id: accountNoteId }) =>
            woaarticle.woanotes.some(
              ({ id: articleNoteId, isPublic: articlePublic, isInternal: articleInternal, woaloggableUser: isAdmin }) =>
                accountNoteId === articleNoteId && articlePublic && articleInternal && !isAdmin.admin,
            ),
          );
          // @ts-ignore
          woaarticle.woanotesUserInternal = internalNotes;
        }
        // @ts-ignore
        woaarticle.woanotesAdmin = woaarticle.woanotes.filter(el => el.isPublic && el.woaloggableUser.admin);

        // @ts-ignore
        orderDetails.woaaccount = woaaccount;
        orderDetails.woaarticle = woaarticle;

        if (orderId === lastOrderIdRequest) {
          this.setState({
            orderDetails,
            orderId,
          });
        }
      } catch (error) {
        this.setState({
          error,
          orderDetails: undefined,
        });

        throw error;
      }
    }

    render() {
      return (
        <OrderDetailsContext.Provider value={this.state}>
          <WrappedComponent {...this.props} />
        </OrderDetailsContext.Provider>
      );
    }
  }

  return OrderDetailsProviderWrapper;
}

export { OrderDetailsContextInterface };
export default {
  useContext: () => React.useContext(OrderDetailsContext),
  withProvider,
  withContext: mainWithContext(OrderDetailsContext),
};
