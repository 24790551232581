import get from 'lodash.get';
import PermissionEnum from 'model/PermissionEnum';

function canManageUsers(woaaccount: WoaAccount): boolean {
  return [PermissionEnum.ADMIN, PermissionEnum.OWNER].some(permission =>
    get(woaaccount, 'permissions', []).includes(permission),
  );
}

function canUseAlerts(woaaccount: WoaAccount): boolean {
  return [PermissionEnum.ADMIN, PermissionEnum.READ_ONLY_ADMIN].every(
    permission => !!woaaccount && !get(woaaccount, 'permissions', []).includes(permission),
  );
}

function canUseConsortia(woaaccount: WoaAccount): boolean {
  return [PermissionEnum.ADMIN, PermissionEnum.CONSORTIA, PermissionEnum.READ_ONLY_ADMIN].some(permission =>
    get(woaaccount, 'permissions', []).includes(permission),
  );
}

export { canManageUsers, canUseAlerts, canUseConsortia };
