import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { itIsParent } from 'services/utils';
import { ModalsWindows, addToHash, removeFromHash } from './modalManager';
import './modal.scss';

const bodyRoot = document.getElementById('modal_root');

class Modal extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    hash: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    children: PropTypes.node,
    title: PropTypes.node,
    titleClose: PropTypes.bool,
    closeEsc: PropTypes.bool,
    footer: PropTypes.node,
    isMobile: PropTypes.bool,
    seleniumid: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    hash: null,
    children: null,
    title: null,
    titleClose: false,
    closeEsc: false,
    footer: null,
    isMobile: false,
    seleniumid: 'modal',
  };

  DOM = {
    modal: React.createRef(),
  };

  constructor(props) {
    super(props);
    this.hashKey = null;
    this.parent = document.createElement('div');
    this.parent.classList.add('enote_modal_container');
  }

  componentDidMount() {
    const { hash } = this.props;
    this.hashKey = hash || String(Math.random()).slice(2, 5);
    bodyRoot.appendChild(this.parent);
    ModalsWindows.push(this);
    addToHash(this.hashKey);
  }

  componentWillUnmount() {
    bodyRoot.removeChild(this.parent);
    const n = ModalsWindows.findIndex(el => el === this);
    if (n >= 0) {
      ModalsWindows.splice(n, 1);
    }
    removeFromHash(this.hashKey);
  }

  publicClose = () => {
    const { closeModal } = this.props;
    if (closeModal) {
      closeModal();
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  publicCloseEsc = () => (this.props.closeEsc ? this.publicClose() : null);

  onKeyDown = event => {
    if (event.keyCode === 9) {
      const { shiftKey } = event;

      setTimeout(() => {
        const modalDom = this.DOM.modal.current;
        const target = document.activeElement;
        const inModal = target ? itIsParent(target, modalDom) : false;
        const focusable = modalDom.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
        );
        if (!inModal) {
          if (focusable.length > 0) {
            focusable[shiftKey ? focusable.length - 1 : 0].focus();
          }
        }
      }, 0);
    }
  };

  renderModal() {
    const { children, footer, title, titleClose, className, isMobile, seleniumid } = this.props;

    return (
      <div
        ref={this.DOM.modal}
        className={cn('enote_modal', { fullscreen_mobile: isMobile }, className)}
        data-seleniumid={seleniumid}
        onKeyDown={this.onKeyDown}
        role="presentation"
      >
        <div className="enote_modal__window" role="presentation">
          {(title || titleClose) && (
            <header key="modalHeader" className="enote_modal__header">
              <div className="enote_modal__title">{title}</div>
              {titleClose && (
                <button
                  className="delete"
                  data-seleniumid={`${seleniumid}-close`}
                  onClick={this.publicClose}
                  type="button"
                />
              )}
            </header>
          )}
          <div className="enote_modal__bottom">
            <div className="enote_modal__content">{children}</div>
            {footer && (
              <footer key="modalFooter" className="enote_modal__footer">
                {footer}
              </footer>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return ReactDOM.createPortal(this.renderModal(), this.parent);
  }
}

export default Modal;
