import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

function Checkbox({ className, children, classNameLabel, seleniumid, tooltip, ...anotherProps }) {
  return (
    <label className={cn('it_checkbox_cover', className)} data-seleniumid={seleniumid} data-title={tooltip}>
      <input {...anotherProps} className="it_checkbox" data-seleniumid={`${seleniumid}-checkbox`} type="checkbox" />
      <span className="it_checkbox_mark" />
      {children && <span className={cn('it_checkbox_label', classNameLabel)}>{children}</span>}
    </label>
  );
}

Checkbox.propTypes = {
  className: PropTypes.string,
  classNameLabel: PropTypes.string,
  children: PropTypes.node,
  seleniumid: PropTypes.string,
  tooltip: PropTypes.string,
};

Checkbox.defaultProps = {
  className: '',
  classNameLabel: '',
  children: null,
  seleniumid: '',
  tooltip: undefined,
};

export default Checkbox;
