import cn from 'classnames';
import React from 'react';

function Header({ classNamePrefix, field, title }) {
  return (
    <span
      className={`${classNamePrefix}-headTitle-${field}`}
      data-seleniumid={`${classNamePrefix}-headTitle--${field}`}
    >
      {title}
    </span>
  );
}

function SortingHeader({
  classNamePrefix,
  current,
  field,
  // onSort,
  title,
}) {
  const asc = `${field}:asc`;
  const desc = `${field}:desc`;
  // const defaultSort = '';

  // const getSortType = (current) => {
  //   if (current === defaultSort) return asc;
  //   if (current === asc) return desc;
  //   if (current && current !== asc && current !== desc) return asc;
  //   return defaultSort;
  // };
  //

  const isActive = current === asc || current === desc;

  return (
    <>
      <span
        className={`${classNamePrefix}-headTitle-${field}`}
        data-seleniumid={`${classNamePrefix}-headTitle--${field}`}
      >
        {title}
      </span>
      &nbsp;
      <span
        className={cn('sortIcon', {
          'sortIcon--nonActive': !isActive,
          'sortIcon--asc': current === asc,
          'sortIcon--desc': current === desc,
        })}
      />
    </>
  );
}

function getSort(sorted = []) {
  if (sorted.length > 0) {
    return `${sorted[0].id}:${sorted[0].desc ? 'desc' : 'asc'}`;
  }

  return undefined;
}

export { Header, SortingHeader, getSort };
