/* global digitalData: true */

import { tryCatchLog } from 'decorators';

class Analytics {
  @tryCatchLog
  setUser(email, firstName, lastName, participantId) {
    if (typeof digitalData !== 'undefined') {
      digitalData.user = {
        email,
        firstName,
        lastName,
        participantId,
      };

      this.fireEvent('userLoggedIn', digitalData.user);
    }
  }

  @tryCatchLog
  cleanUser() {
    if (typeof digitalData !== 'undefined') {
      digitalData.user = {};
    }
  }

  @tryCatchLog
  fireEvent(eventName, eventData = undefined) {
    if (typeof digitalData !== 'undefined') {
      digitalData.event.push({ eventInfo: { eventName, eventData } });
    }
  }
}

export default new Analytics();
