import queryString from 'query-string';
import ErrorEnum from 'model/ErrorEnum';
import L from 'services/console';
import emitter from 'services/events/emitter';
import EventEnum from 'services/events/EventEnum';

export const defaultOptions = {
  credentials: 'include',
  mode: 'cors',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json; charset=UTF-8',
  },
};

function handleApiError(errorType, errorMessage, url) {
  if (errorType === ErrorEnum.UNAUTHORIZED) {
    if (url.includes('/login/logout')) {
      return Promise.resolve(undefined);
    }

    if (!url.includes('/login/currentUser')) {
      emitter.emit(EventEnum.SESSION_EXPIRED);
    }
  }

  const error = new Error(errorMessage);
  error.name = errorType;
  error.isFail = true;
  L.error(url, error);
  return Promise.reject(error);
}

async function json(url, options) {
  // eslint-disable-next-line no-param-reassign
  options = {
    ...defaultOptions,
    ...options,
    headers: {
      ...defaultOptions.headers,
      ...options.headers,
    },
  };

  try {
    emitter.emit(EventEnum.API_REQUEST_STARTED);

    const response = await fetch(url, options);

    try {
      return await response.json();
    } catch (error) {
      if (!response.ok) {
        throw new Error(response.statusText || 'Something went wrong. Please try again later.');
      }

      throw new Error('Response is malformed. Json is expected.');
    }
  } catch (error) {
    L.error(url, error);
    throw error;
  } finally {
    emitter.emit(EventEnum.API_REQUEST_ENDED);
  }
}

function jsonCatch(fn) {
  return async function (url, data) {
    const result = await fn(url, data);

    if (!result) {
      const error = new Error('Response from server is empty');
      error.inFetch = true;
      L.error(url, error);
      return Promise.reject(error);
    }

    if (result.error) {
      // api version 1
      return handleApiError(result.error.type, result.error.message, url);
    }
    if (result.errors) {
      // api version 2
      return handleApiError(result.errors[0].code, result.errors[0].message, url);
    }

    return result;
  };
}

const get = jsonCatch((url, data = null) => {
  if (data) {
    const parsedUrl = queryString.parseUrl(url);
    parsedUrl.query = { ...parsedUrl.query, ...data };
    // eslint-disable-next-line no-param-reassign
    url = `${parsedUrl.url}?${queryString.stringify(parsedUrl.query)}`;
  }

  return json(url, { method: 'GET' });
});

const post = jsonCatch((url, data = null) => {
  const body = data ? JSON.stringify(data) : '';

  return json(url, { body, method: 'POST' });
});

const put = jsonCatch((url, data = null) => {
  const body = data ? JSON.stringify(data) : '';

  return json(url, { body, method: 'PUT' });
});

const del = jsonCatch((url, data = null) => {
  const body = data ? JSON.stringify(data) : '';

  return json(url, { body, method: 'DELETE' });
});

export { get, post, put, del };
