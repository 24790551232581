import cn from 'classnames';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import React from 'react';
import { InlineHelp } from 'components/Elements';
import ModalConfirmation from 'components/Modal/ModalConfirmation';
import { form } from 'decorators';
import { getRemainingChars, trimStringProperties, validation as v } from 'services/utils';

@autobind
@form
class DenyOrderModal extends React.PureComponent {
  static propTypes = {
    onDeny: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  state = {
    fields: {
      reason: '',
    },
    validation: {},
    reasonMaxLength: 250,
  };

  onSubmit(e) {
    e.preventDefault();

    if (!this.validate()) {
      return;
    }

    const fields = trimStringProperties(this.state.fields);

    this.props.onDeny(fields.reason);
  }

  validate() {
    const fields = trimStringProperties(this.state.fields);
    const { reasonMaxLength } = this.state;

    const errors = {
      reason: v.validate(fields.reason, [
        [v.checkStringMaxLength(reasonMaxLength), `Text is too big. Maximum ${reasonMaxLength} characters.`],
      ]),
    };

    this.setState({ validation: errors });

    return Object.values(errors).every(x => !x);
  }

  renderInlineHelpContent() {
    const { fields, reasonMaxLength } = this.state;
    const remainingChars = getRemainingChars(fields.reason, reasonMaxLength);

    return (
      <span data-seleniumid="denyOrderModal-inlineHelp">
        {`Maximum ${reasonMaxLength} characters`}
        {remainingChars < 0 && <span> (text is too big)</span>}
        {remainingChars >= 0 && remainingChars < reasonMaxLength && <span>{` (${remainingChars} remaining)`}</span>}
      </span>
    );
  }

  render() {
    const { onCancel } = this.props;

    const { fields, validation } = this.state;

    return (
      <ModalConfirmation
        buttonCloseClick={onCancel}
        buttonOkClassName=""
        buttonOkClick={this.onSubmit}
        buttonOkTitle="Deny"
        className="denyOrderModal"
        seleniumid="denyOrderModal"
        title="Deny Order"
      >
        <form>
          <p>
            Please state the reason for denying this funding request. For articles that have been accepted for
            publication, this will be sent to the (responsible) corresponding author for their information.
          </p>

          <textarea
            className={cn({ 'is-error': validation.reason })}
            data-seleniumid="denyOrderModal-reason"
            name="reason"
            onChange={this.onInputChange}
            onFocus={this.onInputFocus}
            style={{
              resize: 'none',
              width: '100%',
              height: '8em',
              padding: '0.5em',
              font: 'inherit',
            }}
            value={fields.reason}
          />
          <InlineHelp className={cn({ 'is-error': validation.reason })}>
            {validation.reason ? validation.reason : this.renderInlineHelpContent()}
          </InlineHelp>
        </form>
      </ModalConfirmation>
    );
  }
}

export default DenyOrderModal;
