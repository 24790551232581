import cn from 'classnames';
import React from 'react';
import ReactSelect, { components } from 'react-select';
import Elements from 'components/Elements';
import './ColumnFilter.scss';

function ColumnFilter({ className, onChange, options, value = [] }) {
  return (
    <ReactSelect
      className={cn('columnFilter', className)}
      classNamePrefix="columnFilter"
      components={{
        // eslint-disable-next-line @typescript-eslint/no-shadow
        Option: ({ children, className, ...rest }) => (
          <components.Option {...rest} className={cn(className, { 'text-primary': rest.isSelected })}>
            <Elements.Checkbox checked={rest.isSelected} onChange={() => {}}>
              {children}
            </Elements.Checkbox>
          </components.Option>
        ),
      }}
      hideSelectedOptions={false}
      isClearable={false}
      isMulti
      isSearchable={false}
      onChange={e => onChange(e.map(option => option.value))}
      options={options}
      placeholder="Filter columns"
      styles={{
        container: styles => ({
          ...styles,
          width: null,
          minWidth: '370px',
          zIndex: 5,
        }),
        control: (styles, state) => ({
          ...styles,
          borderColor: state.isFocused ? '#209d9d' : styles.borderColor,
          borderRadius: '0',
          boxShadow: state.isFocused ? '0 0 0 1px #209d9d' : styles.boxShadow,
          '&:hover': { borderColor: undefined },
        }),
        option: styles => ({
          ...styles,
          backgroundColor: null,
          padding: '2px 12px',
          '&:hover': { backgroundColor: '#cef1f3' },
        }),
      }}
      value={options.filter(option => value.some(v => v === option.value))}
    />
  );
}

export default ColumnFilter;
