enum PermissionEnum {
  ADMIN = 'admin', // virtual, for ui use only
  NONE = 'none',
  OWNER = 'owner',
  USER = 'user',
  CONSORTIA = 'consortia',
  READ_ONLY_ADMIN = 'readOnlyAdmin',
}

export default PermissionEnum;
