import PropTypes from 'prop-types';
import React from 'react';
import ErrorBaner from 'components/ErrorBaner';
import LoaderLayout from 'components/Layouts/LoaderLayout';
import ErrorEnum from 'model/ErrorEnum';
import ProfileInfoContext from 'storage/ProfileInfoContext';

export default function withAuthRequired(Component) {
  return ProfileInfoContext.withContext(['authError', 'isAuth', 'isFetching', 'login'])(
    class extends React.PureComponent {
      static async getDerivedStateFromProps(nextProps) {
        const { authError, isFetching, isAuth, login } = nextProps;

        if (!isFetching && !isAuth && (authError == null || authError.name === ErrorEnum.UNAUTHORIZED)) {
          login();
        }
      }

      static propTypes = {
        authError: PropTypes.shape({ name: PropTypes.string }),
        isAuth: PropTypes.bool.isRequired,
        isFetching: PropTypes.bool.isRequired,
        login: PropTypes.func.isRequired,
      };

      static defaultProps = {
        authError: null,
      };

      state = {};

      render() {
        const { authError, isAuth, isFetching } = this.props;

        if (isFetching) {
          return <LoaderLayout isLoading />;
        }
        if (authError != null && authError.name !== ErrorEnum.UNAUTHORIZED) {
          if (authError.name === ErrorEnum.AUTHORIZED_USER_NOT_FOUND) {
            return (
              <ErrorBaner error={new Error('Need access to a WOA Account?')}>
                <p>The authenticated user is not found in our database.</p>
                <p>
                  If you would like access to a WOA Account Dashboard, please contact a WOA Account owner from your
                  institution.
                </p>
                <p>Don&apos;t know who that is?</p>
                <p>
                  {`Contact the WOA Account Dashboard Administrator `}
                  <a href="mailto:accountdashboard@wiley.com">accountdashboard@wiley.com</a>
                </p>
                <p>
                  {`Return to `}
                  <a href="https://authorservices.wiley.com/home.html">Author Services</a>
                </p>
              </ErrorBaner>
            );
          }

          return (
            <ErrorBaner error={authError}>
              <p>Can not initialize application.</p>
              <p>
                {`Contact the WOA Account Dashboard Administrator `}
                <a href="mailto:accountdashboard@wiley.com">accountdashboard@wiley.com</a>
              </p>
              <p>
                {`Return to `}
                <a href="https://authorservices.wiley.com/home.html">Author Services</a>
              </p>
            </ErrorBaner>
          );
        }
        if (!isAuth) {
          return (
            <ErrorBaner error={new Error('Authorization is required.')}>
              <p>To access this page you need to be authorized. Redirecting to login page...</p>
            </ErrorBaner>
          );
        }

        return <Component {...this.props} />;
      }
    },
  );
}
