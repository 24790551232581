import cn from 'classnames';
import { autobind, decorate } from 'core-decorators';
import memoize from 'memoize-one';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import LoaderLayout from 'components/Layouts/LoaderLayout';
import ConsortiaDatesEnum from 'const/ConsortiaDatesEnum';
import ConsortiaContext from 'storage/ConsortiaContext';
import './TimePeriodPicker.scss';
import CustomDateRangePicker from './CustomDateRangePicker';

function Button({ children, className, isSelected, onClick, sid, useSpan }) {
  const props = {
    children,
    className: cn('btn timePeriodPicker-button', className, {
      'btn-primary': isSelected,
      'btn-light': !isSelected,
      'timePeriodPicker-button--selected': isSelected,
    }),
    'data-seleniumid': sid ? `timePeriodPicker-button--${sid}` : undefined,
    onClick,
  };

  return useSpan ? <span {...props} /> : <button {...props} type="button" />;
}

class TimePeriodPicker extends React.PureComponent {
  static propTypes = {
    dates: PropTypes.shape({
      id: PropTypes.string,
      fromDate: PropTypes.string,
      toDate: PropTypes.string,
    }).isRequired,
    dealOOConfiguration: PropTypes.shape({
      dealStartDate: PropTypes.string,
      dealEndDate: PropTypes.string,
    }),
    setDates: PropTypes.func.isRequired,
    setDatesIdWithoutReloading: PropTypes.func.isRequired,
  };

  static defaultProps = {
    dealOOConfiguration: undefined,
  };

  @autobind
  onSelectMonth(e) {
    if (Number(e.target.value) === -1) {
      return;
    }

    this.props.setDates(
      ConsortiaDatesEnum.MONTH,
      moment(e.target.value).startOf('month'),
      moment(e.target.value).endOf('month'),
    );
  }

  @autobind
  onSelectYear(e) {
    if (Number(e.target.value) === -1) {
      return;
    }

    this.props.setDates(
      ConsortiaDatesEnum.YEAR,
      moment().set('year', e.target.value).startOf('year'),
      moment().set('year', e.target.value).endOf('year'),
    );
  }

  @decorate(memoize)
  getMonths(startDate) {
    const months = [];

    for (
      let i = moment().startOf('month');
      i >= moment(startDate).startOf('month');
      i = moment(i).subtract(1, 'month')
    ) {
      const year = i.get('year');

      if (months.length === 0 || months[months.length - 1].year !== year) {
        months.push({ year, dates: [] });
      }

      months[months.length - 1].dates.push(i);
    }

    return months;
  }

  @decorate(memoize)
  getYears(startDate) {
    const currentYear = moment().get('year');
    const startYear = moment(startDate).get('year');

    const years = [];

    for (let i = currentYear; i >= startYear; i -= 1) {
      years.push(i);
    }

    return years;
  }

  render() {
    const { dates, dealOOConfiguration, setDates, setDatesIdWithoutReloading } = this.props;

    if (!dealOOConfiguration) {
      return <LoaderLayout isLoading />;
    }

    const filterStartDate = moment(dealOOConfiguration.dealStartDate).subtract(1, 'year').startOf('year');
    const months = this.getMonths(filterStartDate);
    const years = this.getYears(filterStartDate);

    return (
      <div className="timePeriodPicker">
        <div className="timePeriodPicker-ButtonsContainer">
          <Button
            isSelected={dates.id === ConsortiaDatesEnum.YTD}
            onClick={() => setDates(ConsortiaDatesEnum.YTD, moment().startOf('year'), moment())}
            sid="ytd"
          >
            YTD
          </Button>

          <Button
            isSelected={dates.id === ConsortiaDatesEnum.LAST_7_DAYS}
            onClick={() => setDates(ConsortiaDatesEnum.LAST_7_DAYS, moment().subtract(7, 'days'), moment())}
            sid="last_7_days"
          >
            7 Days
          </Button>

          <Button
            isSelected={dates.id === ConsortiaDatesEnum.LAST_14_DAYS}
            onClick={() => setDates(ConsortiaDatesEnum.LAST_14_DAYS, moment().subtract(14, 'days'), moment())}
            sid="last_14_days"
          >
            14 Days
          </Button>

          {months.length === 1 && months[0].dates.length === 1 ? (
            <Button
              isSelected={dates.id === ConsortiaDatesEnum.MONTH}
              onClick={() =>
                setDates(
                  ConsortiaDatesEnum.MONTH,
                  moment(months[0].dates[0]).startOf('month'),
                  moment(months[0].dates[0]).endOf('month'),
                )
              } // eslint-disable-line react/jsx-curly-newline
              sid="month"
            >
              {moment(months[0].dates[0]).format('MMM YYYY')}
            </Button>
          ) : (
            <Button
              className="timePeriodPicker-button--month"
              isSelected={dates.id === ConsortiaDatesEnum.MONTH}
              useSpan
            >
              {dates.id === ConsortiaDatesEnum.MONTH ? `${moment(dates.fromDate).format('MMM YYYY')}` : 'Month'}
              <span className="img--arrowDown ml_5" />
              <select
                className="select"
                data-seleniumid="timePeriodPicker-selectMonth"
                name="selectMonth"
                onChange={this.onSelectMonth}
                value={dates.id === ConsortiaDatesEnum.MONTH ? moment(dates.fromDate).format('YYYY-MM-DD') : -1}
              >
                <optgroup hidden label="Select month">
                  <option disabled hidden value="-1">
                    None
                  </option>
                </optgroup>
                {months.map(({ year, dates: datesOfYear }) => (
                  <optgroup key={year} label={year}>
                    {datesOfYear.map(date => (
                      <option
                        key={moment(date).format('YYYY-MM-DD')}
                        data-seleniumid={`timePeriodPicker-selectMonth--${moment(date).format('MMM')}`}
                        value={moment(date).format('YYYY-MM-DD')}
                      >
                        {moment(date).format('MMM')}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            </Button>
          )}

          {years.length === 1 ? (
            <Button
              isSelected={dates.id === ConsortiaDatesEnum.YEAR}
              onClick={() =>
                setDates(
                  ConsortiaDatesEnum.YEAR,
                  moment().set('year', years[0]).startOf('year'),
                  moment().set('year', years[0]).endOf('year'),
                )
              } // eslint-disable-line react/jsx-curly-newline
              sid="year"
            >
              {years[0]}
            </Button>
          ) : (
            <Button className="timePeriodPicker-button--year" isSelected={dates.id === ConsortiaDatesEnum.YEAR} useSpan>
              {dates.id === ConsortiaDatesEnum.YEAR ? `${moment(dates.fromDate).get('year')}` : 'Year'}
              <span className="img--arrowDown ml_5" />
              <select
                className="select"
                data-seleniumid="timePeriodPicker-selectYear"
                name="selectYear"
                onChange={this.onSelectYear}
                value={dates.id === ConsortiaDatesEnum.YEAR ? moment(dates.fromDate).get('year') : -1}
              >
                <optgroup hidden label="Select year">
                  <option disabled hidden value="-1">
                    None
                  </option>
                </optgroup>
                {years.map(year => (
                  <option key={year} data-seleniumid={`timePeriodPicker-selectYear--${year}`} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </Button>
          )}

          <Button
            isSelected={dates.id === ConsortiaDatesEnum.ALL_HISTORY}
            onClick={() => setDates(ConsortiaDatesEnum.ALL_HISTORY, filterStartDate, moment())}
            sid="all"
          >
            All history
          </Button>

          <Button
            isSelected={dates.id === ConsortiaDatesEnum.CUSTOM_RANGE}
            onClick={() => setDatesIdWithoutReloading(ConsortiaDatesEnum.CUSTOM_RANGE)}
            sid="custom"
          >
            Custom Date Range
          </Button>
        </div>
        {dates.id === ConsortiaDatesEnum.CUSTOM_RANGE && (
          <CustomDateRangePicker dates={dates} filterStartDate={filterStartDate} setDates={setDates} />
        )}
      </div>
    );
  }
}

export default ConsortiaContext.withContext(['dates', 'dealOOConfiguration', 'setDates', 'setDatesIdWithoutReloading'])(
  TimePeriodPicker,
);
