import React, { useMemo } from 'react';
import AccountSelector from 'components/AccountSelector';
import AccountContext, { AccountContextInterface } from 'storage/AccountContext';

import './accountSelectorForm.scss';

const AccountSelectorForm: React.VFC = () => {
  const { accountsList, selectedAccount, setSelectedAccountById } = AccountContext.useContext();

  const [selected, setSelected] = React.useState<AccountContextInterface['accountsList'][0]>(selectedAccount);

  const activeAccounts = useMemo(() => accountsList.filter(x => !x.notCurrent), [accountsList]);
  const inactiveAccounts = useMemo(() => accountsList.filter(x => x.notCurrent), [accountsList]);

  const onSubmitAccount = event => {
    event.preventDefault();
    if (selected) {
      setSelectedAccountById(selected.id);
    }
  };

  const onChangeAndConfirm = account => {
    setSelected(account);
    setSelectedAccountById(account.id);
  };

  return (
    <form className="accountSelectorForm" data-seleniumid="accountSelectorForm" onSubmit={onSubmitAccount}>
      <h4>Please choose the account you wish to manage</h4>
      <AccountSelector onChange={setSelected} onChangeAndConfirm={onChangeAndConfirm} selected={selected} />

      {(activeAccounts.length > 5 || inactiveAccounts.length > 0) && (
        <button className="btn btn-secondary" data-seleniumid="accountSelectorForm-btnContinue" type="submit">
          Continue
        </button>
      )}
    </form>
  );
};

export default AccountSelectorForm;
