import React from 'react';
import NoteEditorModal from 'components/NoteEditorModal';
import NoteList from 'components/OrderDetailsModal/NoteList';

type Props = {
  accountId: number;
  articleId: string;
  isEditable: boolean;
  notes: WoaNote[];
};

export default class UserInternalNoteList extends React.PureComponent<Props> {
  state = {
    newNote: false,
    isInternal: true,
  };

  showNewNote = () => this.setState({ newNote: true, isInternal: true });

  hideNewNote = () => this.setState({ newNote: false });

  render() {
    const { accountId, articleId, isEditable, notes } = this.props;
    const { newNote, isInternal } = this.state;

    return (
      <section className="orderDetailsNotes">
        <div className="orderDetailsNotes_title">WOA User Internal Notes</div>
        <div data-seleniumid="userInternalNoteList-notes">
          {notes && notes.length > 0 ? (
            <NoteList editable={isEditable} notes={notes} />
          ) : (
            <>
              <div className="p_5">NOT AVAILABLE</div>
              {isEditable && (
                <div className="p_5">
                  <button
                    className="btn btn-secondary btn-sm"
                    data-seleniumid="orderDetailsView-addInternalNote"
                    onClick={this.showNewNote}
                    type="button"
                  >
                    Add new note
                  </button>
                </div>
              )}
            </>
          )}

          {newNote && (
            <NoteEditorModal
              accountId={accountId}
              articleId={articleId}
              close={this.hideNewNote}
              isInternal={isInternal}
            />
          )}
        </div>
      </section>
    );
  }
}
