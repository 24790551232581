/* eslint react/no-unused-state: 0 */

import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import OrderStatusEnum from 'const/OrderStatusEnum';
import { processing } from 'decorators';
import * as mainApi from 'services/api/main';
import { notify, withErrorNotification } from 'storage/NotificationsContext';
import mainWithContext from 'with/withContext';

type OrdersContextInterface = {
  ordersList: WoaRequest[];
  isFetching: boolean;
  isReportSending: boolean;
  keyword?: string;
  error?: Error;
  getOrdersList: (accountId: number, params?: { keyword?: string; showAll?: boolean }) => void;
  reloadOrders: (showAll?: boolean) => void;
  requestOrdersReport: (dateFrom?: string, dateTo?: string) => void;
};

const defaultValue: OrdersContextInterface = {
  ordersList: [],
  isFetching: true,
  isReportSending: false,
  getOrdersList: () => {},
  reloadOrders: () => {},
  requestOrdersReport: () => {},
};

const OrdersContext = React.createContext<OrdersContextInterface>(defaultValue);

function withProvider(WrappedComponent) {
  @autobind
  class OrdersProviderWrapper extends PureComponent<any, OrdersContextInterface> {
    state = { ...defaultValue, getOrdersList: this.getOrdersList };

    @withErrorNotification
    @processing('isFetching')
    async getOrdersList(accountId: number, { keyword, showAll }: { keyword?: string; showAll?: boolean } = {}) {
      const query = {
        keyword: keyword || undefined,
        statuses: [OrderStatusEnum.APPROVED].join(','),
      };

      if (showAll) {
        query.statuses = [OrderStatusEnum.APPROVED, OrderStatusEnum.DENIED, OrderStatusEnum.CANCELLED].join(',');
      }

      this.setState({
        keyword,
        reloadOrders: showAllStatuses => this.getOrdersList(accountId, { keyword, showAll: showAllStatuses }),
        requestOrdersReport: (dateFrom, dateTo) =>
          this.requestOrdersReport(accountId, query.statuses, dateFrom, dateTo, keyword),
      });

      try {
        const ordersList = await mainApi.getOrders(accountId, query.keyword, query.statuses);

        this.setState({ error: null, ordersList });
      } catch (error) {
        this.setState({ error, ordersList: [] });

        throw error;
      }
    }

    @withErrorNotification
    @processing('isReportSending')
    async requestOrdersReport(accountId, statuses, dateFrom, dateTo, keyword) {
      await mainApi.requestOrdersReport(accountId, statuses, dateFrom, dateTo, keyword);
      notify.info('Report is being generated. It will be sent by email upon completion.');
    }

    render() {
      return (
        <OrdersContext.Provider value={this.state}>
          <WrappedComponent {...this.props} />
        </OrdersContext.Provider>
      );
    }
  }

  return OrdersProviderWrapper;
}

export { OrdersContextInterface };
export default {
  useContext: () => React.useContext(OrdersContext),
  withProvider,
  withContext: mainWithContext(OrdersContext),
};
