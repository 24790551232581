import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

const closeTimers = {};

type Props = {
  notification: {
    id: string;
    content: string;
    duration: number;
    messageType: 'error' | 'info';
    onClose?: (id: string) => void;
  };
  onClose?: (id: string) => void;
};

class NotificationPopup extends PureComponent<Props> {
  static propTypes = {
    onClose: PropTypes.func,
    notification: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    onClose: null,
  };

  componentDidMount() {
    const { onClose, notification } = this.props;
    if (onClose && notification.duration > 0 && !closeTimers[notification.id]) {
      closeTimers[notification.id] = setTimeout(this.onClickClose, parseInt(String(notification.duration), 10));
    }
  }

  componentWillUnmount() {
    const { notification } = this.props;
    clearTimeout(closeTimers[notification.id]);
    delete closeTimers[notification.id];
  }

  onClickClose = () => {
    const { onClose, notification } = this.props;
    if (onClose instanceof Function) onClose(notification.id);
  };

  render() {
    const { notification } = this.props;
    return (
      <div
        className={`notification notification-type-${notification.messageType || 'info'}`}
        data-seleniumid="notification"
      >
        <button className="delete" onClick={this.onClickClose} type="button" />
        {typeof notification.content === 'string' ? (
          <div
            className="notification-message"
            dangerouslySetInnerHTML={{ __html: notification.content }}
            data-seleniumid="notification-message"
          />
        ) : (
          <div className="notification-message" data-seleniumid="notification-message">
            {notification.content}
          </div>
        )}
      </div>
    );
  }
}

export default NotificationPopup;
