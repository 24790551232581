import { get } from 'services/ajax';
import { fixWoaUser } from 'services/api/utils';

const baseUrl = `${process.env.API_URL}/resource/v1`;

export async function logout(): Promise<void> {
  await get(`${baseUrl}/login/logout`);
}

export async function getProfileInfo(): Promise<WoaLoggableUser> {
  const result = await get(`${baseUrl}/login/currentUser`);
  return result.woaloggableUser;
}

export async function getUserByParticipantId(participantId: string): Promise<WoaUser> {
  const result = await get(`${baseUrl}/user/${participantId}`);
  return fixWoaUser(result.woauser);
}

export async function getUsersAvailableForAssociationWithAccount(accountId): Promise<Array<WoaUserShort>> {
  const result = await get(`${baseUrl}/user/allknown?accountId=${accountId}`);
  return result.woausers || [];
}
