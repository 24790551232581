import React from 'react';
import AccountSummary from 'components/AccountSummary';
import InvoiceRequests from 'components/InvoiceRequests';
import OrdersFilter from 'components/OrdersFilter';
import PublicationStatuses from 'components/PublicationStatuses';
import RequestStatuses from 'components/RequestStatuses';
import withAuthRequired from 'with/withAuthRequired';
import { compose } from 'with/withContext';
import withMainLayout from 'with/withMainLayout';
import withSelectedAccountRequired from 'with/withSelectedAccountRequired';

const HomePage = () => (
  <div className="d-flex">
    <div className="mainColumn w_100p">
      <OrdersFilter />
    </div>
    <div className="widgetsColumn">
      <AccountSummary />
      <InvoiceRequests />
      <RequestStatuses />
      <PublicationStatuses />
    </div>
  </div>
);

export default compose(withAuthRequired, withMainLayout, withSelectedAccountRequired, HomePage);
