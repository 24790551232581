import React from 'react';
import LoaderLayout from 'components/Layouts/LoaderLayout';
import ConsortiaDatesEnum from 'const/ConsortiaDatesEnum';
import ConsortiaReportTypeEnum from 'const/ConsortiaReportTypeEnum';
import ConsortiaContext from 'storage/ConsortiaContext';
import { notify } from 'storage/NotificationsContext';

const GetReports: React.FC = () => {
  const { dates, isRequestingReport: isLoading, requestReport } = ConsortiaContext.useContext();

  async function requestHighLevelView() {
    await requestReport(ConsortiaReportTypeEnum.HIGH_LEVEL);
    notify.info('Report is being generated. It will be sent by email upon completion.');
  }

  async function requestDetailedView() {
    await requestReport(ConsortiaReportTypeEnum.DETAILED);
    notify.info('Report is being generated. It will be sent by email upon completion.');
  }

  const detailedViewExclusionList = [ConsortiaDatesEnum.ALL_HISTORY, ConsortiaDatesEnum.CUSTOM_RANGE];

  const highLevelViewButton = (
    <button
      className="btn btn-outline-primary"
      data-seleniumid="download-consortia-view"
      onClick={requestHighLevelView}
      type="button"
    >
      Request Consortia View
    </button>
  );
  const detailedViewButton = (
    <button
      className="btn btn-outline-primary ml_1em"
      data-seleniumid="request-detailed-view"
      disabled={detailedViewExclusionList.includes(dates.id)}
      onClick={requestDetailedView}
      type="button"
    >
      Request Detailed View
    </button>
  );

  return (
    <LoaderLayout isLoading={isLoading}>
      <section className="mt_1em mb_1em">
        {highLevelViewButton}
        {detailedViewButton}
      </section>
    </LoaderLayout>
  );
};

export default React.memo(GetReports);
