import get from 'lodash.get';
import React, { VFC } from 'react';
import { currencyFormat } from 'services/utils';
import ConsortiaContext from 'storage/ConsortiaContext';
import './Stats.scss';

const MoneyStats: VFC<{
  ConsortiaStats: ConsortiaStats;
  currency?: string;
  showPrice: boolean;
}> = ({ ConsortiaStats, currency = 'USD', showPrice }) => {
  const { totalAmountSpent, totalOpenAccessAmountSpent, totalOnlineOpenAmountSpent } = ConsortiaStats;

  return (
    showPrice && (
      <article className="stats">
        <header className="stats-header">
          <p className="stats-totalAmount" data-seleniumid="consortia-total-amount-spent">
            {currencyFormat(totalAmountSpent, currency)}
          </p>
          <h4 className="stats-heading">Total Amount Spent</h4>
        </header>

        <div className="stats-dataWrapper">
          <section className="stats-dataContainer">
            <h5 className="stats-dataHeading">Open Access</h5>
            <p className="stats-dataValue" data-seleniumid="consortia-total-oa-spent">
              {currencyFormat(totalOpenAccessAmountSpent, currency)}
            </p>
          </section>
          <section className="stats-dataContainer">
            <h5 className="stats-dataHeading">OnlineOpen</h5>
            <p className="stats-dataValue" data-seleniumid="consortia-total-oo-spent">
              {currencyFormat(totalOnlineOpenAmountSpent, currency)}
            </p>
          </section>
        </div>
      </article>
    )
  );
};

export default ConsortiaContext.withContext(state => ({
  showPrice: get(state, 'dealOOConfiguration.showPrice', false),
}))(React.memo(MoneyStats));
