import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import WileyLogo from 'assets/img/WileyLogo.png';
import WileyLogoWhite from 'assets/img/WileyLogoWhite.png';
import AccountSelectorModal from 'components/AccountSelectorModal';
import AlertsMenu from 'components/AlertsMenu';
import LoaderLayout from 'components/Layouts/LoaderLayout';
import PermissionEnum from 'model/PermissionEnum';
import { mainRoutes } from 'routes';
import AccountContext from 'storage/AccountContext';
import ProfileInfoContext from 'storage/ProfileInfoContext';
import { canUseAlerts, canUseConsortia } from 'utils/permissions';
import withConfigRequired from 'with/withConfigRequired';
import { compose } from 'with/withContext';
import './layout.scss';

const showCookiePreferences = () => {
  // eslint-disable-next-line no-undef
  if (Osano) {
    // eslint-disable-next-line no-undef
    Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
  }
};

class MainLayout extends PureComponent {
  static propTypes = {
    accountsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    children: PropTypes.node,
    config: PropTypes.shape({
      adminUrl: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
    profileInfo: PropTypes.shape({}),
    selectedAccount: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      permissions: PropTypes.arrayOf(PropTypes.string),
    }),
    setSelectedAccountById: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    location: PropTypes.shape({}).isRequired,
    login: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    isAuth: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    children: null,
    className: '',
    profileInfo: {},
    selectedAccount: null,
    isFetching: false,
  };

  state = {
    switchAccount: false,
  };

  getAccountRoleLabel() {
    const { selectedAccount } = this.props;

    if (!selectedAccount) {
      return '';
    }

    if (selectedAccount.permissions.includes(PermissionEnum.ADMIN)) {
      return 'ADMIN';
    }

    const permissions = [];
    if (selectedAccount.permissions.includes(PermissionEnum.CONSORTIA)) {
      permissions.push('Consortia');
    }
    if (selectedAccount.permissions.includes(PermissionEnum.OWNER)) {
      permissions.push('Account Owner');
    }

    if (permissions.length) {
      return permissions.join(', ');
    }
    return 'User';
  }

  onClickSwitchAccount = () => {
    const { accountsList, selectedAccount, setSelectedAccountById } = this.props;

    if (accountsList.length === 2) {
      setSelectedAccountById(accountsList.find(x => x.id !== selectedAccount.id).id);
    } else {
      this.setState({ switchAccount: true });
    }
  };

  onCloseAccountModal = () => this.setState({ switchAccount: false });

  onClickSignIn = () => this.props.login();

  onClickSignOut = () => this.props.logout();

  render() {
    const { accountsList, children, className, config, isAuth, isFetching, location, profileInfo, selectedAccount } =
      this.props;
    const { switchAccount } = this.state;
    const isAdmin = !!selectedAccount && selectedAccount.permissions.includes(PermissionEnum.ADMIN);
    const isReadOnlyAdmin = !!selectedAccount && selectedAccount.permissions.includes(PermissionEnum.READ_ONLY_ADMIN);

    return (
      <div className="accountLayout" id="mainLayout">
        <header className="mainHeader" data-seleniumid="mainHeader">
          <div className="m-mainMiddleBlock mainHeader_block">
            <Link to="/">
              <img alt="Wiley" className="mainHeader_logo" src={WileyLogo} />
            </Link>
            <div className="mainHeader_content">
              <div key="profile_menu" className="mainMenu_layer">
                {isAuth ? (
                  <>
                    <span className="mr_10">
                      {`Welcome, `}
                      <span className="bold">
                        {`${profileInfo.firstName} ${profileInfo.lastName}`}
                        {selectedAccount && ` (${this.getAccountRoleLabel()})`}
                      </span>
                    </span>
                    <button
                      className="btn btn-link p_0"
                      data-seleniumid="mainHeader-signOut"
                      onClick={this.onClickSignOut}
                      type="button"
                    >
                      Sign out
                    </button>
                  </>
                ) : (
                  <button
                    className="btn btn-link p_0"
                    data-seleniumid="mainHeader-signIn"
                    onClick={this.onClickSignIn}
                    type="button"
                  >
                    Sign in
                  </button>
                )}
              </div>

              {selectedAccount && (
                <>
                  <div className="mainMenu_layer">
                    {!mainRoutes.home.isHere(location) && (
                      <Link className="mainMenu_link" data-seleniumid="mainHeader-home" to={mainRoutes.home.getUrl()}>
                        Home
                      </Link>
                    )}
                    {canUseAlerts(selectedAccount) && <AlertsMenu />}
                    {!isReadOnlyAdmin && !mainRoutes.profile.isHere(location) && (
                      <Link
                        className="mainMenu_link"
                        data-seleniumid="mainHeader-accountInfo"
                        to={mainRoutes.profile.getUrl()}
                      >
                        Account Info
                      </Link>
                    )}
                    {selectedAccount.consortiumId && canUseConsortia(selectedAccount) && (
                      <Link
                        className="mainMenu_link"
                        data-seleniumid="mainHeader-consortiaView"
                        to={mainRoutes.consortiaAccounts.getUrl()}
                      >
                        Consortia View
                      </Link>
                    )}
                  </div>

                  <div className="mainMenu_layer">
                    <span className="bold" data-seleniumid="mainHeader-currentAccount">
                      {selectedAccount.name}
                    </span>
                    {accountsList.length > 1 && (
                      <button
                        className="btn btn-link p_0 ml_10"
                        data-seleniumid="mainHeader-switchAccount"
                        onClick={this.onClickSwitchAccount}
                        type="button"
                      >
                        Switch Account
                      </button>
                    )}

                    {isAdmin && (
                      <a
                        className="ml_10"
                        data-seleniumid="mainHeader-manageAllAccounts"
                        href={config.adminUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Manage All Accounts
                      </a>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </header>

        <LoaderLayout className={cn('m-mainMiddleBlock mainContent', className)} isLoading={isFetching}>
          {children}

          {switchAccount && <AccountSelectorModal onClose={this.onCloseAccountModal} selected={selectedAccount} />}
        </LoaderLayout>

        <footer className="mainFooter">
          <div className="m-mainMiddleBlock">
            <span className="footerLink">
              <Link to={mainRoutes.woaatnc.getUrl()}>Terms &amp; Conditions</Link>
            </span>
            <span className="footerLink">
              <a href="http://onlinelibrary.wiley.com/privacyPolicy" rel="noopener noreferrer" target="_blank">
                Privacy Policy
              </a>
            </span>
            <span className="footerLink">
              <Link to={mainRoutes.aboutcookies.getUrl()}>Cookies</Link>
            </span>
            <span className="footerLink">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <a className="FooterContainer-Link cookiePreferences" onClick={showCookiePreferences} target="_blank">
                Cookie Preferences
              </a>
            </span>
            <span className="footerLink">
              <Link to={mainRoutes.help.getUrl()}>Help/FAQs</Link>
            </span>
            <small>{`© Copyright Wiley ${new Date().getFullYear()}`}</small>
            <img alt="Wiley" className="mainFooter_logo" src={WileyLogoWhite} />
          </div>
        </footer>
      </div>
    );
  }
}

export default compose(
  withConfigRequired,
  ProfileInfoContext.withContext(['isAuth', 'login', 'logout', 'profileInfo']),
  AccountContext.withContext(['accountsList', 'isFetching', 'selectedAccount', 'setSelectedAccountById']),
  Component => props => {
    const location = useLocation();

    return <Component {...props} location={location} />;
  },
  MainLayout,
);
