import cn from 'classnames';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import React from 'react';
import { InlineHelp } from 'components/Elements';
import ModalConfirmation from 'components/Modal/ModalConfirmation';
import { form } from 'decorators';
import { trimStringProperties, validation as v } from 'services/utils';

@autobind
@form
class EditGroupEmailModal extends React.PureComponent {
  static propTypes = {
    buttonOkTitle: PropTypes.string,
    email: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    title: PropTypes.string,
  };

  static defaultProps = {
    buttonOkTitle: 'Edit',
    email: '',
    title: 'Edit Group Email Contact',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.prevEmail !== nextProps.email) {
      return {
        prevEmail: nextProps.email,
        fields: { email: nextProps.email },
      };
    }

    return null;
  }

  state = {
    fields: {
      email: '',
    },
    submitting: false,
    validation: {},
  };

  async onSubmit(e) {
    e.preventDefault();

    if (!this.validate()) {
      return;
    }

    const fields = trimStringProperties(this.state.fields);

    try {
      this.setState({ submitting: true });
      await this.props.onSave(fields.email);
    } finally {
      this.setState({ submitting: false });
    }
  }

  validate() {
    const fields = trimStringProperties(this.state.fields);

    const errors = {
      email: v.validate(fields.email, [
        [v.notEmpty, 'Must not be empty.'],
        [v.isEmailValid, 'Must be valid email.'],
      ]),
    };

    this.setState({ validation: errors });

    return Object.values(errors).every(x => !x);
  }

  render() {
    const { buttonOkTitle, onCancel, title } = this.props;
    const { fields, submitting, validation } = this.state;

    return (
      <ModalConfirmation
        buttonCloseClick={onCancel}
        buttonOkClassName={cn({ 'is-loading': submitting })}
        buttonOkClick={this.onSubmit}
        buttonOkDisabled={submitting}
        buttonOkTitle={buttonOkTitle}
        className="editGroupEmailModal"
        seleniumid="editGroupEmailModal"
        title={title}
      >
        <div>Group email contacts can be used for alerts.</div>

        <div style={{ display: 'flex', marginTop: '0.5em' }}>
          <div className="text-right" style={{ width: '20%', paddingTop: '4px' }}>
            Email:
          </div>
          <div style={{ width: '79%', paddingLeft: '0.5em' }}>
            <input
              className={cn('input w_100p', { 'is-error': validation.email })}
              data-seleniumid="editGroupEmailModal-email"
              name="email"
              onChange={this.onInputChange}
              onFocus={this.onInputFocus}
              placeholder="example@gmail.com"
              type="text"
              value={fields.email}
            />
            {validation.email && <InlineHelp className="d-block is-error">{validation.email}</InlineHelp>}
          </div>
        </div>
      </ModalConfirmation>
    );
  }
}

function withProperLabels(Component) {
  return function (props) {
    let title = '';
    let buttonOkTitle = '';

    if (props.email) {
      title = 'Edit Group Email Contact';
      buttonOkTitle = 'Edit';
    } else {
      title = 'Add Group Email Contact';
      buttonOkTitle = 'Add';
    }

    return <Component {...props} buttonOkTitle={buttonOkTitle} title={title} />;
  };
}

export { EditGroupEmailModal, withProperLabels };
export default withProperLabels(EditGroupEmailModal);
