class Status {
  constructor(code, text) {
    this.code = code;
    this.text = text;
  }

  is = val => this.code === val;

  toString = () => this.text;
}

export default Status;
