import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import LoaderLayout from 'components/Layouts/LoaderLayout';
import AccountContext, { AccountContextInterface } from 'storage/AccountContext';
import './accountSelector.scss';

function Select({ children, ...props }) {
  return (
    <select className="select w_100p" data-seleniumid="accountSelector-select" name="account" {...props}>
      <option disabled hidden value="-1">
        Select account
      </option>
      {children}
    </select>
  );
}

function renderOption({ id, name }) {
  return (
    <option key={id} value={id}>
      {name}
    </option>
  );
}

function AccountSelector<T extends AccountContextInterface['accountsList'][0]>({
  accountsList,
  isFetching,
  selected,
  onChange,
  onChangeAndConfirm,
}: {
  accountsList: T[];
  isFetching: boolean;
  selected: T;
  onChange: (account: T) => void;
  onChangeAndConfirm: (account: T) => void;
}) {
  const onChangeAccount = useCallback(
    event => {
      const accountId = parseInt(event.target.value, 10);
      const account = accountsList.find(el => el.id === accountId);
      onChange(account);
    },
    [accountsList, onChange],
  );

  const activeAccounts = useMemo(() => accountsList.filter(x => !x.notCurrent), [accountsList]);
  const inactiveAccounts = useMemo(() => accountsList.filter(x => x.notCurrent), [accountsList]);

  const hasActiveAccounts = activeAccounts.length > 0;
  const hasInactiveAccounts = inactiveAccounts.length > 0;

  if (accountsList.length === 0) {
    return <p className="mt_1em">There are no accounts associated with the current user.</p>;
  }

  if (activeAccounts.length <= 5) {
    return (
      <LoaderLayout className="accountSelector" isLoading={isFetching}>
        {hasActiveAccounts ? (
          <>
            <h4 className="mt_1em">Active Accounts</h4>

            <div className="accountSelector-list">
              {activeAccounts.map(x => (
                <button
                  key={x.id}
                  className={cn('btn btn-light btn-block accountSelector-listItem', {
                    active: x.id === selected?.id,
                  })}
                  data-seleniumid={`accountSelector-listItem--${x.id}`}
                  onClick={() => onChangeAndConfirm(x)}
                  type="button"
                >
                  {x.name}
                </button>
              ))}
            </div>
          </>
        ) : (
          <p className="mt_1em">There are no active accounts associated with the current user.</p>
        )}

        {hasInactiveAccounts && (
          <>
            <h4 className="mt_1em">Inactive Accounts</h4>
            <Select
              onChange={onChangeAccount}
              value={selected && !activeAccounts.some(x => x.id === selected.id) ? selected.id : '-1'}
            >
              {inactiveAccounts.map(renderOption)}
            </Select>
          </>
        )}
      </LoaderLayout>
    );
  }

  return (
    <LoaderLayout className="accountSelector" isLoading={isFetching}>
      <h4 className="mt_1em">All Accounts</h4>
      <Select onChange={onChangeAccount} value={selected ? selected.id : '-1'}>
        {hasActiveAccounts && hasInactiveAccounts ? (
          <>
            <optgroup label="Active Accounts">{activeAccounts.map(renderOption)}</optgroup>
            <optgroup label="Inactive Accounts">{inactiveAccounts.map(renderOption)}</optgroup>
          </>
        ) : (
          <>
            {activeAccounts.map(renderOption)}
            {inactiveAccounts.map(renderOption)}
          </>
        )}
      </Select>
    </LoaderLayout>
  );
}

export default AccountContext.withContext(['accountsList', 'isFetching'])(AccountSelector);
