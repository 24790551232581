enum ConsortiaDatesEnum {
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_14_DAYS = 'LAST_14_DAYS',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  YTD = 'YTD',
  ALL_HISTORY = 'ALL_HISTORY',
  CUSTOM_RANGE = 'CUSTOM_RANGE',
}

export default ConsortiaDatesEnum;
