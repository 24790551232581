import QS from 'query-string';
import L from 'services/console';

export const ModalsWindows = [];

function getModalsHash() {
  const hash = QS.parse(window.location.hash || '');
  return (hash._xm || '').split('.');
}

function getCleanModalsHash() {
  return getModalsHash().filter(hash => ModalsWindows.find(mw => mw.hashKey === hash));
}

function toggleScrollBars(isShow) {
  const html = document.getElementsByTagName('html')[0];
  if (isShow) {
    if (!html.hasAttribute('data-modal-overflow')) {
      return;
    }
    html.style.overflow = html.getAttribute('data-modal-overflow') || '';
    html.removeAttribute('data-modal-overflow');
    html.style['padding-right'] = '';
  } else {
    if (html.hasAttribute('data-modal-overflow')) {
      return;
    }
    html.setAttribute('data-modal-overflow', html.style.overflow);
    const paddingRight = window.innerWidth - html.offsetWidth;
    html.style.overflow = 'hidden';
    html.style['padding-right'] = `${paddingRight}px`;
  }
}

const popStateListener = event => {
  if (ModalsWindows.length > 0) {
    event.preventDefault();
    const modalsHash = getCleanModalsHash();
    const closedModals = ModalsWindows.filter(m => m.hashKey && !modalsHash.includes(m.hashKey));
    toggleScrollBars(closedModals.length >= ModalsWindows.length);
    closedModals.forEach(m => m.publicClose());
  }
  return event;
};

const keyDownListener = event => {
  if (event.keyCode === 27 && ModalsWindows.length > 0) {
    event.preventDefault();
    ModalsWindows[ModalsWindows.length - 1].publicCloseEsc();
  }
  return event;
};

function setModalsHash(modalsList, isReplace = false) {
  const hash = QS.parse(window.location.hash || '');
  const currentXM = hash._xm || '';
  const newXM = modalsList.filter(el => (el || '').trim()).join('.');
  hash._xm = newXM;
  L.debug('setModalsHash', newXM);
  if (newXM !== currentXM) {
    const newHash = QS.stringify(hash);
    const newUrl = window.location.href.split('#');
    newUrl[1] = newHash || '_xm=';

    if (isReplace) window.location.replace(newUrl.join('#'));
    else window.location.assign(newUrl.join('#'));
  }

  toggleScrollBars(!newXM);
}

function removeFromHash(key) {
  const modalsHash = getModalsHash();
  const n = modalsHash.findIndex(el => el === key);
  if (n >= 0) {
    modalsHash.splice(n, 1);
    setModalsHash(modalsHash, true);
  }
}

function addToHash(key) {
  const modalsHash = getCleanModalsHash();
  if (key && !modalsHash.includes(key)) {
    modalsHash.push(key);
    setModalsHash(modalsHash);
  }
}

function cleanHash() {
  const modalsHash = getModalsHash();
  const cleanModalsHash = getCleanModalsHash();
  if (modalsHash.length !== cleanModalsHash.length) {
    setModalsHash(cleanModalsHash, true);
  }
}

window.addEventListener('popstate', popStateListener);
// window.addEventListener('hashchange', popStateListener);
document.addEventListener('keydown', keyDownListener);

cleanHash();

export { addToHash, cleanHash, removeFromHash };
