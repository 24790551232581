import PropTypes from 'prop-types';
import React from 'react';
import ReactPaginate from 'react-paginate';
import './Pager.scss';

function PageSizeSelector({ onPageSizeChange, pageSize, pageSizeLabel, pageSizeOptions }) {
  const [prefix, postfix] = (pageSizeLabel || '').split('${selector}'); // eslint-disable-line no-template-curly-in-string

  return (
    <div className="pager-sizeSelector">
      {prefix}
      <select
        className="select"
        data-seleniumid="page-size-selector"
        onChange={event => onPageSizeChange(event.target.value)}
        value={pageSize}
      >
        {pageSizeOptions.map(x => (
          <option key={x} value={x}>
            {x}
          </option>
        ))}
      </select>
      {postfix}
    </div>
  );
}

function Pager({ onPageChange, onPageSizeChange, page, pages, pageSize, pageSizeLabel, pageSizeOptions }) {
  return (
    <div className="pager">
      <PageSizeSelector
        onPageSizeChange={onPageSizeChange}
        pageSize={pageSize}
        pageSizeLabel={pageSizeLabel}
        pageSizeOptions={pageSizeOptions}
      />

      <ReactPaginate
        activeClassName="active"
        breakClassName="page-item"
        breakLabel="..."
        breakLinkClassName="page-link"
        containerClassName="pagination pager-pageSelector"
        forcePage={page}
        marginPagesDisplayed={2}
        nextClassName="page-item"
        nextLabel="&raquo;"
        nextLinkClassName="page-link"
        onPageChange={({ selected }) => onPageChange(selected)}
        pageClassName="page-item"
        pageCount={page + 1 > pages ? page + 1 : pages}
        pageLinkClassName="page-link"
        pageRangeDisplayed={2}
        previousClassName="page-item"
        previousLabel="&laquo;"
        previousLinkClassName="page-link"
      />
    </div>
  );
}

Pager.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  pageSizeLabel: PropTypes.string,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
};

Pager.defaultProps = {
  pageSizeLabel: undefined,
};

export default Pager;
