import React from 'react';

type Props = {
  children?: React.ReactNode;
  error?: { message: string };
};

function ErrorBaner({ children, error }: Props) {
  if (!error) {
    return null;
  }

  return (
    <div className="errorBaner">
      <div className="errorBaner_message">{error.message}</div>
      {children}
    </div>
  );
}

ErrorBaner.defaultProps = {
  error: undefined,
  children: undefined,
};

export default ErrorBaner;
