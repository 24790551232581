/* eslint no-console: 0 */

import { guidCustom } from './utils';

const CONSOLE = console;

function itError(...args) {
  const errors = args.filter(el => el instanceof Error);
  const unresolvedErrors = errors.find(el => !el.errId);
  const breakAction = errors.length > 0 && !unresolvedErrors;

  for (let i; i < errors.length; i += 1) {
    if (!errors[i].errId) {
      errors[i].errId = guidCustom();
    }
  }
  if (breakAction) {
    return false;
  }
  // TODO: sentry send report
  console.log('TODO: sentry send report', unresolvedErrors ? unresolvedErrors.stack : '');
  return true;
}

function sendReport() {
  // TODO: report sending
}

const mixedConsole = { ...CONSOLE };
const mixins = {
  // [run default console func, function]
  error: [true, itError],
  warn: [process.env.IS_DEV, sendReport],
  debug: [process.env.IS_DEV, null],
};

function runAction(action, mixin, ...args) {
  if (!mixin) {
    return true;
  }
  const runDefault = mixin[0] || false;
  const func = mixin[1] || null;

  if (func instanceof Function) {
    // TODO: report actions
    func(...args);
  }

  if (runDefault) {
    return CONSOLE[action] ? CONSOLE[action](...args) : CONSOLE.error('Undefined console method', action, ...args);
  }
  return true;
}

Object.keys(mixins).forEach(k => {
  if (!mixins[k]) {
    mixedConsole[k] = () => true;
  } else {
    mixedConsole[k] = (...args) => runAction(k, mixins[k], ...args);
  }
});

export default mixedConsole;
