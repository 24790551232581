import PermissionEnum from 'model/PermissionEnum';
import { get, post, put, del } from 'services/ajax';
import { fixWoaAccount, fixWoaUser } from 'services/api/utils';

const baseUrl = `${process.env.API_URL}/resource/v1`;

export async function getAccounts(): Promise<Array<{ id: number; code: string; name: string; notCurrent: boolean }>> {
  const result = await get(`${baseUrl}/account/all`);
  return result.woaaccounts || [];
}

export async function getAccountById(accountId: number): Promise<WoaAccount> {
  const result = await get(`${baseUrl}/account/${accountId}`, {
    resultData:
      'WOA_ACCOUNT,WOA_ACCOUNT_CONFIGURATION,WOA_USER_ROLE,WOA_ACCOUNT_AUXBALANCES,WOA_INVOICE,WOA_UI_DATA,WOA_NOTE',
  });

  return fixWoaAccount(result.woaaccount);
}

export async function getAccountByCode(code: string): Promise<WoaAccount> {
  const result = await get(`${baseUrl}/account/code/${code}`, {
    resultData: 'WOA_ACCOUNT,WOA_ACCOUNT_CONFIGURATION,WOA_USER_ROLE,WOA_ACCOUNT_AUXBALANCES,WOA_INVOICE,WOA_UI_DATA',
  });

  return fixWoaAccount(result.woaaccount);
}

export async function addUser(accountId: number, email: string): Promise<WoaUser> {
  const result = await post(`${baseUrl}/user/${email}/permissions?accountId=${accountId}`);

  return fixWoaUser(result.woauser);
}

export function removeUser(accountId: number, participantId: string): Promise<void> {
  return del(`${baseUrl}/user/${participantId}/permissions?accountId=${accountId}`);
}

export function addPermission(accountId: number, participantId: string, permission: PermissionEnum): Promise<void> {
  return post(`${baseUrl}/user/${participantId}/permissions/${permission}?accountId=${accountId}`);
}

export function removePermission(accountId, participantId, permission: PermissionEnum): Promise<void> {
  return del(`${baseUrl}/user/${participantId}/permissions/${permission}?accountId=${accountId}`);
}

export function saveGroupEmail(accountId: number, email: string): Promise<void> {
  return put(`${baseUrl}/account/${accountId}`, {
    woaaccount: { groupContactEmail: email },
  });
}

export function removeGroupEmail(accountId: number): Promise<void> {
  return put(`${baseUrl}/account/${accountId}`, {
    woaaccount: { groupContactEmail: '' },
  });
}

export function addFunds(accountId: number, amount: number, purchaseOrderNum: number): Promise<void> {
  return put(`${baseUrl}/account/${accountId}`, {
    woaaccount: { addFunds: { amount, purchaseOrderNum } },
  });
}
