import React from 'react';
import ConsortiaLayout from 'components/Layouts/ConsortiaLayout';

export default function withConsortiaLayout(Component) {
  return function WithConsortiaLayout(props) {
    return (
      <ConsortiaLayout>
        <Component {...props} />
      </ConsortiaLayout>
    );
  };
}
