import React, { useMemo, useState } from 'react';
import DenyOrderModal from 'components/Modal/DenyOrderModal';
import OrderDetailsModal from 'components/OrderDetailsModal';
import DealEnum from 'model/DealEnum';
import { currencyFormat } from 'services/utils';
import AccountContext from 'storage/AccountContext';
import OrderActionsContext from 'storage/OrderActionsContext';
import { canBeAutoApproved } from 'utils/orders';
import PendingOrdersViewRow from './PendingOrdersViewRow';

function PendingOrdersList({ orders }: { orders: WoaRequest[] }) {
  const { selectedAccount } = AccountContext.useContext();
  const { denyOrder } = OrderActionsContext.useContext();

  const [viewDetailsOrder, setViewDetailsOrder] = useState<number>();
  const [denyOrderId, setDenyOrderId] = useState<number>();

  const onView = order => setViewDetailsOrder(order.id);
  const onClickDeny = order => setDenyOrderId(order.id);
  const closeDetails = () => setViewDetailsOrder(null);
  const closeDenyOrderModal = () => setDenyOrderId(null);

  const onSubmitDeny = reason => {
    denyOrder(denyOrderId, { reason });
    closeDenyOrderModal();
  };

  const summary = useMemo<number>(
    () => orders.reduce((accumulator, x) => accumulator + parseFloat(String(x.allocatedAmount)), 0),
    [orders],
  );

  return (
    <>
      {!!viewDetailsOrder && (
        <OrderDetailsModal close={closeDetails} orderId={viewDetailsOrder} showPrice={selectedAccount.showPrice} />
      )}
      {!!denyOrderId && <DenyOrderModal onCancel={closeDenyOrderModal} onDeny={onSubmitDeny} />}
      <table className="table pendingOrdersView">
        <thead>
          <tr className="pendingOrdersView-headRow" style={{ textAlign: 'center' }}>
            <th />
            <th>Request Date</th>
            <th>RCA Name</th>
            <th>Journal</th>
            <th>Amount</th>
            {selectedAccount.dealType === DealEnum.BOTH && <th>Journal Revenue Model</th>}
            <th style={{ width: '155px' }} />
          </tr>
        </thead>
        {orders.length > 0 && (
          <tbody>
            {orders.map(order => (
              <PendingOrdersViewRow
                key={order.id}
                autoApproval={canBeAutoApproved(order, selectedAccount)}
                currency={selectedAccount.currency}
                dealType={selectedAccount.dealType}
                onDeny={onClickDeny}
                onView={onView}
                order={order}
                permissions={selectedAccount.permissions}
                showPrice={selectedAccount.showPrice}
              />
            ))}
          </tbody>
        )}

        {orders.length > 0 && summary > 0 && (
          <tbody>
            <tr className="pendingOrdersView-dataRow">
              <td className="ta_r bold pr_5 pendingOrdersView-dataCell" colSpan={4}>
                Total:
              </td>
              <td
                className="bold pendingOrdersView-dataCell nobr"
                colSpan={3}
                data-seleniumid="pendingOrdersView-totalAmount"
              >
                {selectedAccount.showPrice ? currencyFormat(summary, selectedAccount.currency) : 'N/A'}
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </>
  );
}

export default PendingOrdersList;
