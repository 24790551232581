import React, { PureComponent } from 'react';
import DealEnum from 'model/DealEnum';
import { isArticleAndAccountNotesEqual } from 'services/api/utils';
import { dateFormat, currencyFormat } from 'services/utils';

type Props = {
  currency: string;
  dealType: DealEnum;
  onView: (order: WoaRequest) => void;
  order: WoaRequest & { status: string; woaaccount: WoaAccount };
  showPrice: boolean;
};

class OrdersViewRow extends PureComponent<Props> {
  onClickView = () => {
    const { onView, order } = this.props;
    onView(order);
  };

  render() {
    const { currency, dealType, order, showPrice } = this.props;
    const { woaarticle: { woanotes = [] } = {} } = order;
    const isNoteIconVisible =
      woanotes.some(el => el.isPublic && !el.isInternal) || isArticleAndAccountNotesEqual(order.woaaccount, woanotes);

    return (
      <tr key={order.id} className="ordersView-dataRow">
        <td className="ordersView-dataCell">
          <div className="flex_v_center">
            <button
              className="btn btn-light btn-sm"
              data-seleniumid={`ordersView-viewOrder--${order.id}`}
              onClick={this.onClickView}
              type="button"
            >
              View
            </button>
            {isNoteIconVisible && <span className="orderHasNoteIcon ml_5" title="There are notes" />}
          </div>
        </td>
        <td className="ordersView-dataCell nobr ta_c" data-seleniumid={`ordersView-createdTs--${order.id}`}>
          {dateFormat(order.createdTs)}
        </td>
        <td className="ordersView-dataCell ta_c" data-seleniumid={`ordersView-status--${order.id}`}>
          {order.status}
        </td>
        <td className="ordersView-dataCell" data-seleniumid={`ordersView-author--${order.id}`}>
          {order.author}
        </td>
        <td className="ordersView-dataCell" data-seleniumid={`ordersView-journalName--${order.id}`}>
          {order.journalName}
        </td>
        <td className="ordersView-dataCell nobr" data-seleniumid={`ordersView-allocatedAmount--${order.id}`}>
          {showPrice ? currencyFormat(order.allocatedAmount, currency) : 'N/A'}
        </td>
        {dealType === DealEnum.BOTH && (
          <td className="ordersView-dataCell ta_c" data-seleniumid={`ordersView-journalRevenueModel--${order.id}`}>
            {order.journalRevenueModel}
          </td>
        )}
        <td className="ordersView-dataCell ta_c" data-seleniumid={`ordersView-articlePublicationStatus--${order.id}`}>
          {order.articlePublicationStatus}
        </td>
      </tr>
    );
  }
}

export default OrdersViewRow;
