// eslint-disable-next-line max-classes-per-file
class EventEmitter {
  constructor() {
    this.storage = {};
  }

  emit(event, ...args) {
    if (this.storage[event]) {
      this.storage[event].forEach(fn2 => fn2(...args));
    }
  }

  subscribe(event, fn) {
    if (!this.storage[event]) {
      this.storage[event] = [];
    }

    const fn2 = (...args) => fn(...args);

    this.storage[event].push(fn2);

    const unsubscribe = () => {
      const index = this.storage[event].indexOf(fn2);

      if (index !== -1) {
        this.storage[event].splice(index, 1);
      }
    };

    return unsubscribe;
  }
}

const defaultEmitter = new EventEmitter();

class EventEmit {
  constructor(type, emitter = defaultEmitter) {
    this.type = type;
    this.emitter = emitter;
  }

  emit(params = [], forEmitter = undefined) {
    const emitter = forEmitter || this.emitter;
    if (!emitter) throw new Error('Emitter is undefined');

    emitter.emit(this.type, ...params);
  }

  subscribe(func, forEmitter = undefined) {
    const emitter = forEmitter || this.emitter;
    if (!emitter) throw new Error('Emitter is undefined');

    return emitter.subscribe(this.type, func);
  }
}

function EventEmitterDecorator(obj) {
  const o = obj.prototype || obj;

  o.createEmitterIfNeeded = function () {
    if (!this.emitter) {
      this.emitter = new EventEmitter();
    }
  };
  o.emit = function (...args) {
    this.createEmitterIfNeeded();
    this.emitter.emit(...args);
  };
  o.subscribe = function (...args) {
    this.createEmitterIfNeeded();
    return this.emitter.subscribe(...args);
  };
}

export { EventEmitter, EventEmitterDecorator, EventEmit };
export default defaultEmitter;
