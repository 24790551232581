import AccountContext from './AccountContext';
import ArticleNoteContext from './ArticleNoteContext';
import ConfigContext from './ConfigContext';
import ConsortiaContext from './ConsortiaContext';
import EventsContext from './EventsContext';
import NotificationConfigContext from './NotificationConfigContext';
import OrderActionsContext from './OrderActionsContext';
import OrderDetailsContext from './OrderDetailsContext';
import OrdersContext from './OrdersContext';
import PendingOrdersContext from './PendingOrdersContext';
import ProfileInfoContext from './ProfileInfoContext';

const Providers = [
  NotificationConfigContext.withProvider,
  ArticleNoteContext.withProvider,
  ConsortiaContext.withProvider,
  OrderActionsContext.withProvider,
  OrdersContext.withProvider,
  PendingOrdersContext.withProvider,
  OrderDetailsContext.withProvider,
  EventsContext.withProvider,
  AccountContext.withProvider,
  ProfileInfoContext.withProvider,
  ConfigContext.withProvider,
];

export default function withDataProviders(WrappedComponent) {
  return Providers.reduce((result, provider) => provider(result), WrappedComponent);
}
