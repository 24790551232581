/* eslint no-console: 0 */

function tryCatchLog(...args) {
  function wrapper(fn) {
    return async function (...args2) {
      try {
        await fn.call(this, ...args2);
      } catch (error) {
        console.error(error);
      }
    };
  }

  if (args.length === 1) {
    const fn = args[0];

    return wrapper(fn);
  }

  const target = args[0];
  const key = args[1];
  const descriptor = args[2];

  if (!descriptor) {
    // descriptor is not passed in ts code
    const fn = target[key];

    Object.defineProperty(target, key, { value: wrapper(fn) });

    return undefined;
  }

  const fn = descriptor.value;

  return {
    ...descriptor,
    value: wrapper(fn),
  };
}

export default tryCatchLog;
