import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { InlineHelp } from 'components/Elements';
import ModalConfirmation from 'components/Modal/ModalConfirmation';
import ArticleNoteContext from 'storage/ArticleNoteContext';

const MAX_LENGTH = 1000;

class NoteEditorModal extends PureComponent {
  static propTypes = {
    isAdding: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    addArticleNote: PropTypes.func.isRequired,
    editArticleNote: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    value: PropTypes.string,
    noteId: PropTypes.number,
    articleId: PropTypes.number,
    accountId: PropTypes.number,
    isInternal: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    noteId: null,
    articleId: null,
    accountId: null,
    isInternal: false,
  };

  DOM = {
    form: React.createRef(),
  };

  state = {
    noteText: this.props.value || '',
    emptyError: false,
  };

  componentDidMount() {
    this.DOM.form.current.note.focus();
  }

  onKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  onSubmitNote = async event => {
    event.preventDefault();
    const { addArticleNote, editArticleNote, noteId, articleId, accountId, isInternal, close } = this.props;
    const noteText = this.state.noteText.trim();
    if (!noteText) {
      return this.setState({ emptyError: true });
    }

    await (noteId ? editArticleNote(noteId, noteText) : addArticleNote(accountId, articleId, isInternal, noteText));

    close();
    return true;
  };

  onChangeNote = ({ target }) =>
    this.setState({
      emptyError: false,
      noteText: target.value.slice(0, MAX_LENGTH),
    });

  render() {
    const { noteId, close, isAdding, isSaving } = this.props;
    const { noteText, emptyError } = this.state;

    return (
      <ModalConfirmation
        buttonCloseClick={close}
        buttonOkClassName={cn({
          'is-loading': isAdding || isSaving,
        })}
        buttonOkClick={this.onSubmitNote}
        buttonOkDisabled={noteText.length === 0}
        buttonOkTitle={noteId ? 'Save' : 'Add'}
        seleniumid="noteEditorModal"
        title={noteId ? 'Edit Note' : 'Add Note'}
      >
        <form ref={this.DOM.form} onSubmit={this.onSubmitNote}>
          <InlineHelp className="d_b ta_r">{`You have ${MAX_LENGTH - noteText.length} chars left`}</InlineHelp>
          <textarea
            className={cn('input w_100p', { 'is-error': emptyError })}
            data-seleniumid="noteEditorModal-note"
            maxLength={MAX_LENGTH}
            name="note"
            onChange={this.onChangeNote}
            onKeyDown={this.onKeyDown}
            placeholder="Enter note for this article"
            rows="10"
            value={noteText}
          />
          {emptyError && <InlineHelp className="d_b is-error">Please, enter some text in the text field.</InlineHelp>}
        </form>
      </ModalConfirmation>
    );
  }
}

export default ArticleNoteContext.withContext(['isAdding', 'isSaving', 'addArticleNote', 'editArticleNote'])(
  NoteEditorModal,
);
