import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import React from 'react';
import ModalConfirmation from 'components/Modal/ModalConfirmation';
import Widget from 'components/Widget';
import ConfigContext from 'storage/ConfigContext';
import ProfileInfoContext from 'storage/ProfileInfoContext';
import { compose } from 'with/withContext';

class ProfileInfo extends React.PureComponent {
  static propTypes = {
    config: PropTypes.shape({
      profileUrl: PropTypes.string,
    }).isRequired,
    profileInfo: PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }).isRequired,
  };

  state = {
    editProfileModal: {
      isVisible: false,
      open: () => {
        this.setState(state => ({
          editProfileModal: {
            ...state.editProfileModal,
            isVisible: true,
          },
        }));
      },
      close: () => {
        this.setState(state => ({
          editProfileModal: {
            ...state.editProfileModal,
            isVisible: false,
          },
        }));
      },
      onCancel: () => {
        this.state.editProfileModal.close();
      },
      onConfirm: () => {
        window.open(this.props.config.profileUrl, '_blank');
        this.state.editProfileModal.close();
      },
    },
  };

  @autobind
  onEditProfile() {
    this.state.editProfileModal.open();
  }

  render() {
    const { profileInfo } = this.props;
    const { editProfileModal } = this.state;

    return (
      <Widget seleniumid="profileInfo" title="Your Profile">
        <div className="font-weight-bold">Name:</div>
        <div data-seleniumid="profileInfo-fullName">{`${profileInfo.firstName} ${profileInfo.lastName}`}</div>
        <div className="font-weight-bold" style={{ marginTop: '0.5em' }}>
          Email:
        </div>
        <div data-seleniumid="profileInfo-email">{profileInfo.email}</div>

        <div className="text-center" style={{ marginTop: '0.5em' }}>
          <button
            className="btn btn-secondary"
            data-seleniumid="profileInfo-edit"
            onClick={this.onEditProfile}
            type="button"
          >
            Edit Profile
          </button>
        </div>

        {editProfileModal.isVisible && (
          <ModalConfirmation
            buttonCloseClick={editProfileModal.onCancel}
            buttonOkClick={editProfileModal.onConfirm}
            buttonOkTitle="Redirect"
            className="editProfileModal"
            seleniumid="editProfileModal"
            title="Edit Profile"
          >
            {`Dear ${profileInfo.firstName} ${profileInfo.lastName},`}
            <br />
            to edit your profile you will be redirected to AS2 Profile management page. Once you finish editing, please
            come back to WOA Account Dashboard. Please click the &quot;Redirect&quot; button to redirect.
          </ModalConfirmation>
        )}
      </Widget>
    );
  }
}

export default compose(
  ProfileInfoContext.withContext(['profileInfo']),
  ConfigContext.withContext(['config']),
  ProfileInfo,
);
