import React from 'react';
import { Navigate } from 'react-router-dom';
import { mainRoutes } from 'routes';
import withSelectedAccountRequired from 'with/withSelectedAccountRequired';

export default function withConsortiumRequired(Component) {
  return withSelectedAccountRequired(props => {
    if (!props.selectedAccount.consortiumId) {
      return <Navigate to={mainRoutes.home.getUrl()} />;
    }

    return <Component {...props} />;
  });
}
