import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import React from 'react';
import LoaderLayout from 'components/Layouts/LoaderLayout';
import { form, processing } from 'decorators';
import { getUsersAvailableForAssociationWithAccount } from 'services/api/user';

@autobind
@form
class UserSelect extends React.PureComponent {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    onFocus: () => {},
  };

  state = {
    isFetching: true,
    fields: {
      user: '-1',
    },
    users: [],
  };

  @processing('isFetching')
  async componentDidMount() {
    const users = await getUsersAvailableForAssociationWithAccount(this.props.accountId);

    this.setState({ users });
  }

  onUserChange(e) {
    const { onChange } = this.props;
    const { users } = this.state;
    const { value } = e.target;

    this.setFieldValue(e.target.name, value);

    if (value === '-1') {
      onChange('');
    } else {
      onChange(users.find(x => x.email === value));
    }
  }

  render() {
    const { disabled, onFocus } = this.props;
    const { isFetching, fields, users } = this.state;

    return (
      <LoaderLayout isLoading={isFetching}>
        <select
          className="select w_100p"
          data-seleniumid="userSelect-select"
          disabled={disabled}
          name="user"
          onChange={this.onUserChange}
          onFocus={onFocus}
          value={fields.user}
        >
          <option key="-1" value="-1">
            Choose User
          </option>
          {users.map(user => (
            <option key={user.email} value={user.email}>
              {[user.firstName, user.lastName, `(${user.email})`].filter(x => !!x).join(' ')}
            </option>
          ))}
        </select>
      </LoaderLayout>
    );
  }
}

export default UserSelect;
