import { autobind } from 'core-decorators';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import ColumnFilter from 'components/Consortia/ColumnFilter/ColumnFilter';
import JournalRevenueModelFilter from 'components/Consortia/JournalRevenueModelFilter';
import RequestsList from 'components/Consortia/RequestsList/RequestsList';
import withRequests from 'components/Consortia/RequestsList/withRequests';
import Search from 'components/Search/Search';
import SearchBanner from 'components/SearchBanner/SearchBanner';
import RequestStatusEnum from 'model/consortia/RequestStatusEnum';
import { currencyFormat } from 'services/utils';
import ConsortiaContext from 'storage/ConsortiaContext';
import withAuthRequired from 'with/withAuthRequired';
import withConsortiaLayout from 'with/withConsortiaLayout';
import withConsortiumRequired from 'with/withConsortiumRequired';
import { compose } from 'with/withContext';
import withSelectedAccountRequired from 'with/withSelectedAccountRequired';
import './ConsortiaRequestsPage.scss';

class ConsortiaApprovedRequestsPage extends React.PureComponent {
  static propTypes = {
    canClearFilters: PropTypes.bool.isRequired,
    columnFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
    columnFilterOptions: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }))
      .isRequired,
    dealShowPrice: PropTypes.bool,
    isFetching: PropTypes.bool.isRequired,
    journalRevenueModel: PropTypes.string.isRequired,
    onColumnFilterChange: PropTypes.func.isRequired,
    onFiltersClear: PropTypes.func.isRequired,
    onJournalRevenueModelChange: PropTypes.func.isRequired,
    onListChange: PropTypes.func.isRequired,
    onSearchStringChange: PropTypes.func.isRequired,
    onSearchStringClear: PropTypes.func.isRequired,
    pagination: PropTypes.shape({ page: PropTypes.number, size: PropTypes.number }),
    requests: PropTypes.arrayOf(PropTypes.shape({})),
    selectedAccount: PropTypes.shape({ currency: PropTypes.string }).isRequired,
    searchString: PropTypes.string,
    sorted: PropTypes.string,
    stats: PropTypes.shape({ approvedRequests: PropTypes.number, totalAmountSpent: PropTypes.number }),
    visibleColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  static defaultProps = {
    dealShowPrice: undefined,
    pagination: undefined,
    requests: undefined,
    searchString: undefined,
    sorted: undefined,
    stats: undefined,
  };

  @autobind
  clearSearch() {
    this.props.onSearchStringClear();
  }

  render() {
    const {
      canClearFilters,
      columnFilter,
      columnFilterOptions,
      dealShowPrice,
      isFetching,
      journalRevenueModel,
      onColumnFilterChange,
      onFiltersClear,
      onJournalRevenueModelChange,
      onListChange,
      onSearchStringChange,
      pagination,
      requests,
      searchString,
      selectedAccount,
      sorted,
      stats,
      visibleColumns,
    } = this.props;

    return (
      requests && (
        <>
          <h2 className="consortiaPage-totalHeading">
            <div className="d-inline-block mr_1em">
              Approved Requests&nbsp;
              <span className="consortiaPage-totalAmount" data-seleniumid="consortia-total-approvedRequests">
                {stats.approvedRequests}
              </span>
            </div>

            {dealShowPrice && (
              <div className="d-inline-block">
                Total Amount&nbsp;
                <span className="consortiaPage-totalAmount" data-seleniumid="consortia-total-approvedAmount">
                  {currencyFormat(stats.totalAmountSpent, selectedAccount.currency)}
                </span>
              </div>
            )}
          </h2>

          <div className="ConsortiaRequestsPage-Filters">
            <Search
              buttonClassName="ConsortiaRequestsPage-SearchButton"
              buttonSeleniumId="consortiaApprovedRequestsPage-search"
              className="ConsortiaRequestsPage-Search"
              inputClassName="ConsortiaRequestsPage-SearchInput"
              inputSeleniumId="consortiaApprovedRequestsPage-searchInput"
              isLoading={isFetching}
              keyword={searchString}
              onSubmit={onSearchStringChange}
              placeholder="Journal name, article name, article DOI, author, account name"
            />
            <ColumnFilter
              className="ConsortiaRequestsPage-ColumnFilter"
              onChange={onColumnFilterChange}
              options={columnFilterOptions}
              value={columnFilter}
            />
            <JournalRevenueModelFilter
              className="ConsortiaRequestsPage-RevenueModelFilter"
              onChange={onJournalRevenueModelChange}
              value={journalRevenueModel}
            />
            <button
              className="btn btn-light ml_1em ConsortiaRequestsPage-ClearFiltersButton"
              data-seleniumid="clearFilters"
              disabled={!canClearFilters}
              onClick={onFiltersClear}
              type="button"
            >
              Clear Filters
            </button>
          </div>

          <SearchBanner
            buttonSeleniumId="consortiaApprovedRequestsPage-clearSearchResult"
            isVisible={!!searchString}
            labelSeleniumId="consortiaApprovedRequestsPage-searchResult"
            onClear={this.clearSearch}
            text={`${isFetching ? '?' : pagination.items} Results for your search for "${searchString}"`}
          />

          <RequestsList
            dealShowPrice={dealShowPrice}
            isFetching={isFetching}
            onChange={onListChange}
            pagination={pagination}
            requests={requests}
            requestsType={RequestStatusEnum.APPROVED}
            sorted={sorted}
            visibleColumns={visibleColumns}
          />
        </>
      )
    );
  }
}

export default compose(
  withAuthRequired,
  withConsortiaLayout,
  withSelectedAccountRequired,
  withConsortiumRequired,
  ConsortiaContext.withContext(state => ({ dealShowPrice: get(state, 'dealOOConfiguration.showPrice') })),
  withRequests({ status: RequestStatusEnum.APPROVED }),
  React.memo,
  ConsortiaApprovedRequestsPage,
);
