import cn from 'classnames';
import React from 'react';
import ModalConfirmation from 'components/Modal/ModalConfirmation';
import NoteEditorModal from 'components/NoteEditorModal';
import { dateFormat } from 'services/utils';
import ArticleNoteContext from 'storage/ArticleNoteContext';

const NoteControls = ({ disabled, isRemoving, note, onClickEdit, onClickRemove }) => (
  <div className="orderDetailsNotes_itemControls">
    <button
      className="btn btn-link p_0 mr_10 orderDetailsNotes_editNote"
      data-seleniumid={`userNoteList-editNote-${note.id}`} // admin notes is not editable
      disabled={disabled}
      onClick={() => onClickEdit(note)}
      type="button"
    >
      edit note
    </button>
    <button
      className={cn('btn btn-link p_0 mr_10 orderDetailsNotes_removeNote', { 'is-loading': isRemoving })}
      data-seleniumid={`userNoteList-removeNote-${note.id}`}
      disabled={!isRemoving && disabled}
      onClick={() => onClickRemove(note)}
      type="button"
    >
      remove note
    </button>
  </div>
);

const NoteList: React.VFC<{ notes: WoaNote[]; editable?: boolean; isAdmin?: boolean }> = ({
  notes,
  editable = false,
  isAdmin = false,
}) => {
  const { isAdding, isRemoving, isSaving, removeArticleNote } = ArticleNoteContext.useContext();

  const [editNote, setEditNote] = React.useState<WoaNote>();
  const [removeNote, setRemoveNote] = React.useState<WoaNote>();

  const closeNoteRemove = () => setRemoveNote(undefined);

  const confirmRemove = () => {
    if (removeNote) {
      removeArticleNote(removeNote.id);
    }

    closeNoteRemove();
  };

  const sid = isAdmin ? 'adminNoteList' : 'userNoteList';

  return (
    <>
      {notes.map(el => (
        <div key={el.id} className="orderDetailsNotes_item">
          <div className="orderDetailsNotes_itemText" data-seleniumid={`${sid}-noteText-${el.id}`}>
            {el.note}
          </div>
          <div className="orderDetailsNotes_itemTitle" title="Updated by">
            {editable && (
              <NoteControls
                disabled={isAdding || isSaving || isRemoving}
                isRemoving={isRemoving}
                note={el}
                onClickEdit={setEditNote}
                onClickRemove={setRemoveNote}
              />
            )}

            <div className="orderDetailsNotes_itemUpdated">
              <span data-seleniumid={`${sid}-noteAuthor-${el.id}`}>
                {`${el.woaloggableUser.firstName} ${el.woaloggableUser.lastName}`}
              </span>
              {`, ${dateFormat(el.ts)}`}
            </div>
          </div>
        </div>
      ))}

      {editNote && (
        <NoteEditorModal
          close={() => setEditNote(undefined)}
          isInternal={editNote.isInternal}
          noteId={editNote.id}
          value={editNote.note}
        />
      )}

      {removeNote && (
        <ModalConfirmation
          buttonCloseClick={closeNoteRemove}
          buttonOkClick={confirmRemove}
          buttonOkTitle="Remove note"
          seleniumid="removeNoteModal"
          title="Remove Article Note"
        >
          <h4>Do you want to remove note?</h4>
        </ModalConfirmation>
      )}
    </>
  );
};

export default React.memo(NoteList);
