import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Modal from './Modal';

const closeDefault = {
  className: 'btn btn-light text-decoration-underline modal_action_button',
  onClick: null,
  title: 'Cancel',
};

class ModalInformation extends PureComponent {
  static propTypes = {
    title: PropTypes.node,
    children: PropTypes.node,
    className: PropTypes.string,

    buttonCloseTitle: PropTypes.string,
    buttonCloseClick: PropTypes.func,
    buttonCloseClassName: PropTypes.string,

    seleniumid: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    title: null,
    className: '',

    buttonCloseTitle: '',
    buttonCloseClick: null,
    buttonCloseClassName: '',

    seleniumid: 'modalInformation',
  };

  DOM = {
    cancelButton: React.createRef(),
  };

  state = {};

  componentDidMount() {
    this.DOM.cancelButton.current.focus();
  }

  onClose = () => {
    const { buttonCloseClick } = this.props;
    if (buttonCloseClick) {
      buttonCloseClick();
    }
  };

  renderHeader({ title }) {
    if (typeof title === 'string') {
      if (!title.trim()) return null;
      return <div key="modalHeaderTitle">{title}</div>;
    }
    if (title) {
      return title;
    }
    return null;
  }

  renderFooter({ buttonCloseTitle, buttonCloseClassName, seleniumid }) {
    return (
      <button
        ref={this.DOM.cancelButton}
        className={cn(closeDefault.className, buttonCloseClassName)}
        data-seleniumid={`${seleniumid}-close`}
        onClick={this.onClose}
        type="button"
      >
        {buttonCloseTitle || closeDefault.title}
      </button>
    );
  }

  render() {
    const { children, ...otherProps } = this.props;

    return (
      <Modal
        closeEsc
        {...otherProps}
        closeModal={this.onClose}
        footer={this.renderFooter(this.props)}
        title={this.renderHeader(this.props)}
        titleClose
      >
        {children}
      </Modal>
    );
  }
}

export default ModalInformation;
