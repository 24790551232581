import React from 'react';
import './Stats.scss';
import { Link } from 'react-router-dom';
import { mainRoutes } from 'routes';

const RequestsStats: React.VFC<{ ConsortiaStats: ConsortiaStats }> = ({ ConsortiaStats }) => {
  const { totalRequests, pendingRequests, approvedRequests, deniedRequests, cancelledRequests } = ConsortiaStats;

  return (
    <article className="stats">
      <header className="stats-header">
        <p className="stats-totalAmount" data-seleniumid="consortia-total-requests">
          {totalRequests}
        </p>
        <h4 className="stats-heading">Total Requests</h4>
      </header>
      <div className="stats-dataWrapper">
        <section className="stats-dataContainer stats-dataContainer--pendingRequests">
          <Link
            style={{ display: 'block', textDecoration: 'none', color: 'inherit' }}
            to={mainRoutes.consortiaPendingRequests.getUrl()}
          >
            <h5 className="stats-dataHeading">Pending</h5>
            <p className="stats-dataValue" data-seleniumid="consortia-pending-requests">
              {pendingRequests}
            </p>
          </Link>
        </section>
        <section className="stats-dataContainer stats-dataContainer--approvedRequests">
          <Link
            style={{ display: 'block', textDecoration: 'none', color: 'inherit' }}
            to={mainRoutes.consortiaApprovedRequests.getUrl()}
          >
            <h5 className="stats-dataHeading">Approved</h5>
            <p className="stats-dataValue" data-seleniumid="consortia-approved-requests">
              {approvedRequests}
            </p>
          </Link>
        </section>
        <section className="stats-dataContainer stats-dataContainer--deniedRequests">
          <Link
            style={{ display: 'block', textDecoration: 'none', color: 'inherit' }}
            to={mainRoutes.consortiaDeniedRequests.getUrl()}
          >
            <h5 className="stats-dataHeading">Denied</h5>
            <p className="stats-dataValue" data-seleniumid="consortia-denied-requests">
              {deniedRequests}
            </p>
          </Link>
        </section>
        <section className="stats-dataContainer stats-dataContainer--cancelledRequests">
          <Link
            style={{ display: 'block', textDecoration: 'none', color: 'inherit' }}
            to={mainRoutes.consortiaCancelledRequests.getUrl()}
          >
            <h5 className="stats-dataHeading">Cancelled</h5>
            <p className="stats-dataValue" data-seleniumid="consortia-cancelled-requests">
              {cancelledRequests}
            </p>
          </Link>
        </section>
      </div>
    </article>
  );
};

export default React.memo(RequestsStats);
