import cn from 'classnames';
import React from 'react';
import ReactSelect, { components } from 'react-select';

type JournalRevenueModelFilterEnum = 'Open Access' | 'Online Open' | 'Both';

function JournalRevenueModelFilter({
  className,
  onChange,
  value,
}: {
  className?: string;
  onChange: (journalRevenueModel: JournalRevenueModelFilterEnum) => void;
  value: JournalRevenueModelFilterEnum;
}) {
  const options: Array<{ value: JournalRevenueModelFilterEnum; label: string }> = [
    { value: 'Both', label: 'All Journal Revenue Models' },
    { value: 'Open Access', label: 'Open Access' },
    { value: 'Online Open', label: 'Online Open' },
  ];

  return (
    <ReactSelect
      className={cn('journalRevenueModelFilter', className)}
      classNamePrefix="journalRevenueModelFilter"
      components={{
        // eslint-disable-next-line @typescript-eslint/no-shadow
        Option: ({ className, ...rest }) => (
          <components.Option {...rest} className={cn(className, { 'text-primary': rest.isSelected })} />
        ),
      }}
      hideSelectedOptions={false}
      isSearchable={false}
      onChange={e => onChange(e.value)}
      options={options}
      placeholder="Select revenue model"
      styles={{
        container: styles => ({
          ...styles,
          marginLeft: '1em',
          width: '260px',
          zIndex: 5,
        }),
        control: (styles, state) => ({
          ...styles,
          borderColor: state.isFocused ? '#209d9d' : styles.borderColor,
          borderRadius: '0',
          boxShadow: state.isFocused ? '0 0 0 1px #209d9d' : styles.boxShadow,
          '&:hover': { borderColor: undefined },
        }),
        option: styles => ({
          ...styles,
          backgroundColor: null,
          padding: '2px 12px',
          '&:hover': { backgroundColor: '#cef1f3' },
        }),
      }}
      value={options.find(option => option.value === value)}
    />
  );
}

JournalRevenueModelFilter.defaultProps = {
  className: '',
};

export default JournalRevenueModelFilter;
