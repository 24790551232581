import orderBy from 'lodash.orderby';
import memoize from 'memoize-one';
import React, { PureComponent } from 'react';
import OrderDetailsModal from 'components/OrderDetailsModal';
import DealEnum from 'model/DealEnum';
import AccountContext from 'storage/AccountContext';
import OrdersViewRow from './OrdersViewRow';

const headers = [
  { field: 'view', title: '', sortable: false },
  { field: 'createdTs', title: 'Request Date', sortable: true },
  { field: 'status', title: 'Request Status', sortable: true },
  { field: 'author', title: 'RCA Name', sortable: true },
  { field: 'journalName', title: 'Journal', sortable: true },
  { field: 'allocatedAmount', title: 'Amount', sortable: true },
  { field: 'journalRevenueModel', title: 'Journal Revenue Model', sortable: true },
  { field: 'articlePublicationStatus', title: 'Publication Status', sortable: true },
];

type Props = {
  orders: WoaRequest[];
  pageSize?: number;
  selectedAccount: WoaAccount;
  sortable?: boolean;
};

type State = {
  visibleCount: number;
  sorted?: any;
  viewDetailsOrder?: number;
};

class OrdersList extends PureComponent<Props, State> {
  static defaultProps = {
    pageSize: 15,
    sortable: true,
  };

  state: State = {
    visibleCount: this.props.pageSize,
    sorted: null,
    viewDetailsOrder: null,
  };

  componentDidUpdate(prevProps) {
    const { orders, pageSize } = this.props;

    if (orders !== prevProps.orders) {
      this.setState({
        visibleCount: pageSize,
        sorted: null,
      });
    }
  }

  getSortedOrders = memoize((orders, sorted) => {
    if (sorted == null) {
      return orders;
    }

    return orderBy(orders, [sorted.field], [sorted.direction > 0 ? 'asc' : 'desc']);
  });

  closeDetails = () => this.setState({ viewDetailsOrder: null });

  openDetails = order => this.setState({ viewDetailsOrder: order.id });

  onClickSort = event => {
    const { field } = event.target.dataset;
    const header = headers.find(el => el.field === field && el.sortable);

    if (header) {
      const { sorted } = this.state;
      let direction = 1;
      if (sorted && sorted.field === field) {
        direction = sorted.direction < 0 ? 0 : -1;
      }
      if (direction) {
        this.setState({ sorted: { direction, field } });
      } else {
        this.setState({ sorted: null });
      }
    }
  };

  onClickSeeMore = () => this.setState(state => ({ visibleCount: state.visibleCount + this.props.pageSize }));

  render() {
    const { orders, pageSize, selectedAccount, sortable } = this.props;
    const { visibleCount, sorted, viewDetailsOrder } = this.state;
    const sortedOrders = this.getSortedOrders(orders, sorted);

    return (
      <div>
        {!!viewDetailsOrder && (
          <OrderDetailsModal
            close={this.closeDetails}
            orderId={viewDetailsOrder}
            showPrice={selectedAccount.showPrice}
          />
        )}
        <table className="table ordersView">
          <thead>
            <tr className="ordersView-headRow">
              {headers
                .filter(x => x.field !== 'journalRevenueModel' || selectedAccount.dealType === DealEnum.BOTH)
                .map(header => (
                  <th key={header.field} className="ordersView-headCell">
                    {sortable && header.sortable ? (
                      <button
                        className="btn btn-link text-reset p_0 bold"
                        data-field={header.field}
                        data-seleniumid={`ordersView-sort--${header.field}`}
                        onClick={this.onClickSort}
                        style={{ fontSize: '1em' }}
                        type="button"
                      >
                        {header.title}
                        {sorted && sorted.field === header.field && (
                          <span className={sorted.direction > 0 ? 'm--sortAsc' : 'm--sortDesc'} />
                        )}
                      </button>
                    ) : (
                      header.title
                    )}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {sortedOrders.map((order, i) =>
              i >= visibleCount && pageSize > 0 ? null : (
                <OrdersViewRow
                  key={order.id}
                  currency={selectedAccount.currency}
                  dealType={selectedAccount.dealType}
                  onView={this.openDetails}
                  order={order}
                  showPrice={selectedAccount.showPrice}
                />
              ),
            )}
          </tbody>
        </table>

        <div>
          {/* It is KOSTYL for react bug. Ask Denis Shelest about it */}
          {orders.length === 0 ? 'No request found' : ''}
        </div>

        {visibleCount < orders.length && pageSize > 0 && (
          <button
            className="btn btn-secondary btn-block mt_10"
            data-seleniumid="ordersView-seeMore"
            onClick={this.onClickSeeMore}
            type="button"
          >
            {`See More (${orders.length - visibleCount})`}
          </button>
        )}
      </div>
    );
  }
}

export default AccountContext.withContext(['selectedAccount'])(OrdersList);
