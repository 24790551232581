import PropTypes from 'prop-types';
import React from 'react';
import { mainRoutes } from 'routes';
import { downloadReport } from 'services/api/consortia';
import { withErrorNotification } from 'storage/NotificationsContext';
import withAuthRequired from 'with/withAuthRequired';
import { compose } from 'with/withContext';
import withMainLayout from 'with/withMainLayout';
import withNavigate from 'with/withNavigate';

class ReportDownloadPage extends React.PureComponent {
  static propTypes = {
    navigate: PropTypes.func.isRequired,
  };

  state = { url: null };

  @withErrorNotification
  async componentDidMount() {
    const reportId = (window.location.href.match(/reports\/(.+)$/) || [])[1];

    if (reportId) {
      const { downloadUrl: url } = await downloadReport(reportId);
      this.setState({ url }, () => {
        window.location.href = url;
      });
    } else {
      this.props.navigate(mainRoutes.home.getUrl());
      throw new Error('Report you requested is not accessible');
    }
  }

  render() {
    const { url } = this.state;

    return (
      <div className="single-message">
        {url ? (
          <>
            <h1>If download is not started, please click a link below.</h1>
            <a href={url}>Click to download</a>
          </>
        ) : (
          <>
            <p>Loading...</p>
            <h1>Report download will start automatically soon.</h1>
          </>
        )}
      </div>
    );
  }
}

export default compose(withAuthRequired, withMainLayout, withNavigate, ReportDownloadPage);
