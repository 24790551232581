import React, { PureComponent } from 'react';
import RootLayout from 'components/Layouts/RootLayout';
import NotificationPopupsListener from 'components/Notification/NotificationPopupsListener';
import NotificationsContext from 'storage/NotificationsContext';

class DataLayout extends PureComponent {
  render() {
    return (
      <>
        <RootLayout {...this.props} />
        <NotificationPopupsListener />
      </>
    );
  }
}

export default NotificationsContext.withProvider(DataLayout);
