/* eslint react/no-unused-state: 0 */

import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { processing } from 'decorators';
import * as mainApi from 'services/api/main';
import { EventEmit } from 'services/events/emitter';
import { withErrorNotification } from 'storage/NotificationsContext';
import mainWithContext from 'with/withContext';

export const Emitter = {
  NOTE_WAS_ADDED: new EventEmit('NOTE_WAS_ADDED'),
  NOTE_WAS_UPDATED: new EventEmit('NOTE_WAS_UPDATED'),
  NOTE_WAS_REMOVED: new EventEmit('NOTE_WAS_REMOVED'),
};

type ArticleNoteContextInterface = {
  isSaving: boolean;
  isAdding: boolean;
  isRemoving: boolean;
  editArticleNote: (noteId: number, note: string) => void;
  addArticleNote: (accountId: number, articleId: string, isInternal: boolean, note: string) => void;
  removeArticleNote: (noteId: number) => void;
};

const defaultValue: ArticleNoteContextInterface = {
  isSaving: false,
  isAdding: false,
  isRemoving: false,
  editArticleNote: () => {},
  addArticleNote: () => {},
  removeArticleNote: () => {},
};

const ArticleNoteContext = React.createContext<ArticleNoteContextInterface>(defaultValue);

function withProvider(WrappedComponent) {
  @autobind
  class ArticleNoteProviderWrapper extends PureComponent<any, ArticleNoteContextInterface> {
    state = {
      ...defaultValue,
      editArticleNote: this.editArticleNote,
      addArticleNote: this.addArticleNote,
      removeArticleNote: this.removeArticleNote,
    };

    @withErrorNotification
    @processing('isSaving')
    async editArticleNote(noteId, note) {
      const newNote = await mainApi.editArticleNote(noteId, note);
      Emitter.NOTE_WAS_UPDATED.emit([noteId, newNote, 1]);
    }

    @withErrorNotification
    @processing('isAdding')
    async addArticleNote(accountId, articleId, isInternal, note) {
      const newNote = await mainApi.addArticleNote(accountId, articleId, isInternal, note);
      Emitter.NOTE_WAS_ADDED.emit([articleId, newNote]);
    }

    @withErrorNotification
    @processing('isRemoving')
    async removeArticleNote(noteId) {
      await mainApi.removeArticleNote(noteId);
      Emitter.NOTE_WAS_REMOVED.emit([noteId]);
    }

    render() {
      return (
        <ArticleNoteContext.Provider value={this.state}>
          <WrappedComponent {...this.props} />
        </ArticleNoteContext.Provider>
      );
    }
  }

  return ArticleNoteProviderWrapper;
}

export { ArticleNoteContextInterface };
export default {
  useContext: () => React.useContext(ArticleNoteContext),
  withProvider,
  withContext: mainWithContext(ArticleNoteContext),
};
