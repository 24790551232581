import React from 'react';
import NoteEditorModal from 'components/NoteEditorModal';
import NoteList from 'components/OrderDetailsModal/NoteList';

type Props = {
  articleId: string;
  isEditable: boolean;
  notes: WoaNote[];
};

export default class UserNoteList extends React.PureComponent<Props> {
  state = {
    newNote: false,
    isInternal: false,
  };

  showNewNote = () => this.setState({ newNote: true, isInternal: false });

  hideNewNote = () => this.setState({ newNote: false });

  render() {
    const { articleId, isEditable, notes } = this.props;
    const { newNote, isInternal } = this.state;

    return (
      <section className="orderDetailsNotes">
        <div className="orderDetailsNotes_title">WOA User Messages to Wiley</div>
        <div data-seleniumid="userNoteList-notes">
          {notes.length > 0 ? (
            <NoteList editable={isEditable} notes={notes} />
          ) : (
            <>
              <div className="p_5">NOT AVAILABLE</div>
              {isEditable && (
                <div className="p_5">
                  <button
                    className="btn btn-secondary btn-sm"
                    data-seleniumid="orderDetailsView-addNote"
                    onClick={this.showNewNote}
                    type="button"
                  >
                    Add new note
                  </button>
                </div>
              )}
            </>
          )}

          {newNote && <NoteEditorModal articleId={articleId} close={this.hideNewNote} isInternal={isInternal} />}
        </div>
      </section>
    );
  }
}
