import cn from 'classnames';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import React from 'react';
import { InlineHelp } from 'components/Elements';
import ModalConfirmation from 'components/Modal/ModalConfirmation';
import UserSelect from 'components/UserSelect';
import { form } from 'decorators';
import { trimStringProperties, validation as v } from 'services/utils';

@autobind
@form
class AddUserModal extends React.PureComponent {
  static propTypes = {
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    woaaccount: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
  };

  state = {
    fields: {
      customEmail: '',
      existedEmail: '',
    },
    submitting: false,
    validation: {},
  };

  onUserChange(user) {
    const email = user != null ? user.email : '';

    this.setFieldValue('existedEmail', email);

    if (email) {
      this.setFieldValue('customEmail', '');
    }
  }

  @autobind
  onFocus(e) {
    this.onInputFocus(e);

    this.hideError('common');
  }

  async onSubmit(e) {
    e.preventDefault();

    if (!this.validate()) {
      return;
    }

    try {
      this.setState({ submitting: true });

      const fields = trimStringProperties(this.state.fields);

      if (fields.customEmail) {
        await this.props.onAdd(fields.customEmail);
      } else {
        await this.props.onAdd(fields.existedEmail);
      }
    } finally {
      this.setState({ submitting: false });
    }
  }

  validate() {
    const fields = trimStringProperties(this.state.fields);
    let errors = {};

    if (!fields.customEmail && !fields.existedEmail) {
      errors = {
        common: 'Choose any existing user or type email of a new one.',
      };
    } else if (fields.customEmail) {
      errors = {
        customEmail: v.validate(fields.customEmail, [[v.isEmailValid, 'Must be valid email.']]),
      };
    }

    this.setState({ validation: errors });

    return Object.values(errors).every(x => !x);
  }

  render() {
    const { onCancel, woaaccount } = this.props;
    const { fields, submitting, validation } = this.state;

    return (
      <ModalConfirmation
        buttonCloseClick={onCancel}
        buttonOkClassName={cn({ 'is-loading': submitting })}
        buttonOkClick={this.onSubmit}
        buttonOkDisabled={submitting}
        buttonOkTitle="Add User"
        className="addUserModal"
        seleniumid="addUserModal"
        title="Add User"
      >
        <form>
          <div>Users added to this account will have permissions to approve and deny funding requests.</div>

          <div style={{ display: 'flex', marginTop: '0.5em' }}>
            <div className="text-right" style={{ width: '20%', paddingTop: '4px' }}>
              Existing user:
            </div>
            <div style={{ width: '79%', paddingLeft: '0.5em' }}>
              <UserSelect
                accountId={woaaccount.id}
                disabled={!!fields.customEmail}
                onChange={this.onUserChange}
                onFocus={this.onFocus}
              />
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', padding: '0 30px' }}>
            <div style={{ flex: '1 0 0', borderBottom: '1px solid #000' }} />
            <div style={{ margin: '0 10px' }}>OR</div>
            <div style={{ flex: '1 0 0', borderBottom: '1px solid #000' }} />
          </div>

          <div style={{ display: 'flex', marginTop: '0.5em' }}>
            <div className="text-right" style={{ width: '20%', paddingTop: '4px' }}>
              New user:
            </div>
            <div style={{ width: '79%', paddingLeft: '0.5em' }}>
              <input
                className={cn('input w_100p', { 'is-error': validation.customEmail })}
                data-seleniumid="addUserModal-customEmail"
                disabled={!!fields.existedEmail}
                name="customEmail"
                onChange={this.onInputChange}
                onFocus={this.onFocus}
                placeholder="example@gmail.com"
                type="text"
                value={fields.customEmail}
              />
              {validation.customEmail && <InlineHelp className="d-block is-error">{validation.customEmail}</InlineHelp>}
            </div>
          </div>

          {validation.common && <InlineHelp className="d-block is-error">{validation.common}</InlineHelp>}
        </form>
      </ModalConfirmation>
    );
  }
}

export default AddUserModal;
