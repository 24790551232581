import moment from 'moment';
/*= ========================================================================================== */
export function guidCustom(segments = 8) {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  let guid = '';
  for (let i = 0; i < segments; i += 1) {
    guid = `${guid}${s4()}`;
  }
  return guid;
}
/*= ========================================================================================== */
export function currencyChar(currency) {
  return { usd: '\u0024', gbp: '\u20A4', eur: '\u20AC' }[currency.toLowerCase()] || '';
}
/*= ========================================================================================== */
export function getNoun(number, one, two, five) {
  const absNumber = Math.abs(number);
  let modNumber = absNumber % 100;
  if (modNumber >= 5 && modNumber <= 10) return five;
  modNumber = absNumber % 10;
  if (modNumber === 1) return one;
  if (modNumber >= 2 && modNumber <= 4) return two;
  return five;
}
/*= ========================================================================================== */
export function currencyFormat(pValue, pCurrency) {
  const currency = pCurrency ? currencyChar(pCurrency) : '';
  const value = parseFloat(String(pValue).replace(/\s+/, ''));
  const R = String(value).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,');
  const [left, right] = R.split('.');
  const dec = `${right || ''}00`.slice(0, right ? 2 : 0);
  return `${currency}${left}${dec ? '.' : ''}${dec}`;
}
/*= ========================================================================================== */
export function getDeep(pObj, pPath, pDefault = undefined) {
  if (!pObj) return pDefault;
  const pieces = Array.isArray(pPath) ? pPath : pPath.split('.');
  return pieces.reduce((R, key) => (R ? R[key] : pDefault), pObj);
}
/*= ========================================================================================== */
export function dateFormat(date, withTime = false) {
  return date ? moment(date).format(withTime ? 'DD MMM YYYY hh:mm:ss' : 'DD MMM YYYY') : '';
}
/*= ========================================================================================== */
export function validateEmail(value) {
  const reg =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(String(value).toLowerCase());
}
/*= ========================================================================================== */
export function itIsParent(element, parent) {
  let dom = element;

  while (dom) {
    if (dom === parent) return true;
    dom = dom.parentElement;
  }
  return false;
}

export const validation = {
  notEmpty(value) {
    return !!value;
  },
  checkStringMaxLength(maxLength) {
    return function (value) {
      return typeof value === 'string' && value.length <= maxLength;
    };
  },
  isEmailValid(value) {
    return validateEmail(value);
  },
  isNumber(value) {
    return /^-{0,1}\d*\.{0,1}\d+$/.test(value);
  },
  isPositiveNumber(value) {
    return validation.isNumber(value) && parseFloat(value) > 0;
  },
  validate(value, ar = []) {
    let error = '';

    ar.some(([validator, message]) => {
      if (validator(value)) {
        return false;
      }

      error = message;
      return true;
    });

    return error;
  },
};

export function trimStringProperties(obj) {
  const out = { ...obj };

  Object.entries(out).forEach(([key, value]) => {
    if (typeof value === 'string') {
      out[key] = value.trim();
    }
  });

  return out;
}

export function getRemainingChars(value, maxLength) {
  const valueLength = value.trim().length;
  return maxLength - valueLength;
}

export function thresholdCut(value, threshold = 2) {
  return value && value.trim().length >= threshold ? value.trim() : undefined;
}
