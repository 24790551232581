import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Modal from './Modal';

const okDefault = {
  className: 'btn btn-secondary modal_action_button',
  onClick: null,
  title: 'Ok',
};

const closeDefault = {
  className: 'btn btn-light text-decoration-underline modal_action_button',
  onClick: null,
  title: 'Cancel',
};

class ModalConfirmation extends PureComponent {
  static propTypes = {
    title: PropTypes.node,
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,

    buttonOkTitle: PropTypes.string,
    buttonOkClick: PropTypes.func,
    buttonOkClassName: PropTypes.string,
    buttonOkDisabled: PropTypes.bool,

    buttonCloseTitle: PropTypes.string,
    buttonCloseClick: PropTypes.func,
    buttonCloseClassName: PropTypes.string,
    buttonCloseDisabled: PropTypes.bool,

    seleniumid: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    title: null,
    className: '',
    disabled: false,

    buttonOkTitle: '',
    buttonOkClick: null,
    buttonOkClassName: '',
    buttonOkDisabled: false,

    buttonCloseTitle: '',
    buttonCloseClick: null,
    buttonCloseClassName: '',
    buttonCloseDisabled: false,

    seleniumid: 'modalConfirmation',
  };

  DOM = {};

  componentDidMount() {
    this.DOM.okButton.focus();
  }

  onClose = () => {
    const { buttonCloseClick, buttonCloseDisabled } = this.props;
    if (!buttonCloseDisabled && buttonCloseClick) {
      buttonCloseClick();
    }
  };

  renderHeader({ title }) {
    if (typeof title === 'string') {
      if (!title.trim()) return null;
      return (
        <div key="modalHeaderTitle" className="enote_modal__title_simple">
          {title}
        </div>
      );
    }
    if (title) {
      return title;
    }
    return null;
  }

  renderFooter({
    buttonOkTitle,
    buttonOkClick,
    buttonOkClassName,
    buttonOkDisabled,
    buttonCloseTitle,
    buttonCloseClassName,
    buttonCloseDisabled,
    disabled,
    seleniumid,
  }) {
    return [
      <button
        key="modalCancelButton"
        className={cn(closeDefault.className, buttonCloseClassName)}
        data-seleniumid={`${seleniumid}-cancel`}
        disabled={buttonCloseDisabled}
        onClick={this.onClose}
        type="button"
      >
        {buttonCloseTitle || closeDefault.title}
      </button>,
      <button
        key="modalOkButton"
        ref={x => {
          this.DOM.okButton = x;
        }}
        className={cn(okDefault.className, buttonOkClassName)}
        data-seleniumid={`${seleniumid}-confirm`}
        disabled={buttonOkDisabled || disabled}
        onClick={buttonOkClick}
        type="button"
      >
        {buttonOkTitle || okDefault.title}
      </button>,
    ];
  }

  render() {
    const { children, ...otherProps } = this.props;

    return (
      <Modal
        closeEsc
        {...otherProps}
        closeModal={this.onClose}
        footer={this.renderFooter(this.props)}
        title={this.renderHeader(this.props)}
        titleClose
      >
        {children}
      </Modal>
    );
  }
}

export default ModalConfirmation;
