import React from 'react';
import AccountSelectorForm from 'components/AccountSelectorForm';
import ErrorBaner from 'components/ErrorBaner';
import LoaderLayout from 'components/Layouts/LoaderLayout';
import AccountContext from 'storage/AccountContext';

const error = new Error('You have no access to any woa account.');

export default function withSelectedAccountRequired(Component) {
  return AccountContext.withContext(['accountsList', 'isFetching', 'selectedAccount'])(props => {
    if (props.selectedAccount) {
      return <Component {...props} />;
    }
    if (props.isFetching) {
      return <LoaderLayout isLoading />;
    }
    if (props.accountsList.length === 0) {
      return <ErrorBaner error={error} />;
    }

    return <AccountSelectorForm />;
  });
}
