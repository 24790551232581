import React from 'react';
import ModalInformation from 'components/Modal/ModalInformation';
import AlertWindowContent from './AlertWindowContent';

import './alertsWindow.scss';

function AlertsWindow({ close }: { close: () => void }) {
  return (
    <ModalInformation buttonCloseClick={close} className="alertsWindow" title="Alerts">
      <AlertWindowContent />
    </ModalInformation>
  );
}

export default AlertsWindow;
