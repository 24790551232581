import React from 'react';
import DealEnum from 'model/DealEnum';
import { isArticleAndAccountNotesEqual } from 'services/api/utils';
import { dateFormat, currencyFormat } from 'services/utils';

type Props = {
  currency: string;
  dealType: DealEnum;
  event: WoaEvent;
  onView: (event: WoaEvent) => void;
  showPrice: boolean;
};

function EventsViewRow({ event, currency, dealType, onView, showPrice }: Props) {
  const onClickView = () => onView(event);

  const { woarequest } = event;
  const { woaarticle: { woanotes = [] } = {} } = woarequest;

  const isNoteIconVisible =
    woanotes.some(el => el.isPublic && !el.isInternal) ||
    isArticleAndAccountNotesEqual(woarequest.woaaccount, woanotes);

  return (
    <tr className="eventsView-dataRow">
      <td className="eventsView-dataCell">
        <div className="flex_v_center">
          <button
            className="btn btn-light btn-sm"
            data-seleniumid={`eventsView-viewOrder--${woarequest.id}`}
            onClick={onClickView}
            type="button"
          >
            View
          </button>
          {isNoteIconVisible && <span className="orderHasNoteIcon ml_5" title="There are notes" />}
        </div>
      </td>
      <td className="eventsView-dataCell nobr ta_c" data-seleniumid={`eventsView-createdTs--${woarequest.id}`}>
        {dateFormat(woarequest.createdTs)}
      </td>
      <td className="eventsView-dataCell ta_c" data-seleniumid={`eventsView-createdTs--${woarequest.id}`}>
        {woarequest.status}
      </td>
      <td className="eventsView-dataCell" data-seleniumid={`eventsView-author--${woarequest.id}`}>
        {woarequest.author}
      </td>
      <td className="eventsView-dataCell" data-seleniumid={`eventsView-journalName--${woarequest.id}`}>
        {woarequest.journalName}
      </td>
      {showPrice && (
        <td className="eventsView-dataCell nobr" data-seleniumid={`eventsView-allocatedAmount--${woarequest.id}`}>
          {currencyFormat(woarequest.allocatedAmount, currency)}
        </td>
      )}
      {dealType === DealEnum.BOTH && (
        <td className="eventsView-dataCell ta_c" data-seleniumid={`eventsView-journalRevenueModel--${woarequest.id}`}>
          {woarequest.journalRevenueModel}
        </td>
      )}
      <td
        className="eventsView-dataCell ta_c"
        data-seleniumid={`eventsView-articlePublicationStatus--${woarequest.id}`}
      >
        {woarequest.articlePublicationStatus}
      </td>
    </tr>
  );
}

export default React.memo(EventsViewRow);
