import React, { PureComponent } from 'react';
import AccountSelector from 'components/AccountSelector';
import ModalConfirmation from 'components/Modal/ModalConfirmation';
import AccountContext, { AccountContextInterface } from 'storage/AccountContext';

class AccountSelectorModal<T extends AccountContextInterface['accountsList'][0]> extends PureComponent<
  {
    selectedAccount: T;
    setSelectedAccountById: AccountContextInterface['setSelectedAccountById'];
    onClose: () => void;
  },
  { selected: T }
> {
  state = {
    selected: this.props.selectedAccount,
  };

  onChangeAccount = account => this.setState({ selected: account });

  onChangeAccountAndConfirm = account => this.setState({ selected: account }, this.onConfirm);

  onConfirm = () => {
    const { selected } = this.state;
    const { setSelectedAccountById, onClose } = this.props;

    setSelectedAccountById(selected.id);
    onClose();
  };

  render() {
    const { onClose } = this.props;
    const { selected } = this.state;

    return (
      <ModalConfirmation
        buttonCloseClick={onClose}
        buttonOkClick={this.onConfirm}
        seleniumid="accountSelectorForm"
        title="Accounts"
      >
        <h4>Please choose the account you wish to manage</h4>
        <AccountSelector
          onChange={this.onChangeAccount}
          onChangeAndConfirm={this.onChangeAccountAndConfirm}
          selected={selected}
        />
      </ModalConfirmation>
    );
  }
}

export default AccountContext.withContext(['selectedAccount', 'setSelectedAccountById'])(AccountSelectorModal);
