import PropTypes from 'prop-types';
import React from 'react';
import withConfirm from 'with/withConfirm';

function Button({ seleniumid, children, ...anotherProps }) {
  return (
    <button data-seleniumid={seleniumid} type="button" {...anotherProps} className="btn btn-primary btn-sm">
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  seleniumid: PropTypes.string,
};

Button.defaultProps = {
  children: null,
  seleniumid: 'confirmButton',
};

export default withConfirm(Button);
