import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Elements from 'components/Elements';
import ErrorBaner from 'components/ErrorBaner';
import LoaderLayout from 'components/Layouts/LoaderLayout';
import OrdersList from 'components/OrdersList';
import PendingOrders from 'components/PendingOrders';
import RequestReportModal from 'components/RequestReportModal/RequestReportModal';
import Search from 'components/Search/Search';
import SearchBanner from 'components/SearchBanner/SearchBanner';
import AccountContext from 'storage/AccountContext';
import { Emitter as NotesEmitter } from 'storage/ArticleNoteContext';
import { Emitter } from 'storage/OrderActionsContext';
import OrdersContext from 'storage/OrdersContext';
import { compose } from 'with/withContext';

const defaultState = {
  showAll: false,
  requestReport: false,
};

class OrdersFilter extends PureComponent {
  static propTypes = {
    error: PropTypes.shape({}),
    isFetching: PropTypes.bool.isRequired,
    isReportSending: PropTypes.bool.isRequired,
    isSelectedAccountBeingFetched: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    keyword: PropTypes.string,
    getOrdersList: PropTypes.func.isRequired,
    ordersList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    reloadOrders: PropTypes.func.isRequired,
    selectedAccountId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    error: null,
    keyword: '',
  };

  static getDerivedStateFromProps(props, state) {
    if (!props.isSelectedAccountBeingFetched && props.selectedAccountId !== state.prevSelectedAccountId) {
      const patch = {
        ...defaultState,
        prevSelectedAccountId: props.selectedAccountId,
      };

      props.getOrdersList(props.selectedAccountId, { keyword: props.keyword, showAll: patch.showAll });

      return patch;
    }

    return null;
  }

  state = { ...defaultState };

  subscriptions = [];

  componentDidMount() {
    this.subscriptions.push(
      Emitter.ORDER_WAS_UPDATED.subscribe(this.afterNoteEvent),
      NotesEmitter.NOTE_WAS_ADDED.subscribe(this.afterNoteEvent),
      NotesEmitter.NOTE_WAS_UPDATED.subscribe(this.afterNoteEvent),
      NotesEmitter.NOTE_WAS_REMOVED.subscribe(this.afterNoteEvent),
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(x => x());
  }

  getOrders = keyword => {
    const { selectedAccountId, getOrdersList } = this.props;
    const { showAll } = this.state;
    getOrdersList(selectedAccountId, { keyword, showAll });
  };

  openRequestReportDialog = () => this.setState({ requestReport: true });

  closeRequestReportDialog = () => this.setState({ requestReport: false });

  onChangeShowAll = event => this.setState({ showAll: event.target.checked }, this.getOrders);

  clearSearch = () => {
    this.getOrders();
  };

  afterNoteEvent = () => {
    this.props.reloadOrders(this.state.showAll);
  };

  render() {
    const { isFetching, isReportSending, keyword, ordersList, error } = this.props;
    const { showAll, requestReport } = this.state;

    return (
      <>
        <PendingOrders />

        {requestReport && <RequestReportModal close={this.closeRequestReportDialog} />}

        <LoaderLayout className="w_100p" isLoading={isFetching}>
          <div className="ordersFilter-options">
            <div className="ordersFilter-showAll mr_10">
              <Elements.Checkbox
                checked={showAll}
                onChange={this.onChangeShowAll}
                seleniumid="ordersFilter-showAllHistory"
              >
                show all request history
              </Elements.Checkbox>
            </div>
            <Search
              buttonSeleniumId="ordersFilter-search"
              inputClassName="ordersFilter-SearchInput"
              inputSeleniumId="ordersFilter-searchInput"
              isSearching={isFetching}
              keyword={keyword}
              onSubmit={this.getOrders}
              placeholder="Author, article title, DOI, journal or manuscript ID"
            />
          </div>

          <SearchBanner
            buttonSeleniumId="ordersFilter-clearSearchResult"
            isVisible={!!keyword}
            labelSeleniumId="ordersFilter-searchResult"
            onClear={this.clearSearch}
            text={`${isFetching ? '?' : ordersList.length} Results for your search for "${keyword}"`}
          />

          <h4 className="flex_v_center">
            {`${showAll ? 'All History' : 'Approved Requests'} (${ordersList.length})`}
            <button
              className="btn btn-secondary ml_10"
              data-seleniumid="ordersFilter-requestReport"
              disabled={ordersList.length === 0 || isReportSending}
              onClick={this.openRequestReportDialog}
              type="button"
            >
              Request Report
            </button>
          </h4>

          <OrdersList orders={ordersList} />

          <ErrorBaner error={error} />
        </LoaderLayout>
      </>
    );
  }
}

export default compose(
  AccountContext.withContext(state => ({
    isSelectedAccountBeingFetched: state.isFetching,
    selectedAccountId: state.selectedAccount.id,
  })),
  OrdersContext.withContext([
    'error',
    'isFetching',
    'isReportSending',
    'keyword',
    'getOrdersList',
    'ordersList',
    'reloadOrders',
  ]),
  OrdersFilter,
);
