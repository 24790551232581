import React from 'react';
import LoaderLayout from 'components/Layouts/LoaderLayout';
import ConfigContext from 'storage/ConfigContext';

export default function withConfigRequired(Component) {
  return function WithConfigRequired(props) {
    const { config, isFetching } = ConfigContext.useContext();

    if (isFetching) {
      return <LoaderLayout isLoading />;
    }

    return <Component {...props} config={config} />;
  };
}
