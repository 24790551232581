import AboutCookiesPage from 'components/Pages/AboutCookiesPage';
import ConsortiaAccountsPage from 'components/Pages/ConsortiaAccountsPage';
import ConsortiaApprovedRequestsPage from 'components/Pages/ConsortiaApprovedRequestsPage';
import ConsortiaCancelledRequestsPage from 'components/Pages/ConsortiaCancelledRequestsPage';
import ConsortiaDeniedRequestsPage from 'components/Pages/ConsortiaDeniedRequestsPage';
import ConsortiaPendingRequestsPage from 'components/Pages/ConsortiaPendingRequestsPage';
import HelpConsortiaPage from 'components/Pages/HelpConsortiaPage';
import HelpPage from 'components/Pages/HelpPage';
import HomePage from 'components/Pages/HomePage';
import Page404 from 'components/Pages/Page404';
import ProfilePage from 'components/Pages/ProfilePage';
import ReportDownloadPage from 'components/Pages/ReportDownloadPage';
import VersionPage from 'components/Pages/VersionPage';
import WoaatncPage from 'components/Pages/WoaatncPage';

/* ================================================================================================== */
class RouteClass {
  constructor({ key, url, component, title, exact = true }) {
    this.key = key;
    this.url = url;
    this.component = component;
    this.title = title;
    this.exact = exact;
  }

  getUrl = args => {
    const url = Object.keys(args || {}).reduce((R, key) => {
      const v = args[key];
      return R.replace(`:${key}/`, `${v}/`).replace(`:${key}|`, `${v}|`);
    }, `${this.url}|`);
    return url.slice(0, url.length - 1);
  };

  /**
   * @param {Location} location
   * @returns {boolean}
   */
  isHere(location) {
    return this.url === location.pathname;
  }
}
/* ================================================================================================== */
export const mainRoutes = {
  index: new RouteClass({ url: '/index.html', component: HomePage, title: '' }),
  home: new RouteClass({ url: '/', component: HomePage, title: '' }),
  aboutcookies: new RouteClass({ url: '/aboutcookies', component: AboutCookiesPage, title: '' }),
  consortiaAccounts: new RouteClass({ url: '/consortia/accounts', component: ConsortiaAccountsPage, title: '' }),
  consortiaApprovedRequests: new RouteClass({
    url: '/consortia/requests/approved',
    component: ConsortiaApprovedRequestsPage,
    title: '',
  }),
  consortiaDeniedRequests: new RouteClass({
    url: '/consortia/requests/denied',
    component: ConsortiaDeniedRequestsPage,
    title: '',
  }),
  consortiaPendingRequests: new RouteClass({
    url: '/consortia/requests/pending',
    component: ConsortiaPendingRequestsPage,
    title: '',
  }),
  consortiaCancelledRequests: new RouteClass({
    url: '/consortia/requests/cancelled',
    component: ConsortiaCancelledRequestsPage,
    title: '',
  }),

  help: new RouteClass({ url: '/help', component: HelpPage, title: '' }),
  helpconsortia: new RouteClass({ url: '/helpconsortia', component: HelpConsortiaPage, title: '' }),
  profile: new RouteClass({ url: '/profile', component: ProfilePage, title: '' }),
  version: new RouteClass({ url: '/version', component: VersionPage, title: '' }),
  woaatnc: new RouteClass({ url: '/woaatnc', component: WoaatncPage, title: '' }),
  reports: new RouteClass({ url: '/reports/*', component: ReportDownloadPage, title: '' }),
  all: new RouteClass({ url: '/*', component: Page404, title: 'Undefined page / 404' }),
};

export default {
  mainRoutes,
};
