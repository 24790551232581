/* eslint react/no-unused-state: 0 */

import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import * as mainApi from 'services/api/main';
import { EventEmit } from 'services/events/emitter';
import mainWithContext from 'with/withContext';
import NotificationsContext from './NotificationsContext';

const ERROR_ECORE_LOW_BALANCE = 'Unexpected response description from eCore: ERROR';

export const Emitter = {
  ORDER_WAS_UPDATED: new EventEmit('ORDER_WAS_UPDATED'),
};

type OrderActionsContextInterface = {
  isFetching: { [orderId: number]: boolean };
  isSuccess: { [orderId: number]: boolean };
  approveOrder: (orderId: number) => Promise<void>;
  denyOrder: (orderId: number, params: { reason: string }) => Promise<void>;
};

const defaultValue = {
  isFetching: {},
  isSuccess: {},
  approveOrder: () => Promise.resolve(),
  denyOrder: () => Promise.resolve(),
};

const OrderActionsContext = React.createContext<OrderActionsContextInterface>(defaultValue);

function withProvider(WrappedComponent) {
  @autobind
  class OrderActionsWrapper extends PureComponent<any, OrderActionsContextInterface> {
    state = {
      ...defaultValue,
      approveOrder: this.approveOrder,
      denyOrder: this.denyOrder,
    };

    setFetching(orderId, isFetching, isSuccess = false) {
      this.setState(state => ({
        isFetching: {
          ...state.isFetching,
          [orderId]: isFetching,
        },
        isSuccess: {
          ...state.isSuccess,
          [orderId]: isSuccess,
        },
      }));
    }

    approveOrder(orderId: number) {
      return this.sendActionRequest(orderId, 'approve');
    }

    denyOrder(orderId: number, params) {
      return this.sendActionRequest(orderId, 'deny', params);
    }

    sendActionRequest = async (orderId, action, params?: { reason: string }) => {
      const { addInfoNotify, addErrorNotify } = this.props;
      this.setFetching(orderId, true);
      let apiFunction = null;
      let message = '';
      try {
        switch (action) {
          case 'approve':
            apiFunction = mainApi.approveOrder;
            message = 'Order was approved';
            break;

          case 'deny':
            apiFunction = mainApi.denyOrder;
            message = 'Order was denied';
            break;

          default:
            throw new Error(`'${action}' action type is undefined`);
        }
        await apiFunction(orderId, params);
        this.setFetching(orderId, false, true);
        addInfoNotify(message);
        Emitter.ORDER_WAS_UPDATED.emit([action]);
      } catch (error) {
        this.setFetching(orderId, false);

        if (error.message === ERROR_ECORE_LOW_BALANCE) {
          addErrorNotify(
            'There was a problem at this time. Please try again later or contact Customer Support for assistance.',
          );
        } else {
          addErrorNotify(error.message);
        }
      }
    };

    render() {
      return (
        <OrderActionsContext.Provider value={this.state}>
          <WrappedComponent {...this.props} />
        </OrderActionsContext.Provider>
      );
    }
  }

  return NotificationsContext.withContext(['addInfoNotify', 'addErrorNotify'])(OrderActionsWrapper);
}

export { OrderActionsContextInterface };
export default {
  useContext: () => React.useContext(OrderActionsContext),
  withProvider,
  withContext: mainWithContext(OrderActionsContext),
  selectIsFetching: (state, orderId) => state.isFetching[orderId] || false,
};
