import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { mainRoutes } from 'routes';
import withDataProviders from 'storage/withDataProviders';

const routesKeys = Object.keys(mainRoutes);

function RootLayout() {
  return (
    <Routes>
      {routesKeys.map(key => {
        const Component = mainRoutes[key].component;

        return (
          <Route
            key={mainRoutes[key].url}
            element={<Component />}
            exact={mainRoutes[key].exact}
            path={mainRoutes[key].url}
          />
        );
      })}
    </Routes>
  );
}

export default withDataProviders(RootLayout);
