import React from 'react';
import StatusHelpLine from './StatusHelpLine';

export default function RequestStatuses() {
  return (
    <div style={{ border: '2px solid transparent', padding: '0 0.5em' }}>
      <div className="font-weight-bold text-center" style={{ marginTop: '1em' }}>
        Publication Statuses
      </div>

      <StatusHelpLine status="Under Review" tooltip="This article has not yet been accepted for publication." />

      <StatusHelpLine status="Accepted" tooltip="This article has been accepted for publication." />

      <StatusHelpLine
        status="Rejected"
        tooltip="This article has not been accepted for publication. Any previously approved funding requests will be cancelled."
      />

      <StatusHelpLine
        status="Withdrawn"
        tooltip="This article has been withdrawn. Any previously approved funding requests will be cancelled."
      />

      <StatusHelpLine status="In Production" tooltip="This article is in production." />

      <StatusHelpLine status="Published Online" tooltip="This article has been published online." />

      <StatusHelpLine status="Published in Issue" tooltip="This article has been published in an issue." />
    </div>
  );
}
