import get from 'lodash.get';
import React, { VFC, useEffect } from 'react';
import AccountsList from 'components/Consortia/AccountsList/AccountsList';
import GetReports from 'components/Consortia/GetReports';
import MoneyStats from 'components/Consortia/MoneyStats';
import RequestsStats from 'components/Consortia/RequestsStats';
import TimePeriodPicker from 'components/Consortia/TimePeriodPicker';
import { AccountContextInterface } from 'storage/AccountContext';
import ConsortiaContext from 'storage/ConsortiaContext';
import withAuthRequired from 'with/withAuthRequired';
import withConsortiaLayout from 'with/withConsortiaLayout';
import withConsortiumRequired from 'with/withConsortiumRequired';
import { compose } from 'with/withContext';
import withSelectedAccountRequired from 'with/withSelectedAccountRequired';
import './ConsortiaAccountsPage.scss';

const ConsortiaAccountsPage: VFC<{ selectedAccount: AccountContextInterface['selectedAccount'] }> = ({
  selectedAccount,
}) => {
  const {
    accountName,
    accounts,
    dealOOConfiguration,
    isLoadingAccounts,
    onAccountsListChange,
    page,
    pageSize,
    setConsortiumId,
    sorted,
  } = ConsortiaContext.useContext();

  useEffect(() => setConsortiumId(selectedAccount.consortiumId), [selectedAccount.consortiumId]);

  const renderAccounts = () => {
    return (
      <AccountsList
        accountName={accountName}
        accounts={accounts.accounts}
        currency={selectedAccount.currency}
        isLoading={isLoadingAccounts}
        onChange={onAccountsListChange}
        page={page}
        pages={accounts.pagination.pages}
        pageSize={pageSize}
        showPrice={get(dealOOConfiguration, 'showPrice', false)}
        sorted={sorted}
        total={accounts.pagination.items}
      />
    );
  };

  return (
    <>
      <h2 className="consortiaPage-totalHeading">
        Total Accounts&nbsp;
        <span className="consortiaPage-totalAmount" data-seleniumid="consortia-total-accounts">
          {!!accounts && accounts.accounts.length}
        </span>
      </h2>
      <TimePeriodPicker />
      <GetReports />

      {!!accounts && (
        <section className="consortiaPage-statsBlock mt_1em mb_1em">
          <RequestsStats ConsortiaStats={accounts.stats} />
          <MoneyStats ConsortiaStats={accounts.stats} currency={selectedAccount.currency} />
        </section>
      )}

      {!accounts ? <div>Loading...</div> : renderAccounts()}
    </>
  );
};

export default compose(
  withAuthRequired,
  withConsortiaLayout,
  withSelectedAccountRequired,
  withConsortiumRequired,
  ConsortiaAccountsPage,
);
