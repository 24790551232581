/* eslint react/no-unused-state: 0 */

import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import analytics from 'components/analytics';
import { processing } from 'decorators';
import ErrorEnum from 'model/ErrorEnum';
import { getProfileInfo, logout } from 'services/api/user';
import emitter from 'services/events/emitter';
import EventEnum from 'services/events/EventEnum';
import storage from 'storage/storage';
import withConfigRequired from 'with/withConfigRequired';
import mainWithContext from 'with/withContext';

type ProfileInfoContextInterface = {
  authError?: Error;
  isAuth: boolean;
  profileInfo?: WoaLoggableUser;
  isFetching: boolean;
  login: (returnUrl?: string) => void;
  logout: () => void;
};

const defaultValue: ProfileInfoContextInterface = {
  authError: null,
  isAuth: false,
  isFetching: true,
  login: () => {},
  logout: () => {},
};

const ProfileInfoContext = React.createContext<ProfileInfoContextInterface>(defaultValue);

function withProvider(WrappedComponent) {
  @autobind
  class ProfileInfoProviderWrapper extends PureComponent<
    { config: { loginUrl: string } },
    ProfileInfoContextInterface
  > {
    state = {
      ...defaultValue,
      login: this.login,
      logout: this.logout,
    };

    subscriptions = [];

    componentDidMount() {
      this.getProfileInfo();

      this.subscriptions.push(emitter.subscribe(EventEnum.SESSION_EXPIRED, this.onSessionExpired));
    }

    componentWillUnmount() {
      this.subscriptions.forEach(x => x());
    }

    @processing('isFetching')
    async getProfileInfo() {
      this.setState({ authError: null });
      try {
        const profileInfo = await getProfileInfo();
        analytics.setUser(profileInfo.email, profileInfo.firstName, profileInfo.lastName, profileInfo.participantId);
        this.setState({
          profileInfo,
          isAuth: true,
        });
      } catch (error) {
        if ([ErrorEnum.AUTHORIZED_USER_NOT_FOUND, ErrorEnum.UNAUTHORIZED].includes(error.name)) {
          storage.remove('CURRENT_ACCOUNT');
          await logout();
        }
        this.setState({ authError: error });
      }
    }

    login(returnUrl = window.location.href) {
      storage.remove('CURRENT_ACCOUNT');
      analytics.cleanUser();
      const url = this.props.config.loginUrl.replace(/(\?.*)?$/, `?returnUrl=${returnUrl}`);
      window.location.assign(url);
    }

    async logout() {
      await logout();
      this.login(window.location.origin);
    }

    onSessionExpired() {
      this.login();
    }

    render() {
      return (
        <ProfileInfoContext.Provider value={this.state}>
          <WrappedComponent {...this.props} />
        </ProfileInfoContext.Provider>
      );
    }
  }

  return withConfigRequired(ProfileInfoProviderWrapper);
}

export { ProfileInfoContextInterface };
export default {
  useContext: () => React.useContext(ProfileInfoContext),
  withProvider,
  withContext: mainWithContext(ProfileInfoContext),
};
