import React from 'react';
import { Navigate } from 'react-router-dom';
import NotificationConfig from 'components/NotificationConfig';
import GroupEmail from 'components/Profile/GroupEmail';
import ProfileInfo from 'components/Profile/ProfileInfo';
import Terms from 'components/Profile/Terms';
import Users from 'components/Profile/Users';
import PermissionEnum from 'model/PermissionEnum';
import { mainRoutes } from 'routes';
import AccountContext from 'storage/AccountContext';
import ProfileInfoContext from 'storage/ProfileInfoContext';
import withAuthRequired from 'with/withAuthRequired';
import { compose } from 'with/withContext';
import withMainLayout from 'with/withMainLayout';
import withSelectedAccountRequired from 'with/withSelectedAccountRequired';

function ProfilePage() {
  const { profileInfo } = ProfileInfoContext.useContext();
  const { refreshSelectedAccount, selectedAccount } = AccountContext.useContext();

  if (selectedAccount.permissions.includes(PermissionEnum.READ_ONLY_ADMIN)) {
    return <Navigate to={mainRoutes.home.getUrl()} />;
  }

  const isWoadAdmin =
    selectedAccount.permissions.includes(PermissionEnum.ADMIN) ||
    selectedAccount.permissions.includes(PermissionEnum.READ_ONLY_ADMIN);

  return (
    <>
      <div className="d-flex">
        <div className="mainColumn w_100p">
          <h3 className="bb">Account Information</h3>
          <Users profile={profileInfo} refreshWoaaccount={refreshSelectedAccount} woaaccount={selectedAccount} />

          <GroupEmail refreshWoaaccount={refreshSelectedAccount} woaaccount={selectedAccount} />

          <Terms termsDateFrom={selectedAccount.termsDateFrom} termsDateTo={selectedAccount.termsDateTo} />
        </div>

        {!isWoadAdmin && (
          <div className="widgetsColumn">
            <ProfileInfo />
          </div>
        )}
      </div>

      {!isWoadAdmin && (
        <>
          <h3 className="bb">Email Notification</h3>
          <NotificationConfig
            accountId={selectedAccount.id}
            email={profileInfo.email}
            groupContactEmail={selectedAccount.groupContactEmail}
            suppressEmail={selectedAccount.ooProcessConfiguration?.suppressEmail}
          />
        </>
      )}
    </>
  );
}

export default compose(withAuthRequired, withMainLayout, withSelectedAccountRequired, ProfilePage);
