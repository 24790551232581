/* eslint react/no-unused-state: 0 */

import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { processing } from 'decorators';
import PermissionEnum from 'model/PermissionEnum';
import * as mainApi from 'services/api/main';
import { EventEmit } from 'services/events/emitter';
import { withErrorNotification } from 'storage/NotificationsContext';
import mainWithContext from 'with/withContext';
import AccountContext from './AccountContext';

export const Emitter = {
  EVENTS_UPDATED: new EventEmit('EVENTS_UPDATED'),
};

type EventsContextInterface = {
  events: WoaEvent[];
  isClearing: boolean;
  isFetching: boolean;
  getEventsList: () => void;
  clearEventsList: () => void;
};

const defaultValue: EventsContextInterface = {
  events: [],
  isClearing: false,
  isFetching: true,
  getEventsList: () => {},
  clearEventsList: () => {},
};

const EventsContext = React.createContext<EventsContextInterface>(defaultValue);

function withProvider(WrappedComponent) {
  @autobind
  class EventsProviderWrapper extends PureComponent<
    { selectedAccount: WoaAccount & { permissions: PermissionEnum[] } },
    EventsContextInterface
  > {
    state = {
      ...defaultValue,
      getEventsList: this.getEventsList,
      clearEventsList: this.clearEventsList,
    };

    componentDidUpdate(prevProps) {
      const { selectedAccount } = this.props;

      if (selectedAccount !== prevProps.selectedAccount) {
        this.getEventsList();
      }
    }

    @processing('isFetching')
    async getEventsList() {
      const { selectedAccount } = this.props;
      const isAdmin = selectedAccount && selectedAccount.permissions.includes(PermissionEnum.ADMIN);

      if (!selectedAccount || isAdmin) {
        this.setState({ events: [] });
      } else {
        this.setState({ events: await mainApi.getEvents(selectedAccount.id) });
      }
    }

    @withErrorNotification
    @processing('isClearing')
    async clearEventsList() {
      await mainApi.clearEvents(this.props.selectedAccount.id);
      this.getEventsList();
    }

    render() {
      const { selectedAccount, ...otherProps } = this.props;

      return (
        <EventsContext.Provider value={this.state}>
          <WrappedComponent {...otherProps} />
        </EventsContext.Provider>
      );
    }
  }

  return AccountContext.withContext(['selectedAccount'])(EventsProviderWrapper);
}

export { EventsContextInterface };
export default {
  useContext: () => React.useContext(EventsContext),
  withProvider,
  withContext: mainWithContext(EventsContext),
};
