import React, { useEffect, useState } from 'react';
import AlertsWindow from 'components/AlertsWindow';
import AccountContext from 'storage/AccountContext';
import EventsContext, { Emitter as EventsEmitter } from 'storage/EventsContext';
import { Emitter as OrdersEmitter } from 'storage/OrderActionsContext';
import PendingOrdersContext from 'storage/PendingOrdersContext';

function AlertsMenu() {
  const { selectedAccount } = AccountContext.useContext();
  const { events, getEventsList } = EventsContext.useContext();
  const { pendingOrders, getPendingOrdersList, reloadPendingOrders } = PendingOrdersContext.useContext();

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const subscriptions = [
      EventsEmitter.EVENTS_UPDATED.subscribe(() => getEventsList()),
      OrdersEmitter.ORDER_WAS_UPDATED.subscribe(() => reloadPendingOrders()),
    ];

    return () => subscriptions.forEach(x => x());
  }, []);

  useEffect(() => {
    getPendingOrdersList(selectedAccount.id);
  }, [selectedAccount.id]);

  const openAlertsWindow = () => {
    setOpen(true);
    getEventsList();
    getPendingOrdersList(selectedAccount.id);
  };

  const closeAlertsWindow = () => setOpen(false);

  return (
    <>
      <button
        className="btn btn-link p_0 mainMenu_link"
        data-seleniumid="alertsMenu-alerts"
        onClick={openAlertsWindow}
        type="button"
      >
        {`Alerts (${events.length + pendingOrders.length})`}
      </button>
      {isOpen && <AlertsWindow close={closeAlertsWindow} />}
    </>
  );
}

export default AlertsMenu;
