import React from 'react';
import NotificationPopup from 'components/Notification/NotificationPopup';
import NotificationsContext from 'storage/NotificationsContext';
import './notificationPopupsListener.scss';

const NotificationListener: React.VFC = () => {
  const { notifications, closeNotify } = NotificationsContext.useContext();

  return (
    <div className="notification-container" id="notification_listener_container">
      {notifications.map(el => (
        <NotificationPopup key={el.id} notification={el} onClose={closeNotify} />
      ))}
    </div>
  );
};

export default NotificationListener;
