/* eslint react/no-unused-state: 0 */

import { autobind } from 'core-decorators';
import moment from 'moment';
import React from 'react';
import ConsortiaDatesEnum from 'const/ConsortiaDatesEnum';
import ConsortiaReportTypeEnum from 'const/ConsortiaReportTypeEnum';
import { processing } from 'decorators';
import { getAccounts, getDealOOConfiguration, createReport } from 'services/api/consortia';
import { withErrorNotification } from 'storage/NotificationsContext';
import mainWithContext from 'with/withContext';

type ConsortiaContextInterface = {
  accountName?: string;
  accounts?: {
    accounts: Array<{ account: ConsortiaAccount; stats: ConsortiaStats }>;
    pagination: Pagination;
    stats: ConsortiaStats;
  };
  dates: {
    id: ConsortiaDatesEnum;
    fromDate: string;
    toDate: string;
  };
  dealOOConfiguration?: any;

  page: number;
  pageSize: number;
  sorted?: string;

  fetchingAccountsError?: Error;
  isFetchingDealOOConfiguration: boolean;
  isLoadingAccounts: boolean;
  isRequestingReport: boolean;

  setConsortiumId: (consortiumId: number) => void;
  setDates: (id: string, fromDate: string, toDate: string) => void;
  setDatesIdWithoutReloading: (id: string) => void;
  onAccountsListChange: (sorted: string, page: number, pageSize: number, accountName?: string) => void;
  requestReport: (reportName: ConsortiaReportTypeEnum) => void;
};

const defaultValue: ConsortiaContextInterface = {
  dates: {
    id: ConsortiaDatesEnum.YTD,
    fromDate: moment().startOf('year').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  },

  page: 0,
  pageSize: 10,

  isFetchingDealOOConfiguration: false,
  isLoadingAccounts: true,
  isRequestingReport: false,

  setConsortiumId: () => {},
  setDates: () => {},
  setDatesIdWithoutReloading: () => {},
  onAccountsListChange: () => {},
  requestReport: () => {},
};

const Context = React.createContext<ConsortiaContextInterface>(defaultValue);

function withProvider(WrappedComponent) {
  @autobind
  class ContextWrapper extends React.PureComponent<any, ConsortiaContextInterface & { consortiumId?: number }> {
    state = {
      ...defaultValue,
      consortiumId: undefined,
      onAccountsListChange: this.onAccountsListChange,
      requestReport: this.requestReport,
      setConsortiumId: this.setConsortiumId,
      setDates: this.setDates,
      setDatesIdWithoutReloading: this.setDatesIdWithoutReloading,
    };

    @withErrorNotification
    @processing('isFetchingDealOOConfiguration')
    async loadDealOOConfiguration() {
      const { consortiumId } = this.state;

      this.setState({ dealOOConfiguration: await getDealOOConfiguration(consortiumId) });
    }

    @withErrorNotification
    @processing('isLoadingAccounts')
    async loadConsortiaAccounts() {
      const { accountName, consortiumId, dates, page, pageSize, sorted } = this.state;

      try {
        this.setState({
          accounts: await getAccounts(consortiumId, dates.fromDate, dates.toDate, sorted, page, pageSize, accountName),
          fetchingAccountsError: null,
        });
      } catch (error) {
        this.setState({ accounts: null, fetchingAccountsError: error });

        throw error;
      }
    }

    @withErrorNotification
    @processing('isRequestingReport')
    async requestReport(reportName: ConsortiaReportTypeEnum) {
      const { consortiumId, dates } = this.state;

      await createReport(consortiumId, reportName, dates.fromDate, dates.toDate);
    }

    setConsortiumId(consortiumId) {
      this.setState({ consortiumId, page: 0 }, () => {
        this.loadDealOOConfiguration();
        this.loadConsortiaAccounts();
      });
    }

    setDatesIdWithoutReloading(id) {
      this.setState(prevState => ({ dates: { ...prevState.dates, id } }));
    }

    setDates(id, fromDate, toDate) {
      this.setState(
        {
          dates: {
            id,
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
          },
          page: 0,
        },
        this.loadConsortiaAccounts,
      );
    }

    onAccountsListChange(sorted, page, pageSize, accountName) {
      this.setState({ sorted, page, pageSize, accountName }, this.loadConsortiaAccounts);
    }

    render() {
      return (
        <Context.Provider value={this.state}>
          <WrappedComponent {...this.props} />
        </Context.Provider>
      );
    }
  }

  return ContextWrapper;
}

export { ConsortiaContextInterface };
export default {
  useContext: () => React.useContext(Context),
  withProvider,
  withContext: mainWithContext(Context),
};
