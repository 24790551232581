import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Elements from 'components/Elements';
import ModalConfirmation from 'components/Modal/ModalConfirmation';
import { validation } from 'services/utils';
import OrdersContext from 'storage/OrdersContext';

const PERIODS = {
  allHistory: 'allHistory',
  thisYear: 'thisYear',
  lastYear: 'lastYear',
  customRange: 'customRange',
};

class RequestReportModal extends PureComponent {
  static propTypes = {
    close: PropTypes.func.isRequired,
    requestOrdersReport: PropTypes.func.isRequired,
  };

  state = {
    selectedRange: 'allHistory',
    values: {
      from: moment(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)),
      to: moment(new Date()),
    },
    validateErrors: {},
  };

  validate = (dateStart, dateEnd) => {
    const dateEndOver = () => dateStart && dateStart.isValid() && dateEnd.valueOf() > dateStart.valueOf();
    const errors = {
      from: validation.validate(dateStart, [
        [validation.notEmpty, 'Select start date'],
        [() => (dateStart || {}).isValid(), 'Entered date is Invalid'],
      ]),
      to: validation.validate(dateEnd, [
        [validation.notEmpty, 'Select start date'],
        [() => (dateEnd || {}).isValid(), 'Entered date is Invalid'],
        [dateEndOver, 'End date has to be over than Start date'],
      ]),
    };

    Object.keys(errors).forEach(key => {
      if (!errors[key]) delete errors[key];
    });

    return errors;
  };

  changeValue = (key, value) =>
    this.setState(state => {
      if (state.values[key] === value) return null;
      const { validateErrors } = state;
      delete validateErrors[key];
      return {
        values: {
          ...state.values,
          [key]: value,
        },
        validateErrors,
      };
    });

  onClickRequest = () => {
    const { values, selectedRange } = this.state;
    const { requestOrdersReport, close } = this.props;

    const thisYear = new Date().getFullYear();
    let dateFrom;
    let dateTo;

    switch (selectedRange) {
      case PERIODS.thisYear:
        dateFrom = `${thisYear}-01-01`;
        dateTo = `${thisYear}-12-31`;
        break;

      case PERIODS.lastYear:
        dateFrom = `${thisYear - 1}-01-01`;
        dateTo = `${thisYear - 1}-12-31`;
        break;

      case PERIODS.customRange: {
        const validateErrors = this.validate(values.from, values.to);
        this.setState({ validateErrors });
        if (Object.keys(validateErrors).length !== 0) return false;
        dateFrom = values.from;
        dateTo = values.to;
        break;
      }

      default:
    }

    requestOrdersReport(
      dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : undefined,
      dateTo ? moment(dateTo).format('YYYY-MM-DD') : undefined,
    );
    close();
    return true;
  };

  onChangeRange = event => this.setState({ selectedRange: event.target.value });

  onSelectDateStart = date => this.changeValue('from', date);

  onSelectDateEnd = date => this.changeValue('to', date);

  render() {
    const { close } = this.props;
    const { selectedRange, values, validateErrors } = this.state;
    const year = new Date().getFullYear();

    return (
      <ModalConfirmation
        buttonCloseClick={close}
        buttonOkClick={this.onClickRequest}
        buttonOkDisabled={!selectedRange}
        buttonOkTitle="Request"
        className="requestReportModal"
        closeEsc
        seleniumid="requestReportModal"
        title="Request Report"
      >
        <form>
          <div style={{ display: 'flex', marginTop: '0.5em' }}>
            <div className="text-right" style={{ width: '20%', paddingTop: '4px' }}>
              Period:
            </div>
            <div style={{ width: '79%', paddingLeft: '0.5em' }}>
              <select
                className="select"
                data-seleniumid="requestReportModal-selectRange"
                name="selectedRange"
                onChange={this.onChangeRange}
                value={selectedRange}
              >
                <option value={PERIODS.allHistory}>All History</option>
                <option value={PERIODS.thisYear}>{`Current Year (${year})`}</option>
                <option value={PERIODS.lastYear}>{`Previous Year (${year - 1})`}</option>
                <option value={PERIODS.customRange}>Custom Range</option>
              </select>
            </div>
          </div>

          {selectedRange === 'customRange' && (
            <>
              <div style={{ display: 'flex', marginTop: '0.5em' }}>
                <div className="text-right" style={{ width: '20%', paddingTop: '4px' }}>
                  From:
                </div>
                <div style={{ width: '79%', paddingLeft: '0.5em' }}>
                  <Elements.DateInput
                    classNameInput={cn({ 'is-error': validateErrors.from })}
                    maxLength={12}
                    name="from"
                    onSelect={this.onSelectDateStart}
                    placeholder="Select start date"
                    seleniumid="dateInputFrom"
                    value={values.from}
                  />
                  <Elements.InlineHelp className="is-error d-block">{validateErrors.from}</Elements.InlineHelp>
                </div>
              </div>

              <div style={{ display: 'flex', marginTop: '0.5em' }}>
                <div className="text-right" style={{ width: '20%', paddingTop: '4px' }}>
                  To:
                </div>
                <div style={{ width: '79%', paddingLeft: '0.5em' }}>
                  <Elements.DateInput
                    classNameInput={cn({ 'is-error': validateErrors.to })}
                    maxLength={12}
                    minDate={values.from}
                    name="to"
                    onSelect={this.onSelectDateEnd}
                    placeholder="Select end date"
                    seleniumid="dateInputTo"
                    value={values.to}
                  />
                  <Elements.InlineHelp className="is-error d-block">{validateErrors.to}</Elements.InlineHelp>
                </div>
              </div>
            </>
          )}
        </form>
      </ModalConfirmation>
    );
  }
}

export default OrdersContext.withContext(['requestOrdersReport'])(RequestReportModal);
