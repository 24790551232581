import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import LoaderLayout from 'components/Layouts/LoaderLayout';
import NotificationConfigContext from 'storage/NotificationConfigContext';
import NotificationConfigCheckboxes from './NotificationConfigCheckboxes';
import './notificationConfig.scss';

const ALERT_PUBLICATION_STATUS_CHANGED = 'ALERT_PUBLICATION_STATUS_CHANGED';
const ALERT_PUB_STAT_CHANGED_TO_IN_PRODUCTION = 'ALERT_PUB_STAT_CHANGED_TO_IN_PRODUCTION';

const codes = {
  ALERT_NEW_FUNDING_REQUEST: 'New funding requests',
  ALERT_NEW_FUNDING_REQUEST_REMINDER: 'New funding request reminders after 14 days',
  ALERT_PUBLICATION_STATUS_CHANGED: 'Publication status changes',
  ALERT_INVOICE_STATUS_CHANGED: 'Invoice status changes',
  REPORT_MONTHLY: 'Send monthly reports',
  REPORT_QUARTERLY: 'Send quarterly reports',
  ALERT_PUB_STAT_CHANGED_TO_IN_PRODUCTION: 'Under Review to In Production only',
};

const config = {
  updates: [
    'ALERT_NEW_FUNDING_REQUEST',
    'ALERT_NEW_FUNDING_REQUEST_REMINDER',
    'ALERT_PUBLICATION_STATUS_CHANGED',
    'ALERT_INVOICE_STATUS_CHANGED',
  ],
  subPubStatusUpdates: ['ALERT_PUB_STAT_CHANGED_TO_IN_PRODUCTION', 'ALERT_ALL_PUBLICATION_STATUS_CHANGES'],
  reports: ['REPORT_MONTHLY', 'REPORT_QUARTERLY'],
};

class NotificationConfig extends PureComponent {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    emailConfig: PropTypes.shape({}),
    saveNotificationConfig: PropTypes.func.isRequired,
    getNotificationConfig: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    accountId: PropTypes.number.isRequired,
    groupContactEmail: PropTypes.string,
    suppressEmail: PropTypes.bool,
  };

  static defaultProps = {
    emailConfig: undefined,
    groupContactEmail: '',
    suppressEmail: false,
  };

  state = {
    userChanges: {},
    groupChanges: {},
  };

  componentDidMount() {
    const { getNotificationConfig, email, accountId } = this.props;
    getNotificationConfig(email, accountId);
  }

  componentDidUpdate(prevProps) {
    const { accountId, email, emailConfig, getNotificationConfig } = this.props;

    if (accountId !== prevProps.accountId) {
      getNotificationConfig(email, accountId);
    }
    if (emailConfig !== prevProps.emailConfig) {
      this.onClickCancel();
    }
  }

  filterOptions = (keys, values, changes) => {
    const options = values
      .filter(x => keys.includes(x.type))
      .map(x => {
        const val = {
          type: x.type,
          value: x.value,
          title: codes[x.type] || x.type,
        };

        if ({}.hasOwnProperty.call(changes, x.type)) {
          val.newValue = changes[x.type];
        }

        return val;
      });

    return options;
  };

  onChangeUser = (key, value) => {
    this.setState(state => ({ userChanges: { ...state.userChanges, [key]: value } }));

    if (key === ALERT_PUBLICATION_STATUS_CHANGED) {
      this.setState(state => ({
        userChanges: { ...state.userChanges, [ALERT_PUB_STAT_CHANGED_TO_IN_PRODUCTION]: value },
      }));
    }
  };

  onChangeGroup = (key, value) => {
    this.setState(state => ({ groupChanges: { ...state.groupChanges, [key]: value } }));

    if (key === ALERT_PUBLICATION_STATUS_CHANGED) {
      this.setState(state => ({
        groupChanges: { ...state.groupChanges, [ALERT_PUB_STAT_CHANGED_TO_IN_PRODUCTION]: value },
      }));
    }
  };

  onClickCancel = () =>
    this.setState({
      userChanges: {},
      groupChanges: {},
    });

  onClickSave = async () => {
    const { userChanges, groupChanges } = this.state;
    const { accountId, email, saveNotificationConfig } = this.props;

    const user = Object.keys(userChanges).map(k => ({
      type: k,
      value: userChanges[k],
    }));

    const group = Object.keys(groupChanges).map(k => ({
      type: k,
      value: groupChanges[k],
    }));

    if (user.length > 0 || group.length > 0) {
      await saveNotificationConfig(email, accountId, user, group);
    } else {
      this.onClickCancel();
    }
  };

  render() {
    const { groupContactEmail, suppressEmail, isFetching, emailConfig } = this.props;

    const { userChanges, groupChanges } = this.state;
    const userUpdates = emailConfig && this.filterOptions(config.updates, emailConfig.user.setting, userChanges);
    const groupUpdates = emailConfig && this.filterOptions(config.updates, emailConfig.group.setting, groupChanges);
    const userPubStatusUpdates =
      emailConfig && this.filterOptions(config.subPubStatusUpdates, emailConfig.user.setting, userChanges);
    const groupPubStatusUpdates =
      emailConfig && this.filterOptions(config.subPubStatusUpdates, emailConfig.group.setting, groupChanges);
    const userReports = emailConfig && this.filterOptions(config.reports, emailConfig.user.setting, userChanges);
    const groupReports = emailConfig && this.filterOptions(config.reports, emailConfig.group.setting, groupChanges);

    return (
      <LoaderLayout className="notificationConfig" isLoading={isFetching}>
        <h4 className="notificationConfig-sectionTitle">Updates</h4>
        <div className="notificationConfig-section">
          <div className="notificationConfig-group">
            <div className="notificationConfig-groupTitle">Sign up to receive new updates</div>
            {userUpdates && (
              <NotificationConfigCheckboxes
                onChange={this.onChangeUser}
                options={userUpdates}
                pubStatusUpdates={userPubStatusUpdates}
                seleniumid="userNotification"
                suppressEmail={suppressEmail}
              />
            )}
          </div>
          <div className="notificationConfig-group">
            {groupContactEmail && (
              <>
                <div className="notificationConfig-groupTitle">{`Have update sent to group contact email (${groupContactEmail})`}</div>
                {userUpdates && (
                  <NotificationConfigCheckboxes
                    onChange={this.onChangeGroup}
                    options={groupUpdates}
                    pubStatusUpdates={groupPubStatusUpdates}
                    seleniumid="accountNotification"
                    suppressEmail={suppressEmail}
                  />
                )}
              </>
            )}
          </div>
        </div>

        <h4 className="notificationConfig-sectionTitle">Reports</h4>
        <div className="notificationConfig-section">
          <div className="notificationConfig-group">
            <div className="notificationConfig-groupTitle">Sign up to receive update</div>
            {userReports && (
              <NotificationConfigCheckboxes
                onChange={this.onChangeUser}
                options={userReports}
                seleniumid="userNotification"
              />
            )}
          </div>
          <div className="notificationConfig-group">
            {groupContactEmail && (
              <>
                <div className="notificationConfig-groupTitle">{`Have update sent to group contact email (${groupContactEmail})`}</div>
                {groupReports && (
                  <NotificationConfigCheckboxes
                    onChange={this.onChangeGroup}
                    options={groupReports}
                    seleniumid="accountNotification"
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className="p_10">
          <button className="btn btn-secondary btn-sm mr_10" onClick={this.onClickSave} type="button">
            Save changes
          </button>
          <button className="btn btn-light btn-sm" onClick={this.onClickCancel} type="button">
            Cancel
          </button>
        </div>
      </LoaderLayout>
    );
  }
}

export default NotificationConfigContext.withContext([
  'emailConfig',
  'isFetching',
  'getNotificationConfig',
  'saveNotificationConfig',
])(NotificationConfig);
