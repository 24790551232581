import cn from 'classnames';
import get from 'lodash.get';
import React, { useEffect, useState } from 'react';
import Elements from 'components/Elements';
import LoaderLayout from 'components/Layouts/LoaderLayout';
import DenyOrderModal from 'components/Modal/DenyOrderModal';
import AdminNoteList from 'components/OrderDetailsModal/AdminNoteList';
import AffiliationsList from 'components/OrderDetailsModal/AffiliationsList';
import ResearchProgramList from 'components/OrderDetailsModal/ResearchProgramList';
import UserInternalNoteList from 'components/OrderDetailsModal/UserInternalNoteList';
import UserNoteList from 'components/OrderDetailsModal/UserNoteList';
import licenseStatus from 'const/licenseStatus';
import OrderStatusEnum from 'const/OrderStatusEnum';
import PermissionEnum from 'model/PermissionEnum';
import { getOrderDocumentUrlSync } from 'services/api/main';
import { currencyFormat, dateFormat } from 'services/utils';
import AccountContext from 'storage/AccountContext';
import OrderActionsContext from 'storage/OrderActionsContext';
import OrderDetailsContext from 'storage/OrderDetailsContext';
import { canBeAutoApproved } from 'utils/orders';

const publishedStatuses = ['Accepted', 'Published in Early View', 'Published in Issue'];

const DOILink = ({ doi, publicationStatus }) => {
  if (doi) {
    return publishedStatuses.includes(publicationStatus) ? (
      <a href={`https://doi.org/${doi}`} rel="noreferrer" target="_blank">
        {doi}
      </a>
    ) : (
      doi
    );
  }
  return null;
};

type Props = {
  close: () => void;
  orderId: number;
  showPrice: boolean;
  useOrderAccountShowPrice: boolean;
};

function OrderDetailsView({ close, orderId, showPrice, useOrderAccountShowPrice }: Props) {
  const { selectedAccount } = AccountContext.useContext();
  const { error: errorDetails, isFetching, getOrderDetails, orderDetails: order } = OrderDetailsContext.useContext();
  const { approveOrder, denyOrder, isFetching: isActionFetchingMap } = OrderActionsContext.useContext();
  const isActionFetching = isActionFetchingMap[order?.id] ?? false;

  const [denyOrderId, setDenyOrderId] = useState<string>();

  useEffect(() => {
    getOrderDetails(orderId);
  }, [getOrderDetails, orderId]);

  const onClickApprove = async () => {
    await approveOrder(order.id);
    close();
  };

  const onClickDeny = () => setDenyOrderId(order.id);

  const onSubmitDeny = async reason => {
    await denyOrder(order.id, { reason });
    close();
  };

  const closeDenyOrderModal = () => setDenyOrderId(null);

  const onClickPrint = () => {
    window.open(getOrderDocumentUrlSync(order.id)).focus();
  };

  const renderButtons = () => {
    const canApprove = OrderStatusEnum.PENDING === order.status && !canBeAutoApproved(order, order.woaaccount);
    const disabled = isFetching || isActionFetching;
    const isReadOnlyAdmin = selectedAccount.permissions.includes(PermissionEnum.READ_ONLY_ADMIN);

    return (
      <>
        {!isReadOnlyAdmin && canApprove && (
          <>
            <Elements.ConfirmButton
              classNameParent="nobr mr_10"
              disabled={disabled}
              onClick={onClickApprove}
              seleniumid="orderDetailsView-approveOrder"
              title="Do you want approve?"
            >
              Approve
            </Elements.ConfirmButton>

            <button
              className="btn btn-secondary btn-sm mr_10"
              data-seleniumid="orderDetailsView-denyOrder"
              disabled={disabled}
              onClick={onClickDeny}
              title="Do you want deny?"
              type="button"
            >
              Deny
            </button>
          </>
        )}

        {OrderStatusEnum.APPROVED === order.status && (
          <button
            className="btn btn-secondary btn-sm printReport"
            data-seleniumid="orderDetailsView-printReport"
            disabled={disabled}
            onClick={onClickPrint}
            type="button"
          >
            Print
          </button>
        )}
      </>
    );
  };

  const woaarticle = get(order, 'woaarticle');
  const woaaccount = get(order, 'woaaccount');
  const showPrice2 = useOrderAccountShowPrice ? woaaccount && woaaccount.showPrice : showPrice;

  const isUserNotesEditable = existingOrder => {
    const allowedStatuses = [OrderStatusEnum.PENDING, OrderStatusEnum.APPROVED];

    return (
      allowedStatuses.indexOf(existingOrder.status) !== -1 &&
      !selectedAccount.permissions.includes(PermissionEnum.ADMIN) &&
      !selectedAccount.permissions.includes(PermissionEnum.READ_ONLY_ADMIN)
    );
  };

  const isUserInternalNotesEditable = existingOrder => {
    const allowedStatuses = [
      OrderStatusEnum.PENDING,
      OrderStatusEnum.APPROVED,
      OrderStatusEnum.DENIED,
      OrderStatusEnum.CANCELLED,
    ];

    return (
      allowedStatuses.indexOf(existingOrder.status) !== -1 &&
      !selectedAccount.permissions.includes(PermissionEnum.ADMIN) &&
      !selectedAccount.permissions.includes(PermissionEnum.READ_ONLY_ADMIN)
    );
  };

  return (
    <>
      {!!denyOrderId && <DenyOrderModal onCancel={closeDenyOrderModal} onDeny={onSubmitDeny} />}
      <LoaderLayout className="orderDetailsView" error={errorDetails} isLoading={isFetching} isReplace={false}>
        {order && !errorDetails && (
          <>
            <table className="orderDetailsTable">
              <tbody>
                <tr>
                  <th>Request Date</th>
                  <th>WOA Account Name</th>
                  <th>Amount</th>
                  <th style={{ width: '155px' }} />
                </tr>
                <tr>
                  <td data-seleniumid="orderDetailsView-createdTs">{dateFormat(order.createdTs)}</td>
                  <td data-seleniumid="orderDetailsView-woaAccountName">{order.woaaccount.name}</td>
                  <td data-seleniumid="orderDetailsView-allocatedAmount">
                    {showPrice2 ? currencyFormat(order.allocatedAmount, order.woaaccount.currency) : 'N/A'}
                  </td>
                  <td className={cn({ 'is-loading': isActionFetching })}>{renderButtons()}</td>
                </tr>
                <tr>
                  <th>Author Name</th>
                  <th colSpan={2}>Journal</th>
                  <th />
                </tr>
                <tr>
                  <td data-seleniumid="orderDetailsView-author">{order.author}</td>
                  <td colSpan={2} data-seleniumid="orderDetailsView-journalName">
                    {order.journalName}
                  </td>
                  <td />
                </tr>
              </tbody>
            </table>
            <hr className="hr" />
            <table className="orderDetailsTable">
              <tbody>
                <tr>
                  <th>DOI</th>
                  <th colSpan={3}>Article Name</th>
                </tr>
                <tr>
                  <td data-seleniumid="orderDetailsView-doi">
                    <DOILink doi={woaarticle.doi} publicationStatus={woaarticle.publicationStatus} />
                  </td>
                  <td colSpan={3} data-seleniumid="orderDetailsView-name">
                    {woaarticle.name}
                  </td>
                </tr>

                <tr>
                  <th data-seleniumid="orderDetailsView-classificationTitle">Confirmed Article Classification</th>
                  <th>OO Unique Id</th>
                  <th>Manuscript</th>
                  <th>Journal Revenue Model</th>
                </tr>
                <tr>
                  <td data-seleniumid="orderDetailsView-classification">{woaarticle.classification}</td>
                  <td data-seleniumid="orderDetailsView-ooUniqueId">{order.ooUniqueId}</td>
                  <td data-seleniumid="orderDetailsView-manuscriptId">{woaarticle.manuscriptId}</td>
                  <td data-seleniumid="orderDetailsView-journalRevenueModel">{woaarticle.journalRevenueModel}</td>
                </tr>

                <tr>
                  <th>Request Status</th>
                  <th>Status Reason</th>
                  <th>Updated By</th>
                  <th>Updated Date</th>
                </tr>
                <tr>
                  <td data-seleniumid="orderDetailsView-status">{order.status}</td>
                  <td data-seleniumid="orderDetailsView-statusReason">{order.statusReason}</td>
                  <td data-seleniumid="orderDetailsView-statusUpdatedBy">{order.statusUpdatedBy}</td>
                  <td data-seleniumid="orderDetailsView-statusUpdateTs">{dateFormat(order.statusUpdateTs)}</td>
                </tr>

                <tr>
                  <th>Article ID</th>
                  <th>Publication Status</th>
                  <th>Last Status Update</th>
                  <th data-seleniumid="orderDetailsView-submittingArticleTypeTitle">Article Type at Submission</th>
                </tr>

                <tr>
                  <td data-seleniumid="orderDetailsView-aid">{woaarticle.aid}</td>
                  <td data-seleniumid="orderDetailsView-publicationStatus">{woaarticle.publicationStatus}</td>
                  <td data-seleniumid="orderDetailsView-publicationStatusDate">
                    {dateFormat(woaarticle.publicationStatusDate)}
                  </td>
                  <td data-seleniumid="orderDetailsView-submittingArticleType">{order.submittingArticleType}</td>
                </tr>

                <tr>
                  <th>Acceptance Date</th>
                  <th>Production Received Date</th>
                  <th>EV Published Date</th>
                  <th>Published In Issue Date</th>
                </tr>
                <tr>
                  <td data-seleniumid="orderDetailsView-acceptanceDate">{dateFormat(woaarticle.acceptanceDate)}</td>
                  <td data-seleniumid="orderDetailsView-productionReceivedDate">
                    {dateFormat(woaarticle.productionReceivedDate)}
                  </td>
                  <td data-seleniumid="orderDetailsView-evPublishedDate">{dateFormat(woaarticle.evPublishedDate)}</td>
                  <td data-seleniumid="orderDetailsView-publishedInIssueDate">
                    {dateFormat(woaarticle.publishedInIssueDate)}
                  </td>
                </tr>

                <tr>
                  <th colSpan={4}>All Authors</th>
                </tr>
                <tr>
                  <td
                    colSpan={4}
                    dangerouslySetInnerHTML={{ __html: woaarticle.allAuthors || 'N/A' }}
                    // allAuthors can contain <b> tag
                    // eslint-disable-next-line react/no-danger
                    data-seleniumid="orderDetailsView-allAuthors"
                  />
                </tr>
                {order.journalRevenueModel === 'Open Access' && (
                  <>
                    <tr>
                      <th colSpan={2}>Corresponding Author at Submission</th>
                      <th colSpan={2}>Corresponding Author Email at Submission</th>
                    </tr>
                    <tr>
                      <td colSpan={2} data-seleniumid="orderDetailsView-submittingAuthor">
                        {order.submittingAuthor}
                      </td>
                      <td colSpan={2} data-seleniumid="orderDetailsView-submittingAuthorEmail">
                        {order.submittingAuthorEmail}
                      </td>
                    </tr>
                    <tr>
                      <th colSpan={4}>Corresponding Author Institution at Submission</th>
                    </tr>
                    <tr>
                      <td colSpan={4} data-seleniumid="orderDetailsView-submittingAuthorInstitution">
                        {order.submittingAuthorInstitution}
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <th colSpan={4}>Confirmed Research Funders</th>
                </tr>
                <tr>
                  <td colSpan={4} data-seleniumid="orderDetailsView-researchPrograms">
                    {woaarticle.researchPrograms.length > 0 ? (
                      <ResearchProgramList researchPrograms={woaarticle.researchPrograms} />
                    ) : (
                      'N/A'
                    )}
                  </td>
                </tr>
                <tr>
                  <th colSpan={4}>Research Funders at Submission</th>
                </tr>
                <tr>
                  <td colSpan={4} data-seleniumid="orderDetailsView-submittingResearchPrograms">
                    {order.requestResearchPrograms && order.requestResearchPrograms.length > 0 ? (
                      <ResearchProgramList researchPrograms={order.requestResearchPrograms} />
                    ) : (
                      'N/A'
                    )}
                  </td>
                </tr>
                <tr>
                  <th colSpan={4}>Affiliations</th>
                </tr>
                <tr>
                  <td colSpan={4} data-seleniumid="orderDetailsView-affiliations">
                    {woaarticle.correspondingAuthorAffiliations.length > 0 ? (
                      <AffiliationsList affiliations={woaarticle.correspondingAuthorAffiliations} />
                    ) : (
                      'N/A'
                    )}
                  </td>
                </tr>
                <tr>
                  <th>License Type</th>
                  <th>License Sub-Type</th>
                  <th>License Status</th>
                  <th>License Signed Date</th>
                </tr>
                <tr>
                  <td data-seleniumid="orderDetailsView-licenseType">{woaarticle.licenseType}</td>
                  <td data-seleniumid="orderDetailsView-licenseSubType">{woaarticle.licenseSubType}</td>
                  <td data-seleniumid="orderDetailsView-licenseStatus">{licenseStatus.l(woaarticle.licenseStatus)}</td>
                  <td data-seleniumid="orderDetailsView-licenseSignedTs">{dateFormat(woaarticle.licenseSignedTs)}</td>
                </tr>
                <tr>
                  <th>Article Price</th>
                  <th>Discount</th>
                  <th colSpan={2}>Amount</th>
                </tr>
                <tr>
                  <td data-seleniumid="orderDetailsView-articlePrice">
                    {showPrice2 && order.articlePrice != null
                      ? currencyFormat(order.articlePrice, order.woaaccount.currency)
                      : 'N/A'}
                  </td>
                  <td data-seleniumid="orderDetailsView-articleDiscount">
                    {showPrice2 ? currencyFormat(order.articleDiscount, order.woaaccount.currency) : 'N/A'}
                  </td>
                  <td colSpan={2} data-seleniumid="orderDetailsView-allocatedAmount">
                    {showPrice2 ? currencyFormat(order.allocatedAmount, order.woaaccount.currency) : 'N/A'}
                  </td>
                </tr>
              </tbody>
            </table>

            <AdminNoteList notes={woaarticle.woanotesAdmin} />

            <UserNoteList
              articleId={order.articleId}
              isEditable={isUserNotesEditable(order)}
              notes={woaarticle.woanotesUser}
            />
            <UserInternalNoteList
              accountId={order.woaaccount.id}
              articleId={order.articleId}
              isEditable={isUserInternalNotesEditable(order)}
              notes={woaarticle.woanotesUserInternal}
            />
          </>
        )}
      </LoaderLayout>
    </>
  );
}

export default OrderDetailsView;
