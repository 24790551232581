/* eslint react/no-unused-state: 0 */

import React, { PureComponent } from 'react';
import { guidCustom } from 'services/utils';
import mainWithContext from 'with/withContext';

type NotificationContextInterface = {
  notifications: Array<{
    id: string;
    content: string;
    duration: number;
    messageType: 'error' | 'info';
    onClose?: () => void;
  }>;
  closeNotify: (id: string) => void;
  addNotify: (params: {
    type?: 'error' | 'info';
    content: string;
    id?: string;
    duration?: number;
    onClose?: () => void;
  }) => void;
  addInfoNotify: (content: string) => void;
  addErrorNotify: (content: string) => void;
};

const defaultValue: NotificationContextInterface = {
  notifications: [],
  closeNotify: () => {},
  addNotify: () => {},
  addInfoNotify: () => {},
  addErrorNotify: () => {},
};

const notify: {
  error: (content: string) => void;
  info: (content: string) => void;
} = {
  error: () => {},
  info: () => {},
};

const NotificationsContext = React.createContext<NotificationContextInterface>(defaultValue);

function withProvider(WrappedComponent) {
  class NotificationsProviderWrapper extends PureComponent<any, NotificationContextInterface> {
    constructor(props) {
      super(props);
      // eslint-disable-next-line react/state-in-constructor
      this.state = {
        ...defaultValue,
        closeNotify: this.closeNotify,
        addNotify: this.addNotify,
        addInfoNotify: this.addInfoNotify,
        addErrorNotify: this.addErrorNotify,
      };

      notify.error = this.addErrorNotify;
      notify.info = this.addInfoNotify;
    }

    addNotify = ({
      type = 'info',
      content,
      id = guidCustom(4),
      duration = 0,
      onClose,
    }: {
      type?: 'error' | 'info';
      content: string;
      id?: string;
      duration?: number;
      onClose?: () => void;
    }) => {
      const { notifications } = this.state;
      const newNotify = {
        messageType: type,
        content,
        id: id || guidCustom(4),
        duration,
        onClose,
      };
      this.setState({
        notifications: [...notifications, newNotify],
      });
    };

    addInfoNotify = content => this.addNotify({ content, type: 'info', duration: 10000 });

    addErrorNotify = content => this.addNotify({ content, type: 'error' });

    closeNotify = id => {
      if (!id) {
        this.setState({ notifications: [] });
      } else {
        const { notifications } = this.state;
        const n = notifications.findIndex(el => el.id === id);
        if (n >= 0) {
          notifications.splice(n, 1);
          this.setState({
            notifications: [...notifications],
          });
        }
      }
    };

    render() {
      return (
        <NotificationsContext.Provider value={this.state}>
          <WrappedComponent {...this.props} />
        </NotificationsContext.Provider>
      );
    }
  }

  return NotificationsProviderWrapper;
}

function withErrorNotification(...args) {
  function wrapper(fn) {
    return async function (...args2) {
      try {
        return await fn.call(this, ...args2);
      } catch (error) {
        notify.error(error.message);
        throw error;
      }
    };
  }

  if (args.length === 1) {
    const fn = args[0];

    return wrapper(fn);
  }

  const target = args[0];
  const key = args[1];
  const descriptor = args[2];

  if (!descriptor) {
    // descriptor is not passed in ts code
    const fn = target[key];

    Object.defineProperty(target, key, { value: wrapper(fn) });

    return undefined;
  }

  const fn = descriptor.value;

  return {
    ...descriptor,
    value: wrapper(fn),
  };
}

export { withErrorNotification, notify, NotificationContextInterface };
export default {
  useContext: () => React.useContext(NotificationsContext),
  withProvider,
  withContext: mainWithContext(NotificationsContext),
  notify,
};
