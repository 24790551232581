import React from 'react';

type Props = {
  researchPrograms?: ResearchProgram[];
};

export default class ResearchProgramList extends React.PureComponent<Props> {
  static defaultProps = {
    researchPrograms: [],
  };

  renderResearchProgram(researchProgram) {
    let details = '';

    if (researchProgram.grants.length > 0) {
      details = ` (GrantNumber(s): ${researchProgram.grants.join(', ')}`;

      if (researchProgram.recipients && researchProgram.recipients.length > 0) {
        details += `; Grant recipient(s): ${researchProgram.recipients.join(', ')}`;
      }

      details += ')';
    }

    return (
      <li key={researchProgram.researchFunder.name} className="researchProgramList-program">
        {researchProgram.researchFunder.name}
        {details}
      </li>
    );
  }

  render() {
    return (
      <ul className="researchProgramList">{this.props.researchPrograms.map(x => this.renderResearchProgram(x))}</ul>
    );
  }
}
