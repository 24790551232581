import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './SearchBanner.scss';

function SearchBanner({ buttonSeleniumId, className, isVisible, labelSeleniumId, onClear, text }) {
  return isVisible ? (
    <div className={cn('SearchBanner', className)}>
      <div className="SearchBanner-Text" data-seleniumid={labelSeleniumId}>
        {text}
      </div>
      <button
        className="btn btn-light SearchBanner-Button"
        data-seleniumid={buttonSeleniumId}
        onClick={onClear}
        type="button"
      >
        Clear Search
      </button>
    </div>
  ) : null;
}

SearchBanner.propTypes = {
  buttonSeleniumId: PropTypes.string,
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  labelSeleniumId: PropTypes.string,
  onClear: PropTypes.func.isRequired,
  text: PropTypes.string,
};

SearchBanner.defaultProps = {
  className: '',
  isVisible: false,
  buttonSeleniumId: '',
  labelSeleniumId: '',
  text: '',
};

export default SearchBanner;
