import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './assets/css/index.scss';
import '@wiley/fix-google-translate';

import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundary';
import DataLayout from 'components/Layouts/DataLayout';
import ajaxListener from 'qa/ajax-listener';

ajaxListener.run();

const renderApp = () =>
  render(
    <ErrorBoundary>
      <BrowserRouter>
        <Routes>
          <Route element={<DataLayout />} path="*" />
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>,
    document.getElementById('app_root'),
  );

renderApp();
