import copy from 'copy-to-clipboard';
import React from 'react';
import { getBackendVersion } from 'services/api/main';
import withMainLayout from 'with/withMainLayout';

class VersionPage extends React.PureComponent {
  state = { versions: [] };

  async componentDidMount() {
    const versions = [{ type: 'UI', buildVersion: APP_VERSION.buildVersion }];

    try {
      versions.push({ type: 'Services', buildVersion: await getBackendVersion() });
    } catch (e) {
      versions.push({ type: 'Services', buildVersion: 'can not fetch' });
    }

    this.setState({ versions });
  }

  onCopyClick = () => {
    const markdownVersion = this.state.versions.reduce(
      (accumulator, { type, buildVersion }) => `${accumulator}|${type}|${buildVersion}|\n`,
      '||Component||Build version||\n',
    );

    copy(markdownVersion);
  };

  render() {
    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th>Component</th>
              <th>Build version</th>
            </tr>
          </thead>
          <tbody>
            {this.state.versions.map(({ type, buildVersion }) => (
              <tr key={type}>
                <td>{type}</td>
                <td>{buildVersion}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className="btn btn-secondary" onClick={this.onCopyClick} style={{ marginTop: '0.5em' }} type="button">
          Copy to clipboard
        </button>
      </>
    );
  }
}

export default withMainLayout(VersionPage);
