import React from 'react';
import EventsList from 'components/EventsList';
import LoaderLayout from 'components/Layouts/LoaderLayout';
import PendingOrdersList from 'components/PendingOrdersList';
import EventsContext from 'storage/EventsContext';
import PendingOrdersContext from 'storage/PendingOrdersContext';
import Elements from '../Elements';

function AlertWindowContent() {
  const { isFetching: isFetchingOrders, pendingOrders } = PendingOrdersContext.useContext();
  const {
    clearEventsList,
    events,
    isClearing: isClearingEvents,
    isFetching: isFetchingEvents,
  } = EventsContext.useContext();

  return (
    <LoaderLayout isLoading={isFetchingEvents || isFetchingOrders}>
      <div>
        <h4>{`Pending Requests (${pendingOrders.length})`}</h4>
        <div>
          {pendingOrders.length === 0 ? 'You have no pending requests' : <PendingOrdersList orders={pendingOrders} />}
        </div>
      </div>

      <section>
        <header className="cf" style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
          <h4 style={{ margin: '0 10px 0 0' }}>
            {`Publication Status Changes Since Your Last Visit (${events.length})`}
          </h4>
          {events.length > 0 && (
            <Elements.ConfirmButton
              disabled={isFetchingEvents || isClearingEvents}
              onClick={clearEventsList}
              title="Do you want to clear recent changes?"
            >
              Clear
            </Elements.ConfirmButton>
          )}
        </header>
        {events.length === 0 ? <div>You have no Status Changes</div> : <EventsList events={events} pageSize={0} />}
      </section>
    </LoaderLayout>
  );
}

export default AlertWindowContent;
