import cn from 'classnames';
import React from 'react';
import './Widget.scss';

const Widget: React.FC<{ children: React.ReactNode; className?: string; seleniumid?: string; title: string }> = ({
  children,
  className,
  seleniumid,
  title,
}) => {
  return (
    <section className={cn('widget', className)} data-seleniumid={seleniumid}>
      <h3 className="widget-title">{title}</h3>
      <div className="widget-body" style={{ marginTop: '0.5em' }}>
        {children}
      </div>
    </section>
  );
};

Widget.defaultProps = {
  className: '',
  seleniumid: 'widget',
};

export default React.memo(Widget);
