import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import React from 'react';
import Elements from 'components/Elements';
import EditGroupEmailModal from 'components/Profile/EditGroupEmailModal';
import { removeGroupEmail, saveGroupEmail } from 'services/api/account';
import NotificationsContext, { withErrorNotification } from 'storage/NotificationsContext';

export default class GroupEmail extends React.PureComponent {
  static propTypes = {
    refreshWoaaccount: PropTypes.func.isRequired,
    woaaccount: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
  };

  state = {
    editGroupEmailModal: false,
  };

  onSave = withErrorNotification(async email => {
    await saveGroupEmail(this.props.woaaccount.id, email);
    const message = 'Group contact email has been updated.';
    NotificationsContext.notify.info(message);

    this.props.refreshWoaaccount();
    this.close();
  });

  @autobind
  onEditGroupEmail() {
    this.open();
  }

  @autobind
  @withErrorNotification
  async onRemoveGroupEmail() {
    await removeGroupEmail(this.props.woaaccount.id);
    await this.props.refreshWoaaccount();

    const message = 'Group contact email has been removed.';
    NotificationsContext.notify.info(message);
  }

  open = () => this.setState({ editGroupEmailModal: true });

  close = () => this.setState({ editGroupEmailModal: false });

  render() {
    const { woaaccount } = this.props;
    const { editGroupEmailModal } = this.state;

    return (
      <>
        <table className="table mb_2em ta_l" data-seleniumid="groupEmail" style={{ marginTop: '2em' }}>
          <colgroup>
            <col style={{ width: '25%' }} />
            <col style={{ width: '75%' }} />
          </colgroup>

          <thead>
            <tr className="table-sectionTitle">
              <td className="fs_110p bold">Group Contact Email</td>
              <td>Add a group contact email if you&apos;d like email alerts sent to a group of email list</td>
            </tr>
            <tr>
              <th colSpan="2">Email Address</th>
            </tr>
          </thead>

          <tbody>
            {woaaccount.groupContactEmail && (
              <tr>
                <td data-seleniumid="groupEmail-email">{woaaccount.groupContactEmail}</td>
                <td>
                  <button
                    className="btn btn-light btn-sm mr_10"
                    data-seleniumid="groupEmail-edit"
                    onClick={this.onEditGroupEmail}
                    type="button"
                  >
                    Edit
                  </button>
                  <Elements.ConfirmButton
                    onClick={this.onRemoveGroupEmail}
                    seleniumid="groupEmail-remove"
                    title="Do you want to remove?"
                  >
                    Remove
                  </Elements.ConfirmButton>
                </td>
              </tr>
            )}

            {!woaaccount.groupContactEmail && (
              <tr>
                <td colSpan="2">
                  <button
                    className="btn btn-secondary btn-sm"
                    data-seleniumid="groupEmail-add"
                    onClick={this.onEditGroupEmail}
                    type="button"
                  >
                    Add Contact Email
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {editGroupEmailModal && (
          <EditGroupEmailModal email={woaaccount.groupContactEmail} onCancel={this.close} onSave={this.onSave} />
        )}
      </>
    );
  }
}
