import React, { PureComponent } from 'react';
import ModalInformation from 'components/Modal/ModalInformation';
import OrderDetailsView from 'components/OrderDetailsModal/OrderDetailsView';
import './orderDetailsModal.scss';

type Props = {
  close: () => void;
  orderId: number;
  showPrice?: boolean;
  useOrderAccountShowPrice?: boolean;
};

class OrderDetailsModal extends PureComponent<Props> {
  static defaultProps = {
    showPrice: false,
    useOrderAccountShowPrice: false,
  };

  render() {
    const { close, orderId, showPrice, useOrderAccountShowPrice } = this.props;

    return (
      <ModalInformation
        buttonCloseClick={close}
        className="orderDetailsModal"
        orderId={orderId}
        seleniumid="orderDetailsModal"
        title="Order Details View"
      >
        <OrderDetailsView
          close={close}
          orderId={orderId}
          showPrice={showPrice}
          useOrderAccountShowPrice={useOrderAccountShowPrice}
        />
      </ModalInformation>
    );
  }
}

export default OrderDetailsModal;
