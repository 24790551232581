import PropTypes from 'prop-types';
import React from 'react';

const dateMapping = (date = '') => (String(date).startsWith('2099') ? 'Lifetime' : date);

export default class Terms extends React.PureComponent {
  static propTypes = {
    termsDateFrom: PropTypes.string.isRequired,
    termsDateTo: PropTypes.string.isRequired,
  };

  render() {
    const { termsDateFrom, termsDateTo } = this.props;

    return (
      <table className="table mb_2em ta_l">
        <colgroup>
          <col style={{ width: '25%' }} />
          <col style={{ width: '75%' }} />
        </colgroup>

        <thead>
          <tr className="table-sectionTitle">
            <td className="fs_110p bold" colSpan="2">
              Contract Information
            </td>
          </tr>
          <tr>
            <th>Date of Contract</th>
            <th>Expiration of Contract</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{termsDateFrom}</td>
            <td>{dateMapping(termsDateTo)}</td>
          </tr>
        </tbody>
      </table>
    );
  }
}
