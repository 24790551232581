import React from 'react';
import NoteIcon from 'components/NoteIcon';

const AccountSummaryData: React.VFC<{
  title: string;
  value: string | number;
  note: string;
}> = ({ title, value, note }) => {
  return (
    <div className="accountSummary-data">
      <div className="accountSummary-dataTitle">
        <NoteIcon>{note}</NoteIcon>
        {title}
      </div>

      <div className="accountSummary-dataValue">{value}</div>
    </div>
  );
};

export default AccountSummaryData;
