import emitter from 'services/events/emitter';
import EventEnum from 'services/events/EventEnum';

const TIMEOUT = 400;
const INDICATOR_ID = 'selenium-ajax-indicator';
const AJAX_ON_CLASS = 'ajax-on';

class AjaxListener {
  _subscriptions = [];

  _numOfRequests = 0;

  _timeout = -1;

  run() {
    this._subscriptions.push(
      emitter.subscribe(EventEnum.API_REQUEST_STARTED, () => this._increment()),
      emitter.subscribe(EventEnum.API_REQUEST_ENDED, () => this._decrement()),
    );
  }

  stop() {
    this._subscriptions.forEach(x => x());
    this._subscriptions = [];
    this._numOfRequests = 0;
    clearTimeout(this._timeout);
    document.getElementById(INDICATOR_ID).classList.remove(AJAX_ON_CLASS);
  }

  _increment() {
    this._numOfRequests += 1;
    this._checkIndicator();
  }

  _decrement() {
    this._numOfRequests -= 1;
    this._checkIndicator();
  }

  _checkIndicator() {
    if (this._numOfRequests < 1) {
      setTimeout(() => document.getElementById(INDICATOR_ID).classList.remove(AJAX_ON_CLASS), TIMEOUT);
    } else {
      clearTimeout(this._timeout);
      document.getElementById(INDICATOR_ID).classList.add(AJAX_ON_CLASS);
    }
  }
}

export default new AjaxListener();
