import React from 'react';

type Props = {
  affiliations?: Array<{ id: string; name: string }>;
};

export default class AffiliationsList extends React.PureComponent<Props> {
  static defaultProps = {
    affiliations: [],
  };

  render() {
    return (
      <ul className="affiliationsList">
        {this.props.affiliations.map(affiliation => (
          <li key={affiliation.id} className="affiliationsList-affiliation">
            {affiliation.name}
          </li>
        ))}
      </ul>
    );
  }
}
