import React from 'react';
import NoteIcon from 'components/NoteIcon';

type Props = {
  status: string;
  tooltip: React.ReactNode;
};

function StatusHelpLine({ tooltip, status }: Props) {
  return (
    <div style={{ display: 'flex', marginTop: '0.5em' }}>
      <NoteIcon>{tooltip}</NoteIcon>
      <span>{status}</span>
    </div>
  );
}

export default StatusHelpLine;
