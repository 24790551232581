import get from 'lodash.get';
import React from 'react';
import Widget from 'components/Widget';
import { currencyFormat } from 'services/utils';
import AccountContext from 'storage/AccountContext';

const InvoiceRequests: React.VFC = () => {
  const { selectedAccount } = AccountContext.useContext();

  const invoices = React.useMemo(
    () => selectedAccount?.woaaccountInvoices?.filter?.(x => !x.cashAdvDate) ?? [],
    [selectedAccount],
  );

  if (!selectedAccount.showPrice) {
    return null;
  }

  const renderRow = (invoice, currency) => {
    return (
      <tr key={invoice.id}>
        <td>{invoice.createdBy}</td>
        <td>{currencyFormat(invoice.cashAdvAmount, currency)}</td>
      </tr>
    );
  };

  if (get(selectedAccount, 'advanceCash') === true && invoices.length > 0) {
    return (
      <Widget className="invoiceRequests" seleniumid="invoiceRequests" title="Invoice Requests">
        <table>
          <thead>
            <tr>
              <th>Created By:</th>
              <th>Amount:</th>
            </tr>
          </thead>
          <tbody>{invoices.map(x => renderRow(x, selectedAccount.currency))}</tbody>
        </table>
      </Widget>
    );
  }

  return null;
};

export default InvoiceRequests;
