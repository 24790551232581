import React from 'react';

type PropNameMapping<T> =
  | string
  | Array<keyof T>
  | ((state: T, props?: any) => { [key: string]: any })
  | { [key in keyof T]?: string };

type MappedProps<T extends PropNameMapping<any>, Props> = T extends Array<infer ProvidedPropsEnum>
  ? // @ts-ignore
    Omit<Props, ProvidedPropsEnum>
  : T extends (state?: any) => infer ProvidedPropsObject
  ? Omit<Props, keyof ProvidedPropsObject>
  : Props;

export function compose(...args) {
  return args.slice(0, -1).reduceRight((Component, hoc) => hoc(Component), args[args.length - 1]);
}

function uniteAll<T>(Component, Context: React.Context<T>, propNameMapping?: PropNameMapping<T>) {
  return function WithContextWrapper(props) {
    return (
      <Context.Consumer>
        {contextValue => {
          let newProps = { ...props };

          if (propNameMapping) {
            let mappedProps = {};
            if (propNameMapping instanceof Function) {
              mappedProps = propNameMapping(contextValue, props);
            } else if (Array.isArray(propNameMapping)) {
              propNameMapping.forEach(n => {
                // @ts-ignore
                mappedProps[n] = contextValue[n];
              });
            } else if (propNameMapping instanceof Object) {
              Object.keys(propNameMapping).forEach(k => {
                mappedProps[propNameMapping[k] || k] = contextValue[k];
              });
            } else {
              mappedProps[propNameMapping] = contextValue;
            }

            newProps = { ...props, ...mappedProps };
          } else {
            newProps = { ...props, ...contextValue };
          }
          return <Component {...newProps} />;
        }}
      </Context.Consumer>
    );
  };
}

export default function withContext<ContextInterface>(Context: React.Context<ContextInterface>) {
  return <T extends PropNameMapping<ContextInterface>>(propNameMapping?: T) =>
    <Props,>(Component: React.ComponentType<Props>): React.ComponentType<MappedProps<T, Props>> =>
      uniteAll(Component, Context, propNameMapping);
}
