import get from 'lodash.get';

function getKeys(target) {
  // (Using reflect to get all keys including symbols)
  let keys;
  // Use Reflect if exists
  if (typeof Reflect !== 'undefined' && typeof Reflect.ownKeys === 'function') {
    keys = Reflect.ownKeys(target);
  } else {
    keys = Object.getOwnPropertyNames(target);
    // use symbols if support is provided
    if (typeof Object.getOwnPropertySymbols === 'function') {
      keys = keys.concat(Object.getOwnPropertySymbols(target));
    }
  }

  return keys;
}

function mixin(behaviour, sharedBehaviour = {}) {
  const instanceKeys = getKeys(behaviour);
  const sharedKeys = getKeys(sharedBehaviour);
  const typeTag = Symbol('isa');

  function _mixin(clazz) {
    instanceKeys.forEach(key => {
      const descriptor = Object.getOwnPropertyDescriptor(behaviour, key);

      Object.defineProperty(clazz.prototype, key, { ...descriptor, value: behaviour[key] });
    });

    // for (let property of instanceKeys) {
    //   Object.defineProperty(clazz.prototype, property, { value: behaviour[property] });
    // }
    Object.defineProperty(clazz.prototype, typeTag, { value: true });
    return clazz;
  }

  sharedKeys.forEach(key => {
    Object.defineProperty(_mixin, key, {
      value: sharedBehaviour[key],
      enumerable: {}.propertyIsEnumerable.call(sharedBehaviour, key),
    });
  });

  Object.defineProperty(_mixin, Symbol.hasInstance, {
    value: i => !!i[typeTag],
  });

  return _mixin;
}

const form = mixin({
  hideError(fieldName) {
    if (get(this.state, `validation.${fieldName}`) !== '') {
      this.setState(state => ({
        validation: {
          ...state.validation,
          [fieldName]: '',
        },
      }));
    }
  },
  onInputFocus(e) {
    this.hideError(e.target.name);
  },
  onInputChange(e) {
    this.setFieldValue(e.target.name, e.target.value);
  },
  setFieldValue(fieldName, value) {
    this.setState(state => ({
      fields: {
        ...state.fields,
        [fieldName]: value,
      },
    }));
  },
});

export default form;
