import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Elements from 'components/Elements';
import ConsortiaDatesEnum from 'const/ConsortiaDatesEnum';

const CustomDateRangePicker = ({ filterStartDate, dates, setDates }) => {
  const [dateFrom, setDateFrom] = useState(moment(dates.fromDate));
  const [dateTo, setDateTo] = useState(moment(dates.toDate));

  const prepareMaxDate = date => date.endOf('day'); // DateInput.maxDate is sensitive to time
  const today = moment();

  const disabledButton = !dateFrom || !dateTo || dateFrom > dateTo;

  return (
    <div className="CustomDateRangePicker" seleniumid="CustomDateRangePicker">
      <div className="CustomDateRangePicker-InputContainer">
        <label className="CustomDateRangePicker-InputLabel">From:</label>
        <Elements.DateInput
          maxDate={prepareMaxDate(dateTo || today)}
          maxLength={12}
          minDate={filterStartDate}
          onSelect={setDateFrom}
          placeholder="Select start date"
          seleniumid="CustomDateRangePicker-DateInputFrom"
          value={dateFrom}
        />
      </div>

      <div className="CustomDateRangePicker-InputContainer">
        <label className="CustomDateRangePicker-InputLabel">To:</label>
        <Elements.DateInput
          maxDate={prepareMaxDate(today)}
          maxLength={12}
          minDate={dateFrom || filterStartDate}
          onSelect={setDateTo}
          placeholder="Select end date"
          seleniumid="CustomDateRangePicker-DateInputTo"
          value={dateTo}
        />
      </div>
      <button
        className="btn btn-outline-primary"
        disabled={disabledButton}
        onClick={() => setDates(ConsortiaDatesEnum.CUSTOM_RANGE, dateFrom, dateTo)}
        seleniumid="CustomDateRangePicker-FilterButton"
        type="button"
      >
        Filter
      </button>
    </div>
  );
};

CustomDateRangePicker.propTypes = {
  filterStartDate: PropTypes.shape({}),
  dates: PropTypes.shape({
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
  }).isRequired,
  setDates: PropTypes.func.isRequired,
};

CustomDateRangePicker.defaultProps = {
  filterStartDate: undefined,
};

export default CustomDateRangePicker;
