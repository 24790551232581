import React from 'react';
import NoteList from 'components/OrderDetailsModal/NoteList';

export default function AdminNoteList({ notes }: { notes: WoaNote[] }) {
  return (
    <section className="orderDetailsNotes">
      <div className="orderDetailsNotes_title">Wiley Messages to WOA User</div>
      <div data-seleniumid="adminNoteList-notes">
        {notes.length > 0 ? <NoteList isAdmin notes={notes} /> : <div className="p_5">NOT AVAILABLE</div>}
      </div>
    </section>
  );
}
