import { EventEmitterDecorator } from 'services/events/emitter';

const stringify = item => {
  switch (typeof item) {
    case 'object':
      return JSON.stringify(item);
    default:
      return String(item);
  }
};

/**
 * returns number not a string if saves number as a string
 */
const parse = item => {
  try {
    return JSON.parse(item);
  } catch (e) {
    return item;
  }
};

@EventEmitterDecorator
class Storage {
  constructor() {
    window.addEventListener('storage', event => this.emit(event.key, event));
  }

  set(key, value) {
    localStorage.setItem(key, stringify(value));
  }

  get(key) {
    return parse(localStorage.getItem(key));
  }

  remove(key) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }

  watch(key, fn) {
    return this.subscribe(key, event => {
      fn(parse(event.newValue), parse(event.oldValue));
    });
  }
}

export { Storage };
export default new Storage();
