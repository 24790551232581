import React, { useEffect, useState } from 'react';
import OrderDetailsModal from 'components/OrderDetailsModal';
import DealEnum from 'model/DealEnum';
import AccountContext from 'storage/AccountContext';
import EventsViewRow from './EventsViewRow';

const headers = [
  { field: 'view', title: '' },
  { field: 'createdTs', title: 'Request Date' },
  { field: 'status', title: 'Request Status' },
  { field: 'author', title: 'Author Name' },
  { field: 'journalName', title: 'Journal' },
  { field: 'allocatedAmount', title: 'Amount' },
  { field: 'journalRevenueModel', title: 'Journal Revenue Model' },
  { field: 'articlePublicationStatus', title: 'Publication Status' },
];

function EventsList({ events, pageSize }: { events: WoaEvent[]; pageSize?: number }) {
  const { selectedAccount } = AccountContext.useContext();

  const [visibleCount, setVisibleCount] = useState(pageSize);
  const [viewDetailsOrder, setViewDetailsOrder] = useState<number>();

  useEffect(() => {
    setVisibleCount(pageSize);
  }, [events]);

  const closeDetails = () => setViewDetailsOrder(null);
  const openDetails = (event: WoaEvent) => setViewDetailsOrder(event.woarequest.id);
  const onClickSeeMore = () => setVisibleCount(state => state + pageSize);

  return (
    <div>
      {!!viewDetailsOrder && (
        <OrderDetailsModal close={closeDetails} orderId={viewDetailsOrder} showPrice={selectedAccount.showPrice} />
      )}
      <table className="table eventsView">
        <thead>
          <tr className="eventsView-headRow">
            {headers
              .filter(x => {
                if (x.field === 'journalRevenueModel') {
                  return selectedAccount.dealType === DealEnum.BOTH;
                }

                if (x.field === 'allocatedAmount') {
                  return selectedAccount.showPrice;
                }

                return true;
              })
              .map(header => (
                <th key={header.field} className="eventsView-headCell">
                  {header.title}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {events.map((el, i) =>
            i >= visibleCount && pageSize > 0 ? null : (
              <EventsViewRow
                key={el.id}
                currency={selectedAccount.currency}
                dealType={selectedAccount.dealType}
                event={el}
                onView={openDetails}
                showPrice={selectedAccount.showPrice}
              />
            ),
          )}
        </tbody>
      </table>

      <div>
        {/* It is KOSTYL for react bug. Ask Denis Shelest about it */}
        {events.length === 0 ? 'No request found' : ''}
      </div>

      {visibleCount < events.length && pageSize > 0 && (
        <button
          className="btn btn-secondary btn-block mt_10"
          data-seleniumid="eventsView-seeMore"
          onClick={onClickSeeMore}
          type="button"
        >
          {`See More (${events.length - visibleCount})`}
        </button>
      )}
    </div>
  );
}

EventsList.defaultProps = {
  pageSize: 0,
};

export default EventsList;
