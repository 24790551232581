import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import Calendar from 'rc-calendar';
import React, { PureComponent } from 'react';
import listensToClickOutside from 'react-onclickoutside';
import './dateInput.scss';

class DateInput extends PureComponent {
  static propTypes = {
    classNameInput: PropTypes.string,
    format: PropTypes.string,
    maxDate: PropTypes.shape({}),
    maxLength: PropTypes.number,
    minDate: PropTypes.shape({}),
    onSelect: PropTypes.func,
    placeholder: PropTypes.string,
    seleniumid: PropTypes.string,
    value: PropTypes.shape({}),
  };

  static defaultProps = {
    classNameInput: '',
    format: 'YYYY-MM-DD',
    maxDate: undefined,
    maxLength: 10,
    minDate: undefined,
    onSelect: () => {},
    placeholder: '',
    seleniumid: 'dateInput',
    value: undefined,
  };

  constructor(props) {
    super(props);

    const selectedDate = moment(props.value || new Date());

    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      isOpen: false,
      date: selectedDate,
      selectedDate,
      text: this.formatDate(selectedDate),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen, date, text } = this.state;

    if (!isOpen && prevState.isOpen) {
      this.confirmDate(text ? date : null);
    }
  }

  formatDate = date => moment(date).format(this.props.format);

  disabledDate = current => {
    if (!current) return false;
    const { minDate, maxDate } = this.props;

    return (minDate ? current < minDate : false) || (maxDate ? current > maxDate : false);
  };

  // used for listensToClickOutside
  // eslint-disable-next-line react/no-unused-class-component-methods
  handleClickOutside = () => {
    this.setState({ isOpen: false });
  };

  openDatePicker = () => this.setState({ isOpen: true });

  confirmDate = newDate => {
    if (!newDate) {
      this.setState({
        selectedDate: null,
        text: '',
        isOpen: false,
      });
      this.props.onSelect(null);
    } else if (!this.disabledDate(newDate)) {
      this.setState({
        date: newDate,
        selectedDate: newDate,
        text: this.formatDate(newDate),
        isOpen: false,
      });
      this.props.onSelect(newDate);
    } else {
      this.setState(state => {
        this.props.onSelect(state.selectedDate);
        return {
          date: state.selectedDate,
          selectedDate: state.selectedDate,
          text: this.formatDate(state.selectedDate),
          isOpen: false,
        };
      });
    }
  };

  onKeyDown = event => {
    const { isOpen, date, text, selectedDate } = this.state;

    if (event.keyCode === 13 && date.isValid()) {
      this.confirmDate(text ? date : null);
      event.preventDefault();
    }

    if ([38, 40].includes(event.keyCode) && !isOpen) {
      this.openDatePicker();
    }

    if (event.keyCode === 27 && isOpen) {
      this.confirmDate(selectedDate);
      event.stopPropagation();
      event.preventDefault();
    }
  };

  onChangeText = event => {
    const { value } = event.target;
    const { isOpen } = this.state;
    const date = moment(value);
    if (!isOpen) this.openDatePicker();

    if (date && date.isValid()) {
      this.setState({
        date,
        text: value,
      });
    } else {
      this.setState({ text: value });
    }
  };

  onChangeDate = value => this.setState({ date: value });

  onSelectDate = value => this.confirmDate(value);

  render() {
    const { date, selectedDate, text, isOpen } = this.state;
    const { minDate, maxDate, maxLength, placeholder, classNameInput, seleniumid } = this.props;

    return (
      <div className="dateInput" data-seleniumid={seleniumid}>
        <input
          className={cn('input ta_c dateInput-input', classNameInput)}
          data-seleniumid={`${seleniumid}-input`}
          maxLength={maxLength}
          onChange={this.onChangeText}
          onClick={this.openDatePicker}
          onKeyDown={this.onKeyDown}
          placeholder={placeholder}
          type="text"
          value={text}
        />
        {isOpen && (
          <div className="dateInputDatePicker">
            <Calendar
              defaultValue={selectedDate}
              disabledDate={minDate || maxDate ? this.disabledDate : undefined}
              onChange={this.onChangeDate}
              onSelect={this.onSelectDate}
              prefixCls="dateInputCalendar"
              showDateInput={false}
              showOk={false}
              value={date}
            />
          </div>
        )}
      </div>
    );
  }
}

export default listensToClickOutside(DateInput);
