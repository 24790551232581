import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTable from 'react-table';
import { getSort, SortingHeader } from 'components/Consortia/Table/SortingHeader';
import LoaderLayout from 'components/Layouts/LoaderLayout';
import Pager from 'components/Pager/Pager';
// eslint-disable-next-line import/no-cycle
import Search from 'components/Search/Search';
import SearchBanner from 'components/SearchBanner/SearchBanner';
import { mainRoutes } from 'routes';
import { currencyFormat } from 'services/utils';
// eslint-disable-next-line import/no-cycle
import AccountContext from 'storage/AccountContext';
import './AccountsList.scss';
import { ConsortiaContextInterface } from 'storage/ConsortiaContext';

const AccountsList: React.VFC<{
  accountName: string;
  accounts: ConsortiaContextInterface['accounts']['accounts'];
  currency: string;
  isLoading: boolean;
  onChange;
  page: number;
  pages: number;
  pageSize?: number;
  showPrice: boolean;
  sorted: string;
  total: number;
}> = ({
  accountName,
  accounts,
  currency,
  isLoading,
  onChange,
  page,
  pages,
  pageSize = 10,
  showPrice,
  sorted,
  total,
}) => {
  const navigate = useNavigate();
  const { setSelectedAccountByCode } = AccountContext.useContext();

  function getSortingHeader(title) {
    return function createHeader({ column }) {
      return <SortingHeader classNamePrefix="accountsList" current={sorted} field={column.id} title={title} />;
    };
  }

  const columns = [
    {
      Header: getSortingHeader('Account Name'),
      accessor: 'account.name',
      Cell: row => (
        <button
          className="btn btn-link text-left accountsList-link"
          onClick={() => {
            setSelectedAccountByCode(row.original.account.code);
            navigate(mainRoutes.home.getUrl());
          }}
          type="button"
        >
          {row.value}
        </button>
      ),
      minWidth: 300,
    },
    {
      Header: getSortingHeader('Pending Requests'),
      accessor: 'stats.pendingRequests',
      minWidth: 200,
    },
    {
      Header: getSortingHeader('Approved Requests'),
      accessor: 'stats.approvedRequests',
      minWidth: 200,
    },
  ];

  if (showPrice) {
    columns.push({
      Header: getSortingHeader('Total Spent'),
      accessor: 'stats.totalAmountSpent',
      Cell: row => currencyFormat(row.value, currency, sorted),
      minWidth: 200,
    });
  }

  const clearSearch = event => {
    event.preventDefault();
    onChange(sorted, 0, Number(pageSize), undefined);
  };

  const onSubmit = keyword => {
    onChange(sorted, 0, Number(pageSize), keyword);
  };

  return (
    <LoaderLayout isLoading={isLoading}>
      <section className="accountsWrapper">
        <h3 className="accountsWrapper-heading">All Accounts Details</h3>
        <Search
          buttonSeleniumId="accountsFilter-search"
          className="accountsWrapper-Search"
          inputClassName="accountsWrapper-SearchInput"
          inputSeleniumId="accountsFilter-searchInput"
          isSearching={isLoading}
          keyword={accountName}
          onSubmit={onSubmit}
          placeholder="Account name"
        />
        <SearchBanner
          buttonSeleniumId="accountsFilter-clearSearchResult"
          isVisible={!!accountName}
          labelSeleniumId="accountsFilter-searchResult"
          onClear={clearSearch}
          text={`${isLoading ? '?' : total} Results for your search for "${accountName}"`}
        />
        <ReactTable
          className="accountsList -highlight"
          columns={columns}
          data={accounts}
          getTdProps={(state, rowInfo, column) => ({
            className: rowInfo
              ? `accountsList-${column.id} accountsList-${column.id}--${rowInfo.original.account.id}`
              : '',
          })}
          getTheadThProps={(state, rowInfo, column) => ({
            className: `accountsList-head--${column.id}`,
          })}
          loading={isLoading}
          manual
          minRows={1}
          multiSort={false}
          onFetchData={state => {
            if (getSort(state.sorted) !== sorted || state.page !== page || state.pageSize !== pageSize) {
              onChange(getSort(state.sorted), state.page, Number(state.pageSize), accountName);
            }
          }}
          page={page}
          pages={pages}
          pageSize={pageSize}
          pageSizeOptions={[10, 50, 100]}
          PaginationComponent={props => <Pager {...props} pageSizeLabel="Showing ${selector} accounts" />} // eslint-disable-line no-template-curly-in-string
          resizable={false}
        />
      </section>
    </LoaderLayout>
  );
};

export default AccountsList;
