import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

function RadioButton({ className, children, classNameLabel, seleniumid, tooltip, ...anotherProps }) {
  return (
    <label className={cn('it_radiobutton_cover', className)} data-seleniumid={seleniumid} data-title={tooltip}>
      <input {...anotherProps} className="it_radiobutton" data-seleniumid={`${seleniumid}-radiobutton`} type="radio" />
      {children && <span className={cn('it_radiobutton_label', classNameLabel)}>{children}</span>}
    </label>
  );
}

RadioButton.propTypes = {
  className: PropTypes.string,
  classNameLabel: PropTypes.string,
  children: PropTypes.node,
  seleniumid: PropTypes.string,
  tooltip: PropTypes.string,
};

RadioButton.defaultProps = {
  className: '',
  classNameLabel: '',
  children: null,
  seleniumid: '',
  tooltip: undefined,
};

export default RadioButton;
