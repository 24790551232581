import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

function inParent(element, parent) {
  let selected = element;
  while (selected) {
    if (selected === parent) return true;
    selected = selected.parentNode;
  }
  return false;
}

export default function withConfirm(WrappedComponent) {
  return class extends PureComponent {
    static propTypes = {
      children: PropTypes.node,
      classNameParent: PropTypes.string,
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
      onChange: PropTypes.func,
      seleniumid: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      titleNo: PropTypes.string,
      titleYes: PropTypes.string,
    };

    static defaultProps = {
      children: null,
      classNameParent: '',
      disabled: false,
      onClick: null,
      onChange: null,
      seleniumid: 'confirmButton',
      title: 'Continue?',
      titleNo: 'No',
      titleYes: 'Yes',
    };

    DOM = {
      root: React.createRef(),
      buttonYes: React.createRef(),
    };

    state = {
      isConfirm: false,
    };

    componentDidUpdate(prevProps, prevState) {
      const { isConfirm } = this.state;
      if (isConfirm !== prevState.isConfirm) {
        if (isConfirm) {
          this.DOM.buttonYes.current.focus();
          document.addEventListener('click', this.onClickDocument);
        } else {
          document.removeEventListener('click', this.onClickDocument);
        }
      }
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.onClickDocument);
    }

    onClickDocument = e => {
      if (!inParent(e.target, this.DOM.root.current)) {
        this.onClickCancel();
      }
    };

    onClickButton = e => {
      if (e.target === e.currentTarget) {
        this.setState({ isConfirm: true });
      }
    };

    onChangeInput = e => {
      if (e.target.checked) {
        this.setState({ isConfirm: true });
      } else {
        this.props.onChange();
      }
    };

    onClickCancel = () => {
      this.setState({ isConfirm: false });
    };

    onClickConfirm = () => {
      (this.props.onClick || this.props.onChange || (() => {}))();
      this.setState({ isConfirm: false });
    };

    render() {
      const { title, titleYes, titleNo, children, classNameParent, seleniumid, onClick, onChange, ...rest } =
        this.props;

      let anotherProps = rest;
      if (onClick) {
        anotherProps = { ...anotherProps, onClick: this.onClickButton };
      } else if (onChange) {
        anotherProps = { ...anotherProps, onChange: this.onChangeInput };
      }

      const { isConfirm } = this.state;

      return (
        <div ref={this.DOM.root} className={cn('element_confirm_button', classNameParent)}>
          <WrappedComponent seleniumid={seleniumid} {...anotherProps}>
            {children}
          </WrappedComponent>

          {isConfirm && (
            <div className="element_confirm_button__confirmation">
              <span className="element_confirm_button__title">{title}</span>

              <button
                ref={this.DOM.buttonYes}
                className="btn btn-secondary btn-sm element_confirm_button__yes"
                data-seleniumid={`${seleniumid}-confirm`}
                onClick={this.onClickConfirm}
                type="button"
              >
                {titleYes}
              </button>

              <button
                className="btn btn-light btn-sm element_confirm_button__no"
                data-seleniumid={`${seleniumid}-cancel`}
                onClick={this.onClickCancel}
                type="button"
              >
                {titleNo}
              </button>
            </div>
          )}
        </div>
      );
    }
  };
}
