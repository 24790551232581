function assignArrayIfNullOrUndefined(obj, ...fields) {
  if (!obj) {
    return obj;
  }

  fields.forEach(x => {
    if (!obj[x]) {
      // eslint-disable-next-line no-param-reassign
      obj[x] = [];
    }
  });

  return obj;
}

function fixOrderProcessConfiguration(config) {
  return assignArrayIfNullOrUndefined(config, 'researchFunders');
}

function fixResearchProgram(researchProgram: ResearchProgram) {
  return assignArrayIfNullOrUndefined(researchProgram, 'grants', 'recipients');
}

function fixWoaAccount(woaaccount: WoaAccount) {
  if (!woaaccount) {
    return undefined;
  }

  assignArrayIfNullOrUndefined(woaaccount, 'woausers', 'researchFunders', 'woaaccountInvoices', 'woanotes');

  fixOrderProcessConfiguration(woaaccount.ooProcessConfiguration);

  return woaaccount;
}

function fixWoaArticle(woaarticle: WoaArticle) {
  if (!woaarticle) {
    return undefined;
  }

  assignArrayIfNullOrUndefined(
    woaarticle,
    'researchPrograms',
    'correspondingAuthorAffiliations',
    'woaauthors',
    'woanotes',
  );

  woaarticle.researchPrograms.forEach(fixResearchProgram);

  return woaarticle;
}

function fixWoaRequest(woarequest: WoaRequest) {
  if (!woarequest) {
    return undefined;
  }

  fixWoaArticle(woarequest.woaarticle);

  return woarequest;
}

function fixWoaUser(woauser: WoaUser) {
  return assignArrayIfNullOrUndefined(woauser, 'accounts');
}

function fixWoaEvent(woaevent: WoaEvent) {
  if (!woaevent) {
    return undefined;
  }

  // fixWoaAccount(woaevent.woaaccount);
  fixWoaRequest(woaevent.woarequest);

  return woaevent;
}

function isArticleAndAccountNotesEqual(woaAccountNotes: WoaAccount, woaArticleNotes: WoaNote[]) {
  return (
    !!(woaAccountNotes?.woanotes?.length && woaArticleNotes?.length) &&
    woaAccountNotes.woanotes.some(({ id: accountNoteId }) =>
      woaArticleNotes.some(({ id, isPublic, isInternal }) => accountNoteId === id && isPublic && isInternal),
    )
  );
}

export { fixWoaAccount, fixWoaArticle, fixWoaEvent, fixWoaRequest, fixWoaUser, isArticleAndAccountNotesEqual };
