import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Elements from 'components/Elements';

const ALERT_PUB_STAT_CHANGED_TO_IN_PRODUCTION = 'ALERT_PUB_STAT_CHANGED_TO_IN_PRODUCTION';
const ALERT_ALL_PUBLICATION_STATUS_CHANGES = 'ALERT_ALL_PUBLICATION_STATUS_CHANGES';

const Item = ({ el, seleniumid, isDisabled, name, hasNewValue, onChangePublicationStatus }) => {
  return (
    <li className={cn('notificationConfigRadioButtons-item', { 'has-changed': hasNewValue(el) })}>
      <Elements.RadioButton
        checked={el.isChecked}
        disabled={isDisabled}
        name={name}
        onChange={onChangePublicationStatus}
        seleniumid={seleniumid}
        value={el.type}
      >
        {el.title}
      </Elements.RadioButton>
    </li>
  );
};

class NotificationConfigRadioButtons extends PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        newValue: PropTypes.bool,
        title: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.bool,
      }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    seleniumid: PropTypes.string,
  };

  static defaultProps = {
    seleniumid: 'notificationConfigRadioButtons',
  };

  onChangePublicationStatus = e => {
    if (e.target.value === ALERT_PUB_STAT_CHANGED_TO_IN_PRODUCTION) {
      this.props.onChange(ALERT_PUB_STAT_CHANGED_TO_IN_PRODUCTION, true);
    }
    if (e.target.value === ALERT_ALL_PUBLICATION_STATUS_CHANGES) {
      this.props.onChange(ALERT_PUB_STAT_CHANGED_TO_IN_PRODUCTION, false);
    }
  };

  addAllPubStatusChangeOption = (options, seleniumid) => {
    const allPubStatusChangeOptionExist = options.some(el => el.type === ALERT_ALL_PUBLICATION_STATUS_CHANGES);
    if (!allPubStatusChangeOptionExist) {
      options.push({
        type: ALERT_ALL_PUBLICATION_STATUS_CHANGES,
        title: 'All publication status changes',
        seleniumid,
      });
    }
  };

  setSubPubStatusChecked = (isDisabled, options) => {
    if (!isDisabled) {
      options
        .filter(el => el.type === ALERT_PUB_STAT_CHANGED_TO_IN_PRODUCTION)
        .forEach(el => {
          const isChanged = this.hasNewValue(el);
          const value = isChanged ? el.newValue : el.value;
          // eslint-disable-next-line no-param-reassign
          el.isChecked = value;
          options
            .filter(elm => elm.type === ALERT_ALL_PUBLICATION_STATUS_CHANGES)
            .forEach(ele => {
              // eslint-disable-next-line no-param-reassign
              ele.isChecked = !value;
            });
        });
    } else {
      options.forEach(ele => {
        // eslint-disable-next-line no-param-reassign
        ele.isChecked = false;
      });
    }
  };

  hasNewValue = el => {
    return Object.prototype.hasOwnProperty.call(el, 'newValue');
  };

  render() {
    const { options, seleniumid, alertPublicationstatusChange } = this.props;
    const name = seleniumid.startsWith('userNotification') ? 'userNotification' : 'accountNotification';
    const isDisabled = !(alertPublicationstatusChange.newValue === undefined
      ? alertPublicationstatusChange.value
      : alertPublicationstatusChange.newValue);

    this.addAllPubStatusChangeOption(options, seleniumid);
    this.setSubPubStatusChecked(isDisabled, options);

    return (
      <div>
        <ul className="notificationConfigRadioButtons">
          {options.map(el => (
            <Item
              key={el.type}
              el={el}
              hasNewValue={this.hasNewValue}
              isDisabled={isDisabled}
              name={name}
              onChangePublicationStatus={this.onChangePublicationStatus}
              seleniumid={`${seleniumid.split('-')[0]}-${el.type}`}
            />
          ))}
        </ul>
      </div>
    );
  }
}

export default NotificationConfigRadioButtons;
