import cn from 'classnames';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { thresholdCut } from 'services/utils';
import './Search.scss';

const cut = value => thresholdCut(value);

export default class Search extends PureComponent {
  static propTypes = {
    buttonClassName: PropTypes.string,
    buttonSeleniumId: PropTypes.string,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    inputSeleniumId: PropTypes.string,
    isSearching: PropTypes.bool,
    keyword: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    buttonClassName: '',
    buttonSeleniumId: '',
    className: '',
    inputClassName: '',
    inputSeleniumId: '',
    isSearching: false,
    keyword: '',
    placeholder: '',
  };

  state = { input: this.props.keyword };

  componentDidUpdate(prevProps) {
    if (prevProps.keyword !== this.props.keyword) {
      this.setState({ input: this.props.keyword });
    }
  }

  @autobind
  updateSearchInput(event) {
    this.setState({ input: event.target.value });
  }

  @autobind
  startSearch(event) {
    event.preventDefault();
    const query = cut(this.state.input);
    this.props.onSubmit(query);
  }

  render() {
    const { buttonClassName, buttonSeleniumId, className, inputClassName, inputSeleniumId, isSearching, placeholder } =
      this.props;
    const { input } = this.state;
    const canSend = !!cut(input) && !isSearching;

    return (
      <form className={cn('Search', className)} onSubmit={this.startSearch}>
        <input
          className={cn('input mr_10 Search-Field', inputClassName)}
          data-seleniumid={inputSeleniumId}
          name="textField"
          onChange={this.updateSearchInput}
          placeholder={placeholder}
          type="text"
          value={input}
        />
        <button
          className={cn('btn btn-secondary', buttonClassName)}
          data-seleniumid={buttonSeleniumId}
          disabled={!canSend}
          type="submit"
        >
          Search
        </button>
      </form>
    );
  }
}
