import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import { getSort, SortingHeader } from 'components/Consortia/Table/SortingHeader';
import OrderDetailsModal from 'components/OrderDetailsModal';
import Pager from 'components/Pager/Pager';
import 'react-table-hoc-fixed-columns/lib/styles.css';
import { mainRoutes } from 'routes';
import { currencyFormat, dateFormat } from 'services/utils';
import AccountContext from 'storage/AccountContext';
import './RequestsList.scss';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

function RequestsList({
  dealShowPrice,
  isFetching,
  onChange,
  pagination,
  requests,
  requestsType,
  sorted,
  visibleColumns,
}) {
  const navigate = useNavigate();
  const { selectedAccount, setSelectedAccountByCode } = AccountContext.useContext();

  const [viewDetailsOrder, setViewDetailsOrder] = useState(undefined);

  function getSortingHeader(title) {
    return function ({ column }) {
      return <SortingHeader classNamePrefix="requestList" current={sorted} field={column.id} title={title} />;
    };
  }

  const columns = [
    {
      Header: 'View Request',
      accessor: 'request.id',
      sortable: false,
      Cell: row => (
        <button
          className="btn btn-primary"
          data-seleniumid={`viewRequest--${row.original.request.id}`}
          onClick={() => setViewDetailsOrder(row.value)}
          style={{ width: '85px', height: '40px' }}
          type="button"
        >
          View
        </button>
      ),
      style: { padding: 0 },
      headerStyle: { position: 'sticky' },
      fixed: 'left',
      width: 120,
    },
    {
      Header: getSortingHeader('Account Name'),
      accessor: 'account.name',
      Cell: row => (
        <button
          className="btn btn-link text-left accountsList-link p_0"
          onClick={() => {
            setSelectedAccountByCode(row.original.account.code);
            navigate(mainRoutes.home.getUrl());
          }}
          type="button"
        >
          {row.value}
        </button>
      ),
      headerStyle: { position: 'sticky' },
      fixed: 'left',
      minWidth: 300,
    },
    {
      Header: getSortingHeader('Request Date'),
      accessor: 'request.created',
      Cell: row => dateFormat(row.value),
      minWidth: 170,
    },
    {
      Header: getSortingHeader('Article DOI'),
      accessor: 'article.doi',
      minWidth: 180,
    },
    {
      Header: 'Authors',
      accessor: 'article.authors',
      sortable: false,
      Cell: row => row.value.map(author => author.name).join(', '),
      minWidth: 200,
    },
    {
      Header: getSortingHeader('Journal Name'),
      accessor: 'journal.name',
      minWidth: 250,
    },
    {
      Header: 'Journal Revenue Model',
      accessor: 'journal.revenueModel',
      sortable: false,
      minWidth: 180,
    },
    {
      Header: getSortingHeader('Article Publication Status'),
      accessor: 'article.publicationStatus.name',
      minWidth: 200,
    },
    {
      Header: getSortingHeader('Amount'),
      accessor: 'request.amount',
      Cell: row => currencyFormat(row.value, selectedAccount.currency),
      minWidth: 120,
    },
  ].filter(column => visibleColumns.includes(column.accessor));

  return (
    <>
      <ReactTableFixedColumns
        className={`requestList requestList--${requestsType} -highlight`}
        // @ts-ignore
        columns={columns}
        data={requests}
        getTdProps={(state, rowInfo, column) => ({
          className: rowInfo ? `requestList-${column.id} requestList-${column.id}--${rowInfo.original.request.id}` : '',
        })}
        getTheadThProps={(state, rowInfo, column) => ({
          className: `requestList-head--${column.id}`,
        })}
        loading={isFetching}
        manual
        minRows={1}
        multiSort={false}
        onFetchData={state => {
          if (
            getSort(state.sorted) !== sorted ||
            state.page !== pagination.page ||
            state.pageSize !== pagination.size
          ) {
            onChange(getSort(state.sorted), state.page, Number(state.pageSize));
          }
        }}
        page={pagination.page}
        pages={pagination.pages}
        pageSizeOptions={[20, 50, 100, 250]}
        PaginationComponent={props => <Pager {...props} pageSizeLabel="Showing ${selector} requests" />} // eslint-disable-line no-template-curly-in-string
        resizable={false}
      />

      {viewDetailsOrder && (
        <OrderDetailsModal
          close={() => setViewDetailsOrder(undefined)}
          orderId={viewDetailsOrder}
          showPrice={dealShowPrice}
          useOrderAccountShowPrice={dealShowPrice == null}
        />
      )}
    </>
  );
}

export default React.memo(RequestsList);
